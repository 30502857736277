import { useState } from 'react';
import { useSelector} from "react-redux";
import { MealplanDivider, RecipeDetailsSlide } from '../components';


export default function MealplanLayout() {

  // Open recipe details slide
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [open, setOpen] = useState(false);

  // getting recipes from redux state as JSON
  const recipesObject = useSelector((state) => state.recipes.data.week);
  
  // creating array from JSON object
  var recipesArray = [];
  for (var key in recipesObject){
    recipesObject[key].meals.map((item) => recipesArray.push(item))
  }
  
  // creating number of days in a week
  const numDays = Math.floor(recipesArray.length / 4);
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  // creating meal types
  const mealType = ['Beakfast', 'Lunch', 'Dinner', 'Snack'];

  // creating new arraywith all meals separated by day and 4 meals for each day
  const mealsByDays = [];

  // loop through recipesArray and push days and meals into mealsByDay array
  for (var i = 0; i < numDays; i++){
    const dayMeals = [];
    for(let j = 0; j < 4; j++){
      const mealIndex = i * 4 + j;
      const meal = recipesArray[mealIndex];
      const dayOfWeek = daysOfWeek[i % 7];
      dayMeals.push({meal, dayOfWeek});
    }
    mealsByDays.push({day: daysOfWeek[i], meals: dayMeals})
  }

  //++++++++++++++++++++++++++++++
  // Example how to map inside return function
  // console.log(mealsByDays)
  // mealsByDays.map((day)=>{
  //   console.log(day)
  //   day.meals.map((meal)=> {
  //     console.log(meal.meal.title)
  //     console.log(meal.meal.details.image)
  //   })
  // })
  //++++++++++++++++++++++++++++++++++
    return (
      <ul>
        {mealsByDays.map((day) => (
          <li key={mealsByDays.indexOf(day)} className="py-4">
            <MealplanDivider day={day.day}/>
            <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-4">
              {day.meals.map((meal) => {
                return(
                  <div 
                    key={day.meals.indexOf(meal)} 
                    className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                    <div 
                      className="flex-shrink-0 cursor-pointer"
                      onClick={() => {
                        setOpen(true)
                        setSelectedRecipe(meal.meal)}}>
                      <img className="h-48 w-full object-cover" src={meal.meal.details.image} alt={meal.meal.title} />
                    </div>
                    <div className="flex flex-1 flex-col justify-between bg-white p-6">
                      <div className="flex-1">
                        <p className="text-sm font-medium text-indigo-600">
                            {mealType[day.meals.indexOf(meal)]}
                        </p>
                        <p className="cursor-pointer mt-2 block text-xl font-semibold text-gray-900"
                          onClick={() => {
                            setOpen(true)
                            setSelectedRecipe(meal.meal)}}>
                              {meal.meal.title}
                        </p>
                        <p className="text-base leading-7 text-gray-600">
                          Ready in {meal.meal.readyInMinutes} min</p>
                        <p className="text-base leading-7 text-gray-600">
                          {meal.meal.servings} servings</p>
                        {/* <ul role="list" className="mt-6 flex gap-x-6">
                          <li>
                            <p className="text-gray-400 hover:text-gray-500">
                              <span className="sr-only">Twitter</span>
                              <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                              </svg>
                            </p>
                          </li>
                          <li>
                            <p className="text-gray-400 hover:text-gray-500">
                              <span className="sr-only">LinkedIn</span>
                              <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                <path
                                  fillRule="evenodd"
                                  d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </p>
                          </li>
                        </ul> */}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </li>
          
        ))}
        {/* Opens recipe slide */}
        {selectedRecipe && 
          (<RecipeDetailsSlide 
            recipe={selectedRecipe}
            open={open} 
            onClose={() => {
              setOpen(false)}}/>)}
      </ul>
    )
  }