
import { GiStrong, GiWeightScale, GiHeartInside,} from "react-icons/gi";
import { MdNoFood,} from "react-icons/md";
import userScreen from '../../assets/img/user_screen.png';


export default function FeatureSection({quizData, dataFetched}) {

  const getFeatureBenefit = (index, defaultBenefit) => {
    let quizAnswers;
    if (dataFetched) {
      quizAnswers = quizData.quizData;
    } else {
      quizAnswers = quizData.quizData.quizData;
    }
    
    return quizAnswers[index].answer?.benefit || defaultBenefit;
  }
  // const getFeatureBenefit = (index, defaultBenefit) => {

  //   const quizAnswers = quizData?.quizData?.quizData;

  //   console.log(quizAnswers, dataFetched)
  //   return quizAnswers[index].answer?.benefit || defaultBenefit;
  // }
  // getFeatureBenefit(0, 'hfhffh')
  const features = [
    {
      name: 'Personalized Mealplan',
      description: getFeatureBenefit(0, 'A customized approach tailored to your specific goals and body type.'),
      icon: GiWeightScale,
    },
    {
      name: 'Improved Confidence',
      description: getFeatureBenefit(2, 'A customized approach tailored to your specific goals and body type.'),
      icon: GiStrong,
    },
    {
      name: 'Simple and Easy to Follow',
      description: getFeatureBenefit(5, 'A customized approach tailored to your specific goals and body type.'),
      icon: MdNoFood,
    },
    {
      name: 'No Hunger or Cravings',
      description: getFeatureBenefit(6, 'A customized approach tailored to your specific goals and body type.'),
      icon: GiHeartInside,
    }
  ]
  return (
    <div className="overflow-hidden bg-white py-18 sm:py-28">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-2xl font-semibold leading-7 text-orange-700">Your Path to Happier and Healthier You</h2>
              <p className="mt-2 text-3xl font-semibold uppercase tracking-tight text-gray-900 sm:text-2xl">Highlights of your customized plan</p>
              {/* <p className="mt-6 text-lg leading-8 text-gray-600">
                Embark on a transformative journey with our cutting-edge program. We've combined science, technology, and 
                personalization to create a plan that's uniquely yours. Here's what makes our approach stand out:
              </p> */}
              <dl className="mt-10 max-w-xl space-y-8 text-xl leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon aria-hidden="true" className="absolute left-1 top-1 h-7 w-7 text-orange-700" />
                      {/* {feature.name} */}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            alt="Product screenshot"
            src={userScreen}
            // src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
            width={2432}
            height={1442}
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
          />
        </div>
      </div>
    </div>
  )
}
