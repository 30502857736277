import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import resetService from './resetService';

// get token save in localStorage
const token = JSON.parse(localStorage.getItem('reset'));

const initialState = {
    // if token is set in localStorage save as token else set as empty string
    data: token ? token : '',
    isError: false,
    isSuccess: false,
    isLoading:false, 
    message: ''
}

// locate user Thunk
export const findUser = createAsyncThunk('reset/findUser',
async (user, thunkAPI) => {
    try{
        return await resetService.findUser(user)
    } catch (error){
        const message = (error.response && 
            error.response.data && 
            error.response.data.message) || 
            error.message ||
            error.toString()

        return thunkAPI.rejectWithValue(message) 
    }
})

// Reset Password Thunk
export const resetPassword = createAsyncThunk('reset/Password',
async (user, thunkAPI) => {
    try{

        // taking new seleceted password and urlToken sent from UI
        const {password, token,} = user;

        // taking token saved in redux state 
        const serverToken = thunkAPI.getState().reset.data.token;

        // taking userId saved in redux state
        const userId = thunkAPI.getState().reset.data._id;

        // compare url token sent from UI to server token saved in redux state
        // if match is true send data to the backend api to update the password
        if (token === serverToken){
            return await resetService.resetPassword({
                password: password, 
                id: userId})
        }


    } catch (error){
        const message = (error.response && 
            error.response.data &&
            error.response.data.message) || 
            error.message || 
            error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})


export const resetSlice = createSlice({
    name: 'reset',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(findUser.pending, (state) => {
                state.isLoading = true
            })
            .addCase(findUser.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.data = action.payload
            })
            .addCase(findUser.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.reset = ''
            })
            .addCase(resetPassword.pending, (state) => {
                state.isLoading = true
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.data = ''
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.data = ''
            })
    }
})

export const { reset } = resetSlice.actions;
export default resetSlice.reducer;