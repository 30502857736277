
import { GiWeightScale, GiBullseye } from "react-icons/gi";
import ButtonGetMyPlan from './ButtonGetMyPlan';


// Function to calculate weight loss percentage
const calculateWeightLossPercentage = (currentWeight, goalWeight) => {
  if (currentWeight <= goalWeight) return 0;
  const weightLoss = currentWeight - goalWeight;
  const percentage = (weightLoss / currentWeight) * 100;
  return Math.round(percentage * 10) / 10; // Round to one decimal place
};

export default function SinglePriceSection({quizData, surveyData, dataFetched}) {

  const getFeatureBenefit = (index, defaultBenefit) => {
    let quizAnswers;
    if (dataFetched){
      quizAnswers = quizData?.quizData
    } else {
      quizAnswers = quizData.quizData.quizData;
    }
    
    return quizAnswers[index]?.answer?.content || defaultBenefit;
  }

  const isMetric = surveyData.metric

  const weightLossPercentage = calculateWeightLossPercentage(
    isMetric ? surveyData.weightKgCurr : surveyData.weightLbsCurr,
    isMetric ? surveyData.weightKgTarget : surveyData.weightLbsTarget
  )
  
  // const personalGoal = quizData[8].answer.content
  const personalGoal = getFeatureBenefit(8, "Feel confident in your body")


  const targetWeight = surveyData.metric === false ? `${surveyData.weightLbsTarget} lbs` : `${surveyData.weightKgTarget} kg`

  const includedFeatures = [
    {text: personalGoal, icon: GiBullseye},
    {text: `Target weight: ${targetWeight}`, icon: GiWeightScale }
  ]


  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Get visible results in 4 weeks</h2>
          {/* <p className="mt-6 text-lg leading-8 text-gray-600">
            Distinctio et nulla eum soluta et neque labore quibusdam. Saepe et quasi iusto modi velit ut non voluptas
            in. Explicabo id ut laborum.
          </p> */}
        </div>
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            {/* <h3 className="text-2xl font-bold tracking-tight text-gray-900">Personalized Meal Plan For Your Unique Body Type</h3> */}
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">
              <span className='underline decoration-green-600'>Reduce Body Weight 
                <span className='text-green-700 mx-1'>  {weightLossPercentage}%</span>
              </span> With Personalized Meal Plan</h3>

            <p className="mt-6 text-xl leading-7 text-gray-600">
              <span className='text-red-600 font-semibold mr-3'>Eat what you love and still lose BIG!</span> 
                 Our scientifically-backed program is designed to deliver
                <span className='underline decoration-green-600 font-semibold text-green-700'> visible results in just 4 weeks</span>. 
                Watch the <span className='underline decoration-green-600 font-semibold text-green-700'> number of the scale drop</span> and 
                <span className='underline decoration-green-500 font-semibold text-green-700'> confidence soar</span> as your 
                <span className='underline decoration-green-500 font-semibold text-green-700'> stress and anxiety disappears</span>.
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-2xl font-semibold leading-6 text-gray-900">Reach your goal:</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-xl leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {/* {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <GiOnTarget aria-hidden="true" className="h-10 w-10 flex-none text-indigo-600" />
                  {feature}
                </li>
              ))} */}
              {includedFeatures.map((feature, index) => (
                <li key={index} className="flex gap-x-3">
                  <feature.icon aria-hidden="true" className="h-10 w-10 flex-none text-orange-700" />
                  <span className='text-green-700 font-semibold'>{feature.text}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <h3 className="text-3xl mb-3 font-semibold pb-2 border-b border-gray-300">12-Week Plan</h3>
                {/* <h4 className='text-2xl font-semibold'>12-Week Plan</h4> */}
                <p 
                className="text-xl font-semibold text-gray-600"
                >
                <span className="line-through decoration-red-500 text-4xl tracking-tight">$97</span>
                <span className="text-base text-gray-500 leading-6 tracking-wide">  $8/week</span>
                </p>
                <p className="mt-6 mb-3 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-green-700">$34.89</span> 
                  <span className="text-base font-semibold leading-6 tracking-wide text-gray-600"> $2.90/week</span>
                </p>
                <ButtonGetMyPlan />
                {/* <a
                  href="#"
                  className="uppercase mt-10 block w-full rounded-md bg-orange-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                >
                  Get my plan
                </a> */}
                {/* <p className="mt-6 text-xs leading-5 text-gray-600">
                  Invoices and receipts available for easy company reimbursement
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
