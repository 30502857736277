// Service to make http request and send data to local storage 
import axios from 'axios';

// const API_URL = 'http://localhost:5000/api/user/'
const API_URL = process.env.REACT_APP_SERVERSIDE_BASE_API + '/user/';


// Register user
const register = async (userData) => {
    try{
        const response = await axios.post(API_URL + 'register/', userData);

        if(response.data){
            localStorage.setItem('user', JSON.stringify(response.data))
        }
    
        return response.data
    } catch (error) {
        throw new Error("Sorry, there is an error.");
    }
}

// Login user
const login = async (userData) => {
    try{
        const response = await axios.post(API_URL + 'login/', userData);

        if(response.data){
            localStorage.setItem('user', JSON.stringify(response.data))
        }
    
        return response.data
    } catch (error){
        throw new Error("Sorry, the email and password you entered didn't match our records.");
    }
}

//Logout
const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('buyer');
    localStorage.removeItem('mealplanData');
    localStorage.removeItem('mealplanRecipes');
}

// Upidate user data name, email and password from user's account
const update = async (userData, token) => {

    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    try {
        const response = await axios.put(API_URL + 'update/', userData, config);

        if(response.data){
            localStorage.setItem('user', JSON.stringify(response.data))
        }

        return response.data
    } catch(error){
        throw new Error("Sorry, the email you entered didn't match our records.");
    }
}

// Update password from user's account
// const updatePassword = async (userData, token) => {
//     const config = {
//         headers: {
//             Authorization: `Bearer ${token}`
//         }
//     }

//     try {
//         const response = await axios.put(API_URL + 'update/', userData, config);
//     } catch (error){
//         throw new Error("Sorry, unexpeted error")
//     }
// }

const cancel = async (userData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    try{
        const response =  await axios.post(API_URL + 'cancel/', userData, config);

        if(response.data){
            localStorage.setItem('user', JSON.stringify(response.data))
        }

        return response.data
    } catch (error){

        throw new Error("Unexpected error")
    }
}

const authService = {
    register,
    login,
    logout,
    update,
    cancel
    // updatePassword,
}

export default authService;