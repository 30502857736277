import styled from "@emotion/styled";

const CardFormContainer = styled.div`

  height: 56px;
  color: #32325d;
  background-color: white;
  border: 0.3px solid;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  display: flex;
  align-items: center;
  & .StripeElement {
    width: 100%;
    padding: 15px;
`;

export default CardFormContainer;