
const MetricToggle = ({metric, toggleMetric}) => {

  return (
    <div className="flex space-x-3 rounded-xl bg-pink-900/20 p-1">
    <button
      className={`w-full px-2 rounded-lg py-2.5 text-lg font-medium leading-5 text-pink-900
         ring-white ring-opacity-60 ring-offset-2 ring-offset-pink-400 focus:outline-none focus:ring-2
        ${metric ? 'bg-pink-700 text-white shadow' : 'text-pink-500 hover:bg-white/[0.12] hover:text-pink-400'}`}
      onClick={() => toggleMetric(!metric)}
    >
      Metric
    </button>
    <button
      className={`w-full px-2 rounded-lg py-2.5 text-lg font-medium leading-5 text-pink-900
        ring-white ring-opacity-60 ring-offset-2 ring-offset-pink-400 focus:outline-none focus:ring-2
        ${!metric ? 'bg-pink-700 text-white shadow' : 'text-pink-500 hover:bg-white/[0.12] hover:text-white'}`}
      onClick={() => toggleMetric(!metric)}
    >
      Imperial
    </button>
  </div>
  );
};

export default MetricToggle;
