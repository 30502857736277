import KetoAtTheGym from '../assets/img/bonuses/KetoAtTheGym.png';
import KetogenicDiet from '../assets/img/bonuses/KetogenicDiet.png';
import KetoRecipes from '../assets/img/bonuses/KETOrecipes.png';
import LowCarbEatout from '../assets/img/bonuses/LowCarbEatout.png';
import LowCarbFAQ from '../assets/img/bonuses/LowCarbFAQ.png';

const posts = [
  {
    id: 1,
    title: 'The Ketogenic Diet',
    download: 'https://eat2burndownload.s3.amazonaws.com/Bonuses/KetogenicDiet.pdf',
    description:'How  a low card lifestyle can improve your health',
    imageUrl: KetogenicDiet,
  },
  {
    id: 2,
    title: 'Keto At The Gym',
    download: 'https://eat2burndownload.s3.amazonaws.com/Bonuses/KetoAtTheGym.pdf',
    description:'Cyclical and targeted ketogenic diets for bodybuilders, strength training, athletes & High Intensity Workouts.',
    imageUrl: KetoAtTheGym,
  },
  {
    id: 3,
    title: 'Tricks to Low Card Eating Out',
    download: 'https://eat2burndownload.s3.amazonaws.com/Bonuses/KetogenicDiet.pdf',
    description:'Despite popular opinion, eating out while living a low carb lifestyle doesn’t have to be painful and you don’t have to feel guilty when enjoying your meals. It is entirely possible to enjoy delicious, amazing food while you are eating out and still lose weight.',
    imageUrl: LowCarbEatout,
  },
  { 
    id: 4,
    title: 'Keto Recipes',
    download: 'https://eat2burndownload.s3.amazonaws.com/Bonuses/KetogenicDiet.pdf',
    description:'Keto recipes for personal enjoyment but also for family enjoyment. You will love them for sure for how easy it is to prepare them.',
    imageUrl: KetoRecipes,
  },
  {
    id: 5,
    title: 'Low Carb FAQ',
    download: 'https://eat2burndownload.s3.amazonaws.com/Bonuses/LowCarbFAQ.pdf',
    description:'Here are some of the most frequently asked questions about low carb eating',
    imageUrl: LowCarbFAQ,
  }
  // More posts...
]

export default function Bonuses() {
  return (
    <div className="bg-white py-24 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-4xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Your Free Bonuces</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            The following bonuses are included with your membership.
          </p>
          <div className="mt-16 space-y-20 lg:mt-20 lg:space-y-20">
            {posts.map((post) => (
              <article key={post.id} className="relative isolate flex flex-col gap-8 lg:flex-row">
                <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
                  <img
                    src={post.imageUrl}
                    alt="How a low card lifestyle can improve your health"
                    className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover"
                  />
                  <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                <div>
                  <div className="group relative max-w-xl">
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                   
                        <span className="absolute inset-0" />
                        {post.title}
                    </h3>
                    <p className="mt-5 text-lg leading-6 text-gray-600">{post.description}</p>
                  </div>
                  <button
                      onClick={() => window.open(post.download, '_blank')}
                      type="button"
                      style={{zIndex:1}}
                      className="rounded-md bg-pink-500 mt-5 uppercase h-10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-500"
                        >
                        Download
                    </button>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
