import { XlviLoader } from "react-awesome-loaders";

const loveFood = [
    {
      text: "Cauliflower is nothing but cabbage with a college education.",
      author: "Mark Twain"
    },
    {
      text: "Food is for eating, and good food is to be enjoyed... ",
      author: "Delia Smith"
    },
    {
      text: "You don't need a silver fork to eat good food.",
      author: "Paul Prudhomme"
    },
    {
      text: "My weaknesses have always been food and men — in that order.",
      author: "Dolly Parton"
    },
    {
      text: "I'm just someone who likes cooking and for whom sharing food is a form of expression.",
      author: "Maya Angelou"
    },
    {
      text: "After a good dinner, one can forgive anybody, even one's own relatives.",
      author: "Oscar Wilde"
    },
    {
      text: "People who love to eat are always the best people.",
      author: "Julia Child"
    },
    {
      text: "One cannot think well, love well, sleep well, if one has not dined well.",
      author: "Virginia Woolf"
    },
    {
      text: "All you need is love. But a little chocolate now and then doesn't hurt.",
      author: "Charles M. Schulz"
    },
    {
      text: "The only thing I like better than talking about food is eating.",
      author: "John Walters"
    },
    {
      text: "Food is symbolic of love when words are inadequate.",
      author: "Alan D. Wolfelt"
    },
    {
      text: "People who love to eat are always the best people.",
      author: "Julia Child"
    },
    {
      text: "One cannot think well, love well, sleep well, if one has not dined well.",
      author: "Virginia Woolf"
    },
    {
      text: "All you need is love. But a little chocolate now and then doesn't hurt.",
      author: "Charles M. Schulz"
    },
    {
      text: "The way to a man's heart is through his stomach.",
      author: "English Proverb"
    },
    {
      text: "The only thing I like better than talking about food is eating.",
      author: "John Walters"
    },
    {
      text: "Food is symbolic of love when words are inadequate.",
      author: "Alan D. Wolfelt"
    },
    {
      text: "First we eat, then we do everything else.",
      author: "M.F.K. Fisher"
    },
    {
      text: "Good food is the foundation of genuine happiness.",
      author: "Auguste Escoffier"
    },
    {
      text: "Life is uncertain. Eat dessert first.",
      author: "Ernestine Ulmer"
    },
    {
      text: "Cooking is like love. It should be entered into with abandon or not at all.",
      author: "Harriet Van Horne"
    },
    {
      text: "There is no love sincerer than the love of food.",
      author: "George Bernard Shaw"
    },
    {
      text: "One cannot think well, love well, sleep well, if one has not eaten well.",
      author: "Mokokoma Mokhonoana"
    },
    {
      text: "If more of us valued food and cheer and song above hoarded gold, it would be a merrier world.",
      author: "J.R.R. Tolkien"
    },
    {
      text: "Food is the most primitive form of comfort.",
      author: "Sheila Graham"
    },
    {
      text: "The secret of success in life is to eat what you like and let the food fight it out inside.",
      author: "Mark Twain"
    },
    {
      text: "Laughter is brightest where food is best.",
      author: "Irish Proverb"
    },
    {
      text: "The only time to eat diet food is while you're waiting for the steak to cook.",
      author: "Julia Child"
    },
    {
      text: "I am not a glutton - I am an explorer of food.",
      author: "Erma Bombeck"
    },
    {
      text: "You don't need a silver fork to eat good food.",
      author: "Paul Prudhomme"
    }, 
];

const getRandomQuote = ()=> {
  const randomIndex = Math.floor(Math.random() * loveFood.length);
  return loveFood[randomIndex];
}

const quote = getRandomQuote();

const Spinner = () => {
  return (
    <div className="flex justify-center items-center">
    <div className="w-3/4">
         <blockquote className="mt-6 md:flex md:flex-grow md:flex-col">
            <div className="relative text-xl font-bold text-gray-700 md:flex-grow">
              <svg
                className="absolute left-0 top-0 h-8 w-8 -translate-x-3 -translate-y-2 transform text-gray-500"
                fill="currentColor"
                viewBox="0 0 32 32"
              >
                <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
              </svg>
              <p className="relative ml-10">
                {quote.text}
              </p>
            </div>
            <footer className="mt-8">
              <div className="flex justify-end">
                <div className="ml-4">
                  <div className="text-lg font-medium text-black">-{quote.author}</div>
                </div>
              </div>
            </footer>
          </blockquote>
        <div className="flex justify-center mt-8">  
          <XlviLoader 
            boxColors={["#36EEE0", "#F652A0", "#F8D210"]}
            desktopSize={"58px"}
            mobileSize={"40px"}
          />          
        </div>
    </div>
  </div>  
  )
}

export default Spinner