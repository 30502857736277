import {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fullBuyerRecord } from '../features/buyer/buyerSlice';
import EatToBurnGuide from '../assets/img/product/EatToBurnGuide72_300.png';
import HackingFitness from '../assets/img/product/HackingFitnessGuide.png';

// const downloads = []
const downloadOne = {
  id: 1,
  title: 'EatToBurn Manual',
  download: 'https://eat2burndownload.s3.amazonaws.com/eattoburn/EatToBurnGuide.pdf',
  imageUrl: EatToBurnGuide,
}
const downloadTwo = {
  id: 2,
  title: 'Hacking Fitness',
  download: 'https://eat2burndownload.s3.amazonaws.com/HackingFitness/HackingFitnessGuide.pdf',
  imageUrl: HackingFitness,
}


const Downloads = () => {

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [buyerRecordData, setBuyerRecordData] = useState();
  const [downloads, setDownloads] = useState([downloadOne])

  useEffect(() => {

    const fetchBuyerRecord = async () => {
      const dispatchBuyerRecord = await dispatch(fullBuyerRecord(user.buyerId))
      
      setBuyerRecordData(dispatchBuyerRecord.payload)
      // console.log('buyer record', buyerRecordData)
    }
    
    fetchBuyerRecord();

  }, [user, dispatch])

  useEffect(() => {
    if(buyerRecordData && buyerRecordData.orderBump){
      setDownloads([downloadOne, downloadTwo])
    }

  }, [buyerRecordData])

  // console.log('buyer rec',buyerRecordData.orderBump)
  // console.log('downloads states' , downloads)
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-10 lg:px-8">
        {/* <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Welcome</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
          All of your materials are ready.
          </p>
        </div> */}
        <div className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {downloads.map((post) => (
            <article
              key={post.id}
              className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
            >
              <img src={post.imageUrl} alt="EatToBurn Download" className="absolute inset-0 -z-10 h-full w-full object-cover" />
              <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
              <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

              <h3 className="m-3 text-lg text-center font-semibold leading-6 text-white">
                
                  <span className="absolute inset-0" />
                  {post.title}
                
              </h3>
              <button
                onClick={() => window.open(post.download, '_blank')}
                type="button"
                style={{zIndex:1}}
                className="rounded-md bg-pink-500 uppercase h-10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-500"
                  >
                  Download
              </button>
            </article>

          ))}
        </div>
      </div>
    </div>
  )
}

export default Downloads