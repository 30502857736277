export const ACVGummiesLinks = [
    // {
    //     result: 'D',
    //     name: 'Slim Chew',
    //     link: 'https://hop.clickbank.net/?vendor=slimchews&tid=gummiesquiz&affiliate=hapyhalthy'
    // },
    {
        result: 'B',
        name: 'Summer Keto',
        link: 'https://www.tracxpert.com/2CXWFC8W/TNJXHC1/?source_id=gummiesquiz'
    },
    {
        result: 'C',
        name: 'Neotropics',
        link: 'https://0a80f9pe83n9436msk6iuafkdt.hop.clickbank.net/?tid=gummiesquiz'
    },
    {
        result: 'A',
        name: 'Puradrops',
        link: 'https://53fc4aek51cc17dn8hi-5dwq54.hop.clickbank.net/?tid=gummiesquiz'
    },
    {
        result: 'D',
        name: 'Divinity',
        link: 'https://www.tracxpert.com/2CXWFC8W/W3LLHGD/?source_id=gummiesquiz'
    },
    {
        result: 'E',
        name: 'Active Keto',
        link: 'https://www.tracxpert.com/2CXWFC8W/TF4LZXX/?source_id=gummiesquiz'
    },

] 