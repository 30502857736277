import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import {SurveyProgress, SurveyTitle} from '../../../components';


export default function Height(props){

    const [ errorFeet, setErrorFeet ] = useState(false);
    const [ errorInch, setErrorInch ] = useState(false);
    const [ errorCm, setErrorCm ] = useState(false);
    const [ disabled, setDisabled ] = useState(true);

    const validateInput = (value, min, max) => {
        return value !== '' && !isNaN(value) && value >= min && value <= max;
    };

    const handleFeetChange = (event) => {
        const value = event.target.value;
        const valid = validateInput(value, 4, 7);
        setErrorFeet(!valid)
        setDisabled(!valid || errorInch);
        props.updateHeightFtValue(value);
    }

    const handleInchChange = (event) => {
        const value = event.target.value;
        const valid = validateInput(value, 0, 12);
        setErrorInch(!valid);
        setDisabled(!valid || errorFeet);
        props.updateHeightInchValue(value);
    };

    const handleCmChange = (event) => {
        const value = event.target.value;
        const valid = validateInput(value, 119, 251);
        setErrorCm(!valid);
        setDisabled(!valid || errorFeet || errorInch);
        props.updateHeightCmValue(value);
    }

    const handleNextStep = (stepValue)=>{
        props.nextStep(stepValue)
    }

    return(
        <>
        <div>
            <SurveyTitle props={props}/>
            <SurveyProgress step={props.step}/>
            <label htmlFor="number" className="block mt-5 text-lg font-medium leading-6 text-gray-900">
            How tall are you?
            </label>
            {props.metric ? 
            (<>
            <div className="relative mt-2 rounded-md shadow-sm">
                <input
                    type="number"
                    name="height-cm"
                    id="height-cm"
                    min="119"
                    max="251"
                    onChange={handleCmChange}
                    style={{fontSize: '1.25rem'}}
                    className={errorCm ? (
                            "block w-full text-center h-12 rounded-md border-0 py-1.5 ring-1 ring-inset placehoder:text-xl focus:ring-2 sm:text-xl sm:leading-6 pr-10 text-red-900   ring-red-300 placeholder:text-red-300 focus:ring-inset focus:ring-red-500"
                        ) : (
                            "block w-full text-center h-12 rounded-md border-0 py-1.5 ring-1 ring-inset placeholder:text-xl focus:ring-2 sm:text-xl sm:leading-6 text-gray-900  first-letter:shadow-sm  ring-offset-sky-300 placeholder:text-gray-400 focus:ring-inset-4 focus:ring-offset-sky-600"
                        )}
                    placeholder="Your height in Cms?"
                    aria-invalid="true"
                    aria-describedby="age-error"
                />
                {errorCm && (
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon className="h-8 w-8 text-red-500" aria-hidden="true" />
                </div>)}
            </div>
            <div className='mt-2'>
            {errorCm && (
                <p className=" text-lg text-red-600" id="age-error">
                   Height can't be under 120 cm or over 250 cm
                </p>)}
            </div>
            </>
            ) 
            : 
            (
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                <label htmlFor="height-feet" className="block text-lg font-medium leading-6 text-gray-900">
                    Feet
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                    <input
                    type="number"
                    name="height-feet"
                    id="height-feet"
                    onChange={handleFeetChange}
                    style={{fontSize: '1.25rem'}}
                    placeholder="How many feet?"
                    className={errorFeet ? (
                            "block w-full text-center h-12 rounded-md border-0 py-1.5 ring-1 ring-inset placehoder:text-xl focus:ring-2 sm:text-xl sm:leading-6 pr-10 text-red-900   ring-red-300 placeholder:text-red-300 focus:ring-inset focus:ring-red-500"
                        ) : (
                            "block w-full text-center h-12 rounded-md border-0 py-1.5 ring-1 ring-inset placeholder:text-xl focus:ring-2 sm:text-xl sm:leading-6 text-gray-900  first-letter:shadow-sm  ring-offset-sky-300 placeholder:text-gray-400 focus:ring-inset-4 focus:ring-offset-sky-600"
                        )}
                    />
                    {errorFeet && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <ExclamationCircleIcon className="h-8 w-8 text-red-500" aria-hidden="true" />
                        </div>)}
                </div>
                <div className='mt-2'>
                    {errorFeet && (
                        <p className=" text-lg text-red-600" id="age-error">
                        Feet can't be under 4 or over 7.
                        </p>)}
                    </div>
                </div>
                <div className="sm:col-span-3">
                <label htmlFor="height-inches" className="block text-lg font-medium leading-6 text-gray-900">
                    Inches
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                    <input
                    type="number"
                    name="height-inches"
                    id="height-inches"
                    onChange={handleInchChange}
                    style={{fontSize: '1.25rem'}}
                    placeholder="How many inches?"
                    className={errorInch ? (
                            "block w-full text-center h-12 rounded-md border-0 py-1.5 ring-1 ring-inset placehoder:text-xl focus:ring-2 sm:text-xl sm:leading-6 pr-10 text-red-900   ring-red-300 placeholder:text-red-300 focus:ring-inset focus:ring-red-500"
                        ) : (
                            "block w-full text-center h-12 rounded-md border-0 py-1.5 ring-1 ring-inset placeholder:text-xl focus:ring-2 sm:text-xl sm:leading-6 text-gray-900  first-letter:shadow-sm  ring-offset-sky-300 placeholder:text-gray-400 focus:ring-inset-4 focus:ring-offset-sky-600"
                        )}
                    />
                    {errorInch && (
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <ExclamationCircleIcon className="h-8 w-8 text-red-500" aria-hidden="true" />
                        </div>)}
                </div>
                <div className='mt-2'>
                    {errorInch && (
                        <p className=" text-lg text-red-600" id="age-error">
                            Inches can't be more than 12.
                        </p>)}
                </div>
                </div>
            </div>
    
        
            )}
        </div>
        <div>
            <button
                type="submit"
                disabled={disabled}
                onClick={()=> handleNextStep(props.step)}
                className="flex w-full h-12 justify-center rounded-md bg-orange-600 px-3 mt-12 py-2 text-lg font-semibold text-white shadow-sm hover:bg-orange-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-800"
                >
                Next
            </button>
        </div>
        
        </>
    )
}