export default function QuizProgress(props) {
    const { step } = props;
    const steps = [
      { name: 'Step 1', status: '' },
      { name: 'Step 2', status: '' },
      { name: 'Step 3', status: '' },
      { name: 'Step 4', status: '' },
      { name: 'Step 5', status: '' },
      { name: 'Step 6', status: '' },
      { name: 'Step 7', status: '' },
      { name: 'Step 8', status: '' },
      { name: 'Step 9', status: '' },
      { name: 'Step 10', status: '' },
      { name: 'Step 11', status: '' },
    ];
  
    // Loop through the steps array and update the status of the current and completed steps
    for (let i = 0; i < steps.length; i++) {
      if (i < step) {
        steps[i].status = 'complete';
      } else if (i === step) {
        steps[i].status = 'current';
      } else {
        steps[i].status = '';
      }
    }
  
    return (
      <ol className="py-2 flex mx-auto justify-center items-center space-x-5">
        {steps.map((step) => (
          <li key={step.name}>
            {step.status === 'complete' ? (
              <span className="block h-2.5 w-2.5 rounded-full bg-indigo-600 hover:bg-indigo-900">
                <span className="sr-only">{step.name}</span>
              </span>
            ) : step.status === 'current' ? (
              <span className="relative flex items-center justify-center" aria-current="step">
                <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
                  <span className="h-full w-full rounded-full bg-indigo-200" />
                </span>
                <span className="relative block h-2.5 w-2.5 rounded-full bg-indigo-600" aria-hidden="true" />
                <span className="sr-only">{step.name}</span>
              </span>
            ) : (
              <span className="block h-2.5 w-2.5 rounded-full bg-gray-400">
                <span className="sr-only">{step.name}</span>
              </span>
            )}
          </li>
        ))}
      </ol>
    );
  }