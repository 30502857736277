
const BulletproofVideoProperties = [
    {
        number: 1,
        title: "Introduction",
        url: "https://player.vimeo.com/video/435303920",
        videoId: '435303920'
    },
    {
        number: 2,
        title: "How Does Weight Loss Work?",
        url: "https://player.vimeo.com/video/435294911",
        videoId:435294911
    },
    {
        number: 3,
        title: 'Why You Cannot Lose Weight',
        url: 'https://player.vimeo.com/video/435295633',
        videoId: 435295633
    },
    {
        number: 4,
        title: 'The Keto Alternative',
        url: 'https://player.vimeo.com/video/435295734',
        videoId: 435295734
    },
    {
        number: 5,
        title: 'Keep This In Mind Before You Start Keto',
        url: 'https://player.vimeo.com/video/435295786',
        videoId: 435295786
    },
    {
        number: 6,
        title: 'Displace, Dont Replace',
        url: 'https://player.vimeo.com/video/435295834',
        videoId: 435295834
    },
    {
        number: 7,
        title: 'Keto Meal Plan Strategies That Work',
        url: 'https://player.vimeo.com/video/435295891',
        videoId: 435295891
    },
    {
        number: 8,
        title: 'Wind Down on Carbs',
        url: 'https://player.vimeo.com/video/435295972',
        videoId: 435295972
    },
    {
        number: 9,
        title: 'Turbocharge Your Results',
        url: 'https://player.vimeo.com/video/435296040',
        videoId: 435296040
    },
    {
        number: 10,
        title: 'Take Things To The Next Level',
        url: 'https://player.vimeo.com/video/435296103',
        videoId: 435296103
    },
    {
        number: 11,
        title: 'Conclusion',
        url: 'https://player.vimeo.com/video/435304129',
        videoId: 435304129
    }
]

export default BulletproofVideoProperties;