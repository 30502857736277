import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import quizService from './quizService';

const initialState = {
    data: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

// Create new quiz record Thunk
export const newQuizRecord = createAsyncThunk('quiz/createRecord',
    async (quizData, thunkAPI) => {
        try{
            return await quizService.newQuizRecord(quizData);
        }catch(error){
            const message = 
                (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message || 
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Retreive existing quiz record Thunk
export const retrieveQuizRecord = createAsyncThunk('quiz/retrieveQuizRecord',
    async (quizRecord,  thunkAPI) => {
        try {
            return await quizService.retrieveQuizrecord(quizRecord);
        } catch(error){
            const message = 
                (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const quizSlice = createSlice({
    name: 'quiz',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder 
            .addCase(newQuizRecord.pending, (state) => {
                state.isLoading = true
            })
            .addCase(newQuizRecord.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.data = action.payload
                // state.quizData.push(action.payload)
            })
            .addCase(newQuizRecord.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(retrieveQuizRecord.pending, (state) => {
                state.isLoading = true
            })
            .addCase(retrieveQuizRecord.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.data = action.payload
            })
            .addCase(retrieveQuizRecord.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    }
})

export const { reset } = quizSlice.actions;
export default quizSlice.reducer;