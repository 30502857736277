import { useSelector } from "react-redux";
import { GiWeightScale, GiButter, GiBroccoli } from 'react-icons/gi';
import { TbMeat } from 'react-icons/tb';
import { MiniSpinner} from '../components';

const MealplanDataBar = () => {

    const { mealplan, } = useSelector((state) => state);
    const isMealplanDataPopulated = mealplan.data && Object.keys(mealplan.data).length > 0;
    const metric = isMealplanDataPopulated ? mealplan.data.metric : null;

    if (!isMealplanDataPopulated) {
        return <MiniSpinner />; // Display a loading message while the data is being loaded
      }

    const dataItemsArray = [
            {
            // data: mealplan.data.weightKgTarget,    
            data: metric ? mealplan.data.weightKgTarget : mealplan.data.weightLbsTarget,
            measure: metric ? `kg` : `lb`,
            icon: <GiWeightScale />,
            amount: '39,354',
            period: ' ',
            title: 'Goal Weight',
            iconColor: '#03C9D7',
            iconBg: '#E5FAFB',
            pcColor: 'green-600',    
            },
            {
            data: mealplan.data.lowerProteinGrams,
            measure: 'gr',    
            icon: <TbMeat />,
            amount: '4,396',
            period: 'daily',
            title: 'Proteins',
            iconColor: 'rgb(255, 244, 229)',
            iconBg: 'rgb(254, 201, 15)',
            pcColor: 'green-600',
            },
            {
            data: mealplan.data.lowerFatGrams, 
            measure: 'gr',   
            icon: <GiButter />,
            amount: '423,39',
            period: 'daily',
            title: 'Fats',
            iconColor: 'rgb(228, 106, 118)',
            iconBg: 'rgb(255, 244, 229)',
            pcColor: 'green-600',
            },
            {
            data: mealplan.data.lowerCarbsGrams, 
            measure: 'gr',   
            icon: <GiBroccoli />,
            amount: '39,354',
            period: 'daily',
            title: 'Carbs',
            iconColor: 'rgb(0, 194, 146)',
            iconBg: 'rgb(235, 250, 242)',
            pcColor: 'green-600',
            },
        ]

    return(
       <div className='flex flex-wrap lg:flex-nowrap justify-center'>
            
        <div className='grid gap-3 grid-cols-2 md:grid-cols-4'>
            {dataItemsArray.map((item) => (
                <div key={item.title}    
                    className='bg-white
                    dark:text-gray-200
                    dark:bg-secondary-dark-bg
                    md:w-56 p-4 pt-9 rounded-2xl flex flex-col items-center'>
                <button 
                    disabled={true}
                    style={{
                    color:item.iconColor,
                    backgroundColor: item.iconBg  
                    }}
                    className='text-2xl opacity-0.9 rounded-full 
                    p-4 hover:drop-shadow-xl'>
                    {item.icon}
                </button>      
                <p className='mt-3'>
                    <span className='text-lg font-semibold'>
                        {item.data} {item.measure}
                    </span>
                    <span className={`text-sm text-${item.pcColor} ml-2`}>
                    {item.period}
                    </span>
                    </p>
                    <p className='text-sm text-gray-400 mt-1'>{item.title}</p>  
                </div>
            ))}
        </div>

      </div>
    )
}

export default MealplanDataBar;