import { CheckIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
export default function SurveyProgress(props) {
    
    const { step } = props;

    const steps = [
        { name: 'Step 1', status: '' },
        { name: 'Step 2', status: '' },
        { name: 'Step 3', status: '' },
        { name: 'Step 4', status: '' },
        { name: 'Step 5', status: '' },
        { name: 'Step 6', status: '' },
        { name: 'Step 7', status: '' },
        { name: 'Step 8', status: '' },
      ]

    // Loop through the steps array and update the status of the current and completed steps
    for (let i = 0; i < steps.length; i++) {
        if (i < step) {
            steps[i].status = 'complete';
        } else if (i === step) {
            steps[i].status = 'current';
        } else {
            steps[i].status = '';
        }
    }

  return (
    <nav aria-label="Progress">
      <ol className="p-2 flex mx-auto justify-center items-center">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pr-8 sm:pr-20' : '', 'relative')}>
            {step.status === 'complete' ? (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-indigo-600" />
                </div>
                <span

                  className="relative flex h-4 w-4 items-center justify-center rounded-full bg-indigo-600 hover:bg-indigo-900"
                >
                  <CheckIcon className="h-3 w-3 text-white" aria-hidden="true" />
                  <span className="sr-only">{step.name}</span>
                </span>
              </>
            ) : step.status === 'current' ? (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <span
                  className="relative flex h-3 w-3 items-center justify-center rounded-full border-2 border-indigo-600 bg-white"
                  aria-current="step"
                >
                  <span className="h-2.5 w-2.5 rounded-full bg-indigo-600" aria-hidden="true" />
                  <span className="sr-only">{step.name}</span>
                </span>
              </>
            ) : (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <span
                  className="group relative flex h-3 w-3 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400"
                >
                  <span
                    className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </span>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}