import axios from "axios";

const API_URL = 'api/quiz/';


// Creat new quiz record
const newQuizRecord = async (quizData) => {
    const response = await axios.post(API_URL + 'newquizrecord', quizData);
    return response.data;
}

// Retieve quiz record
const retrieveQuizrecord = async (optinId) => {
    const response = await axios.post(API_URL + 'retrievequizrecord', optinId);
    return response.data
}

const quizService = {
    newQuizRecord,
    retrieveQuizrecord
}


export default quizService;