import Vimeo from '@u-wave/react-vimeo';
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect} from 'react';
import {BsPlayCircle} from 'react-icons/bs';
import {AiOutlineClose} from 'react-icons/ai';


export default function VideoPageVideoSection(props) {

  const [open, setOpen] = useState(false);
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const [videoId, setVideoId] = useState('')

  const {image, videos} = props;

  useEffect(() => {
    let isMounted = true;

    return () => {
      isMounted = false;
      setOpen(false)
    }
  }, [])

  const handleClick = () => {
    setOpen(false)
  }

  const handlePlayerReady = () => {
    setIsPlayerReady(true);
  };

    return (
      <>
      <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
        {videos.map((video) => (           
          <li key={video.number} className="relative">
            <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
              <img src={image} alt="Bulletproof Keto Diet CD" className="pointer-events-none object-cover group-hover:opacity-75" />
              <div className="mb-16 absolute inset-0 flex items-center justify-center">
                <button
                  type="button"
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-pink-600 px-2.5 py-1.5 text-lg font-semibold text-white shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600"
                  onClick={() => {
                    setOpen(true);
                    setVideoId(video.videoId)
                    }}
                >
                  Play<BsPlayCircle className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  
              </button>
              </div>
            </div>
            <p className="pointer-events-none text-center mt-2 block text-lg font-semibold text-gray-900">{video.title}</p>
          </li>
        ))}
      </ul>
      <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="flex justify-center items-center">
                  <Vimeo
                    video={videoId}
                    width="640"
                    height="360"
                    autoplay
                    loop
                    showTitle={false}
                    showByline={false}
                    showPortrait={false}
                    onReady={handlePlayerReady}
                  />
                  <button 
                    className="absolute top-0 text-2xl right-0 p-2" 
                    onClick={handleClick}>
                    <AiOutlineClose />
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </>
      )
    }
