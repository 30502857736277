import {useSelector, useDispatch} from 'react-redux';
import { cancel, } from '../features/auth/authSlice';

const CancelSubscription = () => {

  const dispatch = useDispatch();

  const { user, } = useSelector(
    (state) => state.auth
  )

  const { buyerId, status, current_period_end } = user;  
  // Convert time to miliseconds
  var date = new Date(current_period_end * 1000);
  // Extract components
  var year = date.getUTCFullYear();
  var month = date.getUTCMonth();
  var day = date.getUTCDate();
  const formattedDateTime = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`
   
  const hadleCancel = () => {
    dispatch(cancel({buyerId: buyerId}))
  }
  

  return (
    <div className="flex border-t border-gray-100 pt-6">
        {status === "active" ? (
            <button 
              type="button" 
              onClick={hadleCancel}
              className="text-md font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
              Cancel my subscription
            </button>
        ) : (
            <p className="text-md font-semibold leading-6 text-indigo-600">
              Your subscription was {status}. You will have access to your account untill  {formattedDateTime}
            </p>
        )}
    </div>
  )
}

export default CancelSubscription