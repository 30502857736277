import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import {RecipeInstructions, 
        IngridientsList, 
        NutritionList,
        MetricToggle} from '../components'

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ')
// }

export default function RecipeDetailsSlide({recipe, open, onClose}) {

    const [metric, setMetric] = useState(false);
    const toggleMetric = () => {
      setMetric(!metric);
    };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-7xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-2xl font-semibold leading-6 text-gray-900">Recipe</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-500 text-4xl hover:text-gray-900 focus:ring-2 focus:ring-pink-500"
                            onClick={onClose}
                          >
                            {/* <span className="sr-only">Close panel</span> */}
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Main */}
                    <div className="divide-y divide-gray-200">
                      <div className="pb-6">
                        <div className="h-24 bg-pink-600 sm:h-20 lg:h-28" />
                        <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                          <div>
                            <div className="-m-1 flex">
                              <div className="inline-flex outline outline-pink-900 overflow-hidden rounded-lg border-4 border-pink-400">
                                <img
                                  className="h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"
                                  src={recipe.details.image}
                                  alt="Coconut Shrimp Cakes with Mango Pineapple"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mt-6 sm:ml-6 sm:flex-1">
                            <div>
                              <div className="flex items-center">
                                <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">{recipe.details.title}</h3>
                              </div>
                            </div>
                            <div className="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
                              <MetricToggle metric={metric} toggleMetric={toggleMetric}/>
                              <div className="ml-3 pt-3 align-middle inline-flex sm:ml-0">
                                <p className='text-gray-900 mr-1 font-medium'>Carbs: {Math.round(recipe.details.nutrition.caloricBreakdown.percentCarbs)} % |</p>
                                <p className='text-gray-900 mr-1 font-medium'>Protein: {Math.round(recipe.details.nutrition.caloricBreakdown.percentProtein)} % |</p>
                                <p className='text-gray-900 mr-1 font-medium'>Fat: {Math.round(recipe.details.nutrition.caloricBreakdown.percentFat)} %</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="px-4 py-2 sm:px-0 sm:py-0">
                        <dl className="space-y-4 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                        <div className="sm:flex sm:px-6 sm:py-2">
                            <dt className="text-md uppercase font-medium text-gray-900 sm:w-40 sm:flex-shrink-0 lg:w-48">Ingridients</dt>
                            <dd className="mt-1 text-md text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                <IngridientsList metric={metric} ingridients={recipe.details.extendedIngredients}/>
                            </dd>
                          </div>   
                          <div className="sm:flex sm:px-6 sm:py-2">
                            <dt className="text-md uppercase font-medium text-gray-900 sm:w-40 sm:flex-shrink-0 lg:w-48">Instructions</dt>
                            <dd className="mt-1 text-md text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                                <RecipeInstructions instructions={recipe.details.analyzedInstructions}/>
                            </dd>
                          </div>
                          <div className="sm:flex sm:px-6 sm:py-5">
                            <dt className="text-md uppercase font-medium text-gray-900 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Nutrition
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                              <NutritionList nutrition={recipe.details.nutrition.nutrients}/>
                            </dd>
                          </div>
                          {/* <div className="sm:flex sm:px-6 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Website
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                              ashleyporter.com
                            </dd>
                          </div>
                          <div className="sm:flex sm:px-6 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Birthday
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                              <time dateTime="1982-06-23">June 23, 1982</time>
                            </dd>
                          </div> */}
                        </dl>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
