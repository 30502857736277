import axios from 'axios';

// const API_URL = "http://localhost:5000/api/mealplan/";
// const API_URL = 'https://eattoburn.com/api/mealplan/'
const API_URL = process.env.REACT_APP_SERVERSIDE_BASE_API + '/mealplan/';


const loadRecipes = async (recipesParams, token) => {

    const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
    }

    /********** New function checks localStorage 1st **************/
    const localRecipesData = localStorage.getItem('mealplanRecipes');
    /* if recipes stored in local storage return the data if not fetch */
    /* recipes from API and store to localStorage*/
    if (localRecipesData){
        return JSON.parse(localRecipesData)

    } else {
        const response = await axios.post(API_URL + 'getmealplan/', recipesParams, config);

        if(response.data){
            localStorage.setItem('mealplanRecipes', JSON.stringify(response.data));
        }

        return response.data;
    }
}

const recipesService = {
    loadRecipes
}

export default recipesService;