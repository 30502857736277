import { BiArrowBack } from "react-icons/bi";

const SurveyTitle = (props) => {
  // console.log('Title',props.props.previousStep)

  const handlePreviousStep = (stepValue) => {
    props.props.previousStep(stepValue);
  }

  return (
    <div className="p-3 flex items-center">
        <div className="flex-shrink-0">
          <button 
            onClick={() => 
              props.props.step === 0 ? window.location.reload() : handlePreviousStep(props.props.step)}
            >
            <BiArrowBack className="text-gray-600 hover:text-gray-900 font-medium h-6 w-6" />
          </button>  
        </div>
        <h5 className="text-md uppercase font-medium text-gray-900 flex-grow text-center">
         Nutrition Survey
        </h5>
    </div>
  )
}

export default SurveyTitle