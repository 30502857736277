import React from 'react';
import { useNavigate } from 'react-router-dom';

const ButtonGetMyPlan = () => {
  const navigate = useNavigate();

  const to = '/subscribe';
  const buttonText = " Get my plan"

//   const handleClick = (e) => {
//     if (to) {
//       navigate(to);
//     }
//     if (onClick) {
//       onClick(e);
//     }
//   };

  return (
    // <button
    //     className="bg-orange-500 hover:bg-orange-600 text-white text-md uppercase font-medium py-2 px-14 rounded"
    //     onClick={() => navigate('/subscribe')}
    // >
    //     Get my plan
    // </button>
    <button
      className={`bg-orange-500 hover:bg-orange-600 text-white text-md uppercase font-medium py-2 px-14 rounded`}
      // className={`bg-teal-600 hover:bg-green-700 text-white text-md uppercase font-medium py-2 px-14 rounded`}
      onClick={() => navigate(to)}
    >
      {buttonText}
    </button>
  );
};

export default ButtonGetMyPlan;