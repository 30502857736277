// Service to create create buyer record and retrieve buyer records from server
// When buyer record is created save buyerId to localStorage
import axios from 'axios';

// const API_URL = "http://localhost:5000/api/user/";
// const API_URL = "http://localhost:5000/api/buyer/";
// const API_URL = 'https://eattoburn.com/api/buyer/'
const API_URL = process.env.REACT_APP_SERVERSIDE_BASE_API + '/buyer/';


/* Create buyer record when purchase is complited 
and save buyerId to local storage */ 

const newbuyer = async (buyerData) => {
    const response = await axios.post(API_URL + 'newbuyer/', buyerData);
    // console.log('Buyer service .. ', response.data)
    if(response.data){
        // save to localStorage as {"buyerId":"xxxxxxx"}
        const {buyerId} = response.data.newBuyer;
        localStorage.setItem('buyerId', JSON.stringify(buyerId))
    }

    return response.data
}

/* Retrive buyer record to get mealplan details when user logs in 
and save buyerId to local storage */ 
const buyer =  async (buyerId) => {
    const response =  await axios.get(API_URL + 'buyer/', buyerId);

    if(response.data){
        localStorage.setItem('buyerId', JSON.stringify(response.data))
    }

    return response.data
}

/* Retrieve full buyer record  */
const buyerRecord = async (buyerId) => {

    const response = await axios.post(API_URL + 'buyerrecord/', {buyerId: buyerId});
    return response.data
}

const buyerService = {
    buyer,
    newbuyer,
    buyerRecord
}

export default buyerService;