import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { newOptin, reset } from '../features/optin/optinSlice';
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon
} from '@heroicons/react/24/outline'

import IceCreamBackground from '../../src/assets/img/landing/background-landing-min1.webp';

import Logo from '../assets/img/logoWhiteTransparent.png';

import Chevron from '../assets/img/landing/chevron-down-white.original.gif'
import { RotatingLines } from  'react-loader-spinner'


const navigation = [
  { name: 'Login', href: '/login' },
]

const footerNavigation = {
  solutions: [
    { name: 'Marketing', href: '#' },
    { name: 'Analytics', href: '#' },
    { name: 'Commerce', href: '#' },
    { name: 'Insights', href: '#' },
  ],
  support: [
    { name: 'Pricing', href: '#' },
    { name: 'Documentation', href: '#' },
    { name: 'Guides', href: '#' },
    { name: 'API Status', href: '#' },
  ],
  company: [
    { name: 'About', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'Jobs', href: '#' },
    { name: 'Press', href: '#' },
    { name: 'Partners', href: '#' },
  ],
  legal: [
    { name: 'Claim', href: '#' },
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/EatToBurn',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/eat2burn/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    // {
    //   name: 'Twitter',
    //   href: '#',
    //   icon: (props) => (
    //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    //       <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
    //     </svg>
    //   ),
    // },
    {
      name: 'YouTube',
      href: 'https://www.youtube.com/@EatToBurn',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
   <path d="M21.32 7.58c-.26-.95-1-1.7-1.96-1.95C17.85 5 12 5 12 5s-5.85 0-7.36.63c-.96.25-1.7 1-1.96 1.95C2 9.09 2 12 2 12s0 2.91.63 4.42c.26.95 1 1.7 1.96 1.95C6.15 19 12 19 12 19s5.85 0 7.36-.63c.96-.25 1.7-1 1.96-1.95C22 14.91 22 12 22 12s0-2.91-.63-4.42zM9.5 15V9l6 3-6 3z"/>

        </svg>
      ),
    },
    {
      name: 'TikTok',
      href: 'https://www.tiktok.com/@eattoburn',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" ></path> 
        </svg>
      ),
    },
  ],
}


export default function Landing() {

  /* Optin functionalities */
 // GEO Location 
// const geoLocation = navigator.geolocation.getCurrentPosition(
//     function(position) {
//       // Access the latitude and longitude
//       const latitude = position.coords.latitude;
//       const longitude = position.coords.longitude;
  
//       // Use the latitude and longitude as needed
//     //   console.log("Latitude:", latitude);
//     //   console.log("Longitude:", longitude);
//     },
//     function(error) {
//       // Handle errors or provide fallback
//       console.error("Error getting location:", error);
  
//       // Provide fallback values or options
//       const fallbackLatitude = 0; // Default latitude
//       const fallbackLongitude = 0; // Default longitude
//     //   console.log("Using fallback location");
//     //   console.log("Latitude:", fallbackLatitude);
//     //   console.log("Longitude:", fallbackLongitude);
//     }
//   );
const location = useLocation();
const navigate = useNavigate();
const dispatch = useDispatch();

const [ optinData, setOptinData] = useState({
  email: '',
  referrer: document.referrer,
  hostname: window.location.hostname,
  userAgent: window.navigator.userAgent,
  screenWidth: window.innerWidth,
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  language: window.navigator.language,
  platform: window.navigator.platform,
  latitude: '',
  longitude: '',
  utmSource: '',
  utmMedium: '',
  utmCampaign: '',
});

const [errorMessage, setErrorMessage] = useState('');

const { email } = optinData;

// Take optin data that is saved in the state after sending it to backend
const { data, isLoading, isError, isSuccess, message} = useSelector(
  (state) => state.optin
)

useEffect(() => {

  const params = new URLSearchParams(location.search);
  setOptinData(prevData => ({
    ...prevData,
    utmSource: params.get('utm_source') || '',
    utmMedium: params.get('utm_medium') || '',
    utmCampaign: params.get('utm_campaign') || '',
  }));

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setOptinData((prevData) => ({
          ...prevData,
          latitude: latitude.toString(),
          longitude: longitude.toString()
        }));
      },
      (error) => {
        // console.log('Geolocation error:', error.code, error.message);
        let locationStatus;
        switch(error.code) {
          case error.PERMISSION_DENIED:
            locationStatus = 'permission denied';
            break;
          case error.POSITION_UNAVAILABLE:
            locationStatus = 'unavailable';
            break;
          case error.TIMEOUT:
            locationStatus = 'timeout';
            break;
          default:
            locationStatus = 'error';
        }
        setOptinData((prevData) => ({
          ...prevData,
          latitude: locationStatus,
          longitude: locationStatus
        }))
      },
      {timeout: 10000} // 10 sec timeout
    );
  } else {
    // console.log('Geolocation is not supported by this browser.')
    setOptinData((prevData)=>({
      ...prevData,
      latitude: 'not supported',
      longitude: 'not supported'
    }))
  }

  // if(isSuccess){
  //   navigate('/quiz')
  // }

  // dispatch(reset())
}, [location])  

useEffect(() => {
  if (isError) {
    console.error('Optin error:', message);
    setErrorMessage(message);
  }

  if (isSuccess) {

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'gtm.historyChange',
      'customerData': {
        'email': data.newOptinRecord.email,
        'optinId': data.newOptinRecord.optinId
      }
    })

    navigate('/quiz');
  }

  return () => {
    if (isSuccess || isError) {
      // console.log('Resetting optin state');
      dispatch(reset());
    }
  };
}, [isLoading, isError, isSuccess, message, navigate, dispatch])

// Method handles form submission and validates email input field.
const validateEmail = () => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!email || !emailRegex.test(email)) {
    setErrorMessage('Please enter a valid email address');
    return false;
  }

  return true;
};

const handleSubmit = (event) => {
  event.preventDefault();

  if (validateEmail()) {
    // Valid email, proceed with form submission
    // Add your form submission logic here
    // console.log('Sibmitting optin data Page .... ',optinData)
    dispatch(newOptin(optinData))
  }
};

const handleChange = (event) => {
  setOptinData((prevState) => ({
    ...prevState,
    email: event.target.value,
  }));

};
const currentYear = new Date().getFullYear();

  return (
    <div className="bg-white">
      <div className="relative overflow-hidden">
        <Popover as="header" className="relative">
          <div className="bg-purple-900 pt-6">
            <nav className="relative mx-auto flex max-w-7xl items-center justify-between px-6" aria-label="Global">
              <div className="flex flex-1 items-center">
                <div className="flex w-full items-center justify-between md:w-auto">
                  <a href="/">
                    <span className="sr-only">EatToBurn</span>
                    <img
                      className="h-8 w-auto sm:h-10"
                      src={Logo}
                      // src="https://tailwindui.com/img/logos/mark.svg?from-color=teal&from-shade=200&to-color=cyan&to-shade=400&toShade=400"
                      alt="EatToBurn Logo"
                    />
                  </a>
                  <div className="-mr-2 flex items-center md:hidden">
                    <Popover.Button className="focus-ring-inset inline-flex items-center justify-center rounded-md bg-purple-500 p-2 text-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                {/* <div className="hidden space-x-8 md:ml-10 md:flex">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="text-base font-medium text-white hover:text-gray-300"
                    >
                      {item.name}
                    </a>
                  ))}
                </div> */}
              </div>
              <div className="hidden md:flex md:items-center md:space-x-6">
                <Link 
                  to="/login" 
                  className="inline-flex items-center rounded-md border border-transparent opacity-80 bg-purple-400 px-4 py-2 text-base font-medium text-white hover:bg-purple-500"
                  // className="text-base font-medium text-white hover:text-gray-300"
                  >
                  Log in
                </Link>
                {/* <a href="/login" className="text-base font-medium text-white hover:text-gray-300">
                  Log in
                </a> */}
                {/* <a
                  href="#"
                  className="inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white hover:bg-gray-700"
                >
                  Start free trial
                </a> */}
              </div>
            </nav>
          </div>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel focus className="absolute inset-x-0 top-0 origin-top transform p-2 transition md:hidden">
              <div className="overflow-hidden rounded-lg bg-purple-900 shadow-md ring-1 ring-white ring-opacity-5">
                <div className="flex items-center justify-between px-5 pt-4">
                  <div>
                    <img
                      className="h-8 w-auto"
                      src={Logo}
                      // "https://tailwindui.com/img/logos/mark.svg?from-color=teal&from-shade=500&to-color=cyan&to-shade=600&toShade=600"
                      alt="Eat To Burn"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-purple-500 p-2 text-white-400 hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="pb-6 pt-5">
                  <div className="space-y-1 px-2">
                    {navigation.map((item) => (
                      // <a
                      //   key={item.name}
                      //   href={item.href}
                      //   className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-gray-50"
                      // >
                      //   {item.name}
                      // </a>
                      <Link to={item.href}>
                      <p key={item.name}
                        className="block w-full rounded-md bg-gradient-to-r from-purple-500 to-purple-600 px-4 py-3 text-center font-medium text-white shadow hover:from-purple-400 hover:to-purple-500">
                        Existing customer?{' '}Login
                      </p>
                      </Link>
                    ))}
                  </div>
                  {/* <div className="mt-6 px-5">
                    <a
                      href="#"
                      className="block w-full rounded-md bg-gradient-to-r from-teal-500 to-cyan-600 px-4 py-3 text-center font-medium text-white shadow hover:from-teal-600 hover:to-cyan-700"
                    >
                      Start free trial
                    </a>
                  </div> */}
                  {/* <div className="mt-6 px-5">
                    <p className="text-center text-base font-medium text-gray-500">
                      Existing customer?{' '}
                      <a href="#" className="text-gray-900 hover:underline">
                        Login
                      </a>
                    </p>
                  </div> */}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
        <main>
          <div className="flex flex-col items-center justify-center h-screen pt-10 sm:pt-16 lg:overflow-hidden lg:pb-14 lg:pt-8"
               style={{ 
                backgroundImage: `url(${IceCreamBackground})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                }} >
              <div className="mx-auto max-w-7xl lg:px-8 inset-0 ">
                <div className="lg:grid lg:grid-cols-1 lg:gap-8">
                  <div className="mx-auto max-w-md px-6 sm:max-w-2xl sm:text-center lg:flex lg:items-center lg:justify-center lg:px-0 lg:text-left">
                    <div className="lg:py-24" >
                      {/* <a
                        href="#"
                        className="inline-flex items-center rounded-full bg-black p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base"
                      >
                        <span className="rounded-full bg-gradient-to-r from-teal-500 to-cyan-600 px-3 py-0.5 text-sm font-semibold leading-5 text-white">
                          We're hiring
                        </span>
                        <span className="ml-4 text-sm">Visit our careers page</span>
                        <ChevronRightIcon className="ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                      </a> */}
                      <h1 className="mt-4 text-4xl text-center font-bold tracking-tight sm:mt-3 sm:text-6xl lg:mt-6 xl:text-6xl">
                        <span className="block  drop-shadow-xl saturate-200 contrast-150 bg-gradient-to-r pb-4 from-amber-300 to-amber-300 bg-clip-text text-transparent">
                          45 lbs lighter
                        </span>
                        <span className="block drop-shadow-xl saturate-200 contrast-150 bg-gradient-to-r from-amber-300 to-amber-300 bg-clip-text pb-3 text-transparent sm:pb-5">
                          and a lot happier
                        </span>
                      </h1>
                      <h4 className="mt-2 text-center font-medium tracking-tight sm:mt-3 lg:mt-3  text-2xl sm:text-2xl xl:text-3xl">
                        <span className="block drop-shadow-xl saturate-200 contrast-200 text-shadow-lg bg-gradient-to-r pb-1 from-amber-300 to-amber-300 bg-clip-text text-transparent">
                          Take this 20-seconds quiz to
                        </span>
                        <span className="block drop-shadow-xl saturate-200 contrast-200 bg-gradient-to-r from-amber-300 to-amber-300 bg-clip-text text-transparent sm:pb-1">
                          find out how
                        </span>
                      </h4>
                      {/* <p className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                        Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui Lorem cupidatat commodo. Elit
                        sunt amet fugiat veniam occaecat fugiat.
                      </p> */}
                      {/* <div><img src={Chevron} className="w-20 h-20"/></div> */}
                      <div className="mt-1 sm:mt-1">
                        <img src={Chevron} alt='Chevron' className="mx-auto w-20 h-20"/>
                        <form 
                          id="homeOptinForm" 
                          onSubmit={handleSubmit} 
                          className="sm:mx-auto sm:max-w-xl lg:mx-0">
                          <div className="sm:flex">
                            <div className="min-w-0 flex-1">
                              <label htmlFor="email" className="sr-only">
                                Email address
                              </label>
                              <input
                                id="email"
                                type="email"
                                placeholder="Enter your best email"
                                value={email}
                                onChange={handleChange}
                                className="block w-full rounded-md border-0 px-4 py-3 text-xl text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-pink-200 focus:ring-offset-2 focus:ring-offset-pink-400"
                              />
                              {errorMessage && <p className="text-white">{errorMessage}</p>}
                              <button
                                type="submit"
                                className="flex justify-center items-center w-full border text-xl border-white uppercase rounded-md bg-gradient-to-r from-pink-500 to-pink-600 mt-3 px-4 py-3 font-medium text-white shadow hover:from-pink-600 hover:to-pink-700 focus:outline-none focus:ring-2 focus:ring-pink-400 focus:ring-offset-2 focus:ring-offset-gray-900"
                              >
                                {isLoading ? (
                                  <div className='h-9 w-9'>
                                    <RotatingLines 
                                      strokeColor="white"
                                      strokeWidth="3"
                                      animationDuration="1.75"
                                      width="36"
                                      visible={true}/>
                                  </div>)
            
                              : ('Continue')}
                              </button>
                            </div>
                            {/* <div className="mt-3 sm:ml-3 sm:mt-0">
                              <button
                                type="submit"
                                className="block w-full rounded-md bg-gradient-to-r from-teal-500 to-cyan-600 px-4 py-3 font-medium text-white shadow hover:from-teal-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900"
                              >
                                Start free trial
                              </button>
                            </div> */}
                          </div>
                          <p className="mt-3 text-sm text-center text-gray-300 sm:mt-4">
                            <a href="/legal/termsofuse" target="_blank" className="font-medium text-white capitalize">
                              terms of service
                            </a> 
                            {' '}and{' '}
                            <a href="/legal/privacypolicy" target="_blank" className="font-medium text-white capitalize">
                              privacy policy
                            </a> 
                            .
                          </p>
                          {/* <p className="mt-3 text-sm text-gray-300 sm:mt-4">
                           By providing your email, you agree to our{' '}
                            <a href="#" className="font-medium text-white">
                              terms of service
                            </a> 
                            {' '}and{' '}
                            <a href="#" className="font-medium text-white">
                              privacy policy
                            </a> 
                            .
                          </p> */}
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="-mb-16 mt-12 sm:-mb-48 lg:relative lg:m-0">
                    <div className="mx-auto max-w-md px-6 sm:max-w-2xl lg:max-w-none lg:px-0">
                      {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}
                      {/* <img
                        className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                        // src="https://tailwindui.com/img/component-images/cloud-illustration-teal-cyan.svg"
                        src={IceCreamBackground}
                        alt="Keto Ice Cream"
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
          </div>

        </main>
        <footer className="bg-purple-950" aria-labelledby="footer-heading">
          <h2 id="footer-heading" className="sr-only">
            Footer
          </h2>
          <div className="mx-auto max-w-md px-6 pt-12 sm:max-w-7xl lg:px-8 lg:pt-16">
            <div className="xl:grid xl:grid-cols-3 xl:gap-8">
              <div className="space-y-4 xl:col-span-1">
                <img
                  className="h-10"
                  src={Logo}
                  alt="Eat To Burn"
                />
                <h4 className="mt-0 font-medium capitalize text-base text-gray-300">Get into the best shape of your life, without ever feeling hungry.</h4>
                <p className="text-base text-gray-300">
                Start your personalized weigh loss program that is effective, convenient, and most importantly, enjoyable.
                </p>
                <div className="flex space-x-6">
                  {footerNavigation.social.map((item) => (
                    <a key={item.name} href={item.href} className="text-gray-300 hover:text-gray-200">
                      <span className="sr-only">{item.name}</span>
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </a>
                  ))}
                </div>
              </div>
              <div className="mt-12 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
                <div className="md:grid md:grid-cols-2 md:gap-8">
                  {/* <div>
                    <h3 className="text-base font-medium text-gray-300">Solutions</h3>
                    <ul className="mt-4 space-y-4">
                      {footerNavigation.solutions.map((item) => (
                        <li key={item.name}>
                          <a href={item.href} className="text-base text-gray-300 hover:text-gray-200">
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div> */}
                  {/* <div className="mt-12 md:mt-0">
                    <h3 className="text-base font-medium text-gray-900">Support</h3>
                    <ul role="list" className="mt-4 space-y-4">
                      {footerNavigation.support.map((item) => (
                        <li key={item.name}>
                          <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div> */}
                </div>
                <div className="md:grid md:grid-cols-2 md:gap-8">
                  {/* <div>
                    <h3 className="text-base font-medium text-gray-900">Company</h3>
                    <ul role="list" className="mt-4 space-y-4">
                      {footerNavigation.company.map((item) => (
                        <li key={item.name}>
                          <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div> */}
                  {/* <div className="mt-12 md:mt-0">
                    <h3 className="text-base font-medium text-gray-900">Legal</h3>
                    <ul role="list" className="mt-4 space-y-4">
                      {footerNavigation.legal.map((item) => (
                        <li key={item.name}>
                          <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="mt-12 border-t border-gray-200 py-8">
              <p className="text-base text-gray-400 xl:text-center">
              Copyright  &copy; {currentYear} EatToBurn.com. All rights reserved.
              </p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}
