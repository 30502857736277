import React from 'react'
import { VscFlame } from "react-icons/vsc";
import { Link, useLocation } from 'react-router-dom';
import { ResetPasswordEmail, ResetPasswordPassword } from '../components';
// condition for id in reset url or query url 
const FogotPassword = () => {
  
  const location = useLocation();
  const { pathname } = location;

  const isResetEmail = pathname === '/reset';
  const isResetPassword = pathname === '/reset/password';

  return (
    <>
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8
      bg-gradient-to-b from-cyan-300 to-white">
        <div className="sm:mx-auto sm:w-full sm:max-w-md text-pink-900">
          <VscFlame className="mx-auto h-12 w-auto" />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-pink-900">Reset Your Password</h2>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
            <>
              {isResetEmail && <ResetPasswordEmail/>}
              {isResetPassword && <ResetPasswordPassword />}
            </>
            <p className='text-md my-8 text-indigo-600 hover:text-indigo-500'>
              <Link to='/login' className="font-medium ">
                Return to login page
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>  
  )
}

export default FogotPassword