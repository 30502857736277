import {GiEating} from 'react-icons/gi';

export default function NutritionList(nutrition) {
    return (
      <ul className="divide-y divide-gray-100">
        
        {nutrition.nutrition.map((item) => (
          <li
            key={nutrition.nutrition.indexOf(item)}
            className="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-5 sm:flex-nowrap"
          >
            <div className='flex px-6 flex-grow items-center'>
              <p className="text-lg font-medium leading-7 text-gray-900">
                 {item.name}
              </p>
            </div>
            <dl className="flex w-full flex-none justify-between gap-x-8 sm:w-auto">
              <div className="flex sm:ml-10 lg:ml-44 w-16 text-2xl gap-x-2.5">
                <dt><GiEating /></dt>
                  <dd className="flex text-lg leading-6 text-gray-900">
                    <span className='mx-2'>{item.amount}</span> 
                    <span>{item.unit}</span>
                  </dd>
              </div>
            </dl>
          </li>
        ))}
      </ul>
    )
  }