
const VideoProperties = [
    {
        number: 1,
        title: "Introduction",
        url: "https://player.vimeo.com/video/435305050",
        videoId: "435305050"
    },
    {
        number: 2,
        title: "Tracking Your Current Diet",
        url: "https://player.vimeo.com/video/431851718",
        videoId: "431851718"
    },
    {
        number: 3,
        title: 'Starting a Food Journal',
        url: 'https://player.vimeo.com/video/431851836',
        videoId: '431851836'
    },
    {
        number: 4,
        title: 'Getting Rid Of Problem Foods',
        url: 'https://player.vimeo.com/video/431851912',
        videoId: '431851912'
    },
    {
        number: 5,
        title: 'Winning The Race ',
        url: 'https://player.vimeo.com/video/431851999',
        videoId: '431851999'
    },
    {
        number: 6,
        title: 'Getting Best Results',
        url: 'https://player.vimeo.com/video/431852056',
        videoId: '431852056'
    },
    {
        number: 7,
        title: 'Creating a Calorie Deficit',
        url: 'https://player.vimeo.com/video/431852107',
        videoId: '431852107'
    },
    {
        number: 8,
        title: 'Introduce Fiber Into Your Daily Diet',
        url: 'https://player.vimeo.com/video/431852180',
        videoId: '431852180'
    },
    {
        number: 9,
        title: 'The Importance Of Drinking Water For Weight Loss',
        url: 'https://player.vimeo.com/video/431852620',
        videoId: '431852620'
    },
    {
        number: 10,
        title: 'Lean Meats & Healthy Protein Sources',
        url: 'https://player.vimeo.com/video/431853029',
        videoId: '431853029'
    },
    {
        number: 11,
        title: 'Weight Loss Tips & Tricks',
        url: 'https://player.vimeo.com/video/431853278',
        videoId: '431853278'
    },
    {
        number: 12,
        title: 'Conclusion',
        url: 'https://player.vimeo.com/video/435305118',
        videoId: '435305118'
    }
]

export default VideoProperties;