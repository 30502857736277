/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
// import { Fragment } from 'react'
import {useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { 
  Popover, 
  // Transition 
} from '@headlessui/react'
// import {
//   ArrowPathIcon,
//   Bars3Icon,
//   CloudArrowUpIcon,
//   CogIcon,
//   LockClosedIcon,
//   ServerIcon,
//   ShieldCheckIcon,
//   XMarkIcon,
// } from '@heroicons/react/24/outline'
// import { ArrowTopRightOnSquareIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { RotatingLines } from  'react-loader-spinner';

import Logo from '../assets/img/logoWhiteTransparent.png';
// import PizzaBaground from '../assets/img/ketopizzalanding/pizza-background-image.webp';
import PizzaBaground from '../assets/img/ketopizzalanding/large-pizza-pie.webp';
// import PizzaSlices from '../assets/img/ketopizzalanding/pizza-slices.webp';
import SpecialPizza from '../assets/img/ketopizzalanding/special-pizza-view.webp';
import PizzaPies from '../assets/img/ketopizzalanding/pizza-pies.webp';
import ChickenCrustPizza from '../assets/img/ketopizzalanding/chicken-crust-philly-cheese-stake.webp';
import ThaiChickenPizza from '../assets/img/ketopizzalanding/thai-chicken-pizza.webp';
import ZucchiniCrust from '../assets/img/ketopizzalanding/zhiccini-crust.webp';

import Roel from '../assets/img/ketopizzalanding/roel.webp';
import Brenna from '../assets/img/ketopizzalanding/brenna.webp';
import Daniela from '../assets/img/ketopizzalanding/daniella.webp';

const API_URL = 'api/ketopizza/optin';
// const navigation = [
//   { name: 'Product', href: '#' },
//   { name: 'Features', href: '#' },
//   { name: 'Marketplace', href: '#' },
//   { name: 'Company', href: '#' },
// ]
// const features = [
//   {
//     name: 'Push to Deploy',
//     description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi vitae lobortis.',
//     icon: CloudArrowUpIcon,
//   },
//   {
//     name: 'SSL Certificates',
//     description: 'Qui aut temporibus nesciunt vitae dicta repellat sit dolores pariatur. Temporibus qui illum aut.',
//     icon: LockClosedIcon,
//   },
//   {
//     name: 'Simple Queues',
//     description: 'Rerum quas incidunt deleniti quaerat suscipit mollitia. Amet repellendus ut odit dolores qui.',
//     icon: ArrowPathIcon,
//   },
//   {
//     name: 'Advanced Security',
//     description: 'Ullam laboriosam est voluptatem maxime ut mollitia commodi. Et dignissimos suscipit perspiciatis.',
//     icon: ShieldCheckIcon,
//   },
//   {
//     name: 'Powerful API',
//     description:
//       'Ab a facere voluptatem in quia corrupti veritatis aliquam. Veritatis labore quaerat ipsum quaerat id.',
//     icon: CogIcon,
//   },
//   {
//     name: 'Database Backups',
//     description: 'Quia qui et est officia cupiditate qui consectetur. Ratione similique et impedit ea ipsum et.',
//     icon: ServerIcon,
//   },
// ]
const blogPosts = [
  {
    id: 1,
    title: 'Chicken Crust with Philly Cheesesteak Pizza',
    href: '#',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    category: { name: 'Protein Pizza', href: '#' },
    imageUrl: ChickenCrustPizza,
    preview:
      '"...This meat crust protein pizza is a game-changer; it\'s like every bite is packed with flavor and the perfect crunch, making me feel good about indulging in my pizza cravings—absolutely love it..."',
    author: {
      name: 'Roel David',
      imageUrl: Roel,
      href: '#',
    },
    readingLength: '6 min',
  },
  {
    id: 2,
    title: 'Zucchini Crust Maize-Avocado Fiesta Pizza',
    href: '#',
    date: 'Mar 10, 2020',
    datetime: '2020-03-10',
    category: { name: 'Veggie Pizza', href: '#' },
    imageUrl: ZucchiniCrust,
    preview:
      '"...This veggie keto pizza is amazing! It\'s super tasty, filled with all the good veggies, and it doesn\'t even feel like you\'re on a diet... Love it!"',
    author: {
      name: 'Brenna Goyette',
      imageUrl: Brenna,
      href: '#',
    },
    readingLength: '4 min',
  },
  {
    id: 3,
    title: 'Coconut Flour Crust with Thai Chicken Pizza',
    href: '#',
    date: 'Feb 12, 2020',
    datetime: '2020-02-12',
    category: { name: 'Grain Free Pizza', href: '#' },
    imageUrl: ThaiChickenPizza,
    preview:
      '"...This Coconut Flour Crust with Thai Chicken Pizza is just awesome! It\'s got a nice, light crust and the Thai chicken topping is so flavorful. Really hits the spot without feeling heavy... Big thumbs up from me!"',
    author: {
      name: 'Daniela Metz',
      imageUrl: Daniela,
      href: '#',
    },
    readingLength: '11 min',
  },
]
const footerNavigation = {
  solutions: [
    { name: 'Marketing', href: '#' },
    { name: 'Analytics', href: '#' },
    { name: 'Commerce', href: '#' },
    { name: 'Insights', href: '#' },
  ],
  support: [
    { name: 'Pricing', href: '#' },
    { name: 'Documentation', href: '#' },
    { name: 'Guides', href: '#' },
    { name: 'API Status', href: '#' },
  ],
  company: [
    { name: 'About', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'Jobs', href: '#' },
    { name: 'Press', href: '#' },
    { name: 'Partners', href: '#' },
  ],
  legal: [
    // { name: 'Claim', href: '#' },
    { name: 'Privacy Poicy', href: 'legal/privacypolicy' },
    { name: 'Terms of Service', href: 'legal/termsofuse' },
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/EatToBurn',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/eat2burn/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'X',
      href: 'https://twitter.com/EatToBurn',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
        </svg>
      ),
    },
    {
      name: 'YouTube',
      href: 'https://www.youtube.com/@eattoburn',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M21.8,8.02c-0.2-1.29-1.29-2.2-2.59-2.2C16.39,5.5,12,5.5,12,5.5s-4.39,0-7.21,0.3 c-1.3,0-2.39,0.91-2.59,2.2C2,9.31,2,12,2,12s0,2.69,0.2,3.98c0.2,1.29,1.29,2.2,2.59,2.2C7.61,18.5,12,18.5,12,18.5 s4.39,0,7.21-0.3c1.3,0,2.39-0.91,2.59-2.2C22,14.69,22,12,22,12S22,9.31,21.8,8.02z M9.75,15.48V8.52l6.5,3.48L9.75,15.48z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Pinterest',
      href: 'https://www.pinterest.com/eattoburn/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12 2C6.48 2 2 6.48 2 12c0 4.41 2.87 8.14 6.84 9.5-.09-.81-.17-2.05 0-2.94.16-.78 1.03-4.97 1.03-4.97s-.26-.52-.26-1.3c0-1.21.7-2.11 1.58-2.11.74 0 1.1.56 1.1 1.23 0 .75-.48 1.87-.73 2.91-.21.87.45 1.58 1.32 1.58 1.58 0 2.79-1.67 2.79-4.09 0-2.14-1.54-3.63-3.74-3.63-2.55 0-4.04 1.91-4.04 3.88 0 .77.3 1.6.67 2.05.07.09.08.17.06.26-.07.28-.22.88-.25 1-.04.17-.14.2-.32.12-1.12-.49-1.82-2.01-1.82-3.24 0-2.63 1.92-5.05 5.55-5.05 2.91 0 5.17 2.07 5.17 4.85 0 2.89-1.82 5.21-4.35 5.21-.85 0-1.65-.44-1.92-1.03 0 0-.42 1.6-.52 1.99-.17.65-.62 1.46-.93 1.95 1.39.43 2.85.66 4.37.66 5.52 0 10-4.48 10-10S17.52 2 12 2z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
}

export default function KetoPizzaLanding() {


  // Check and clean up unmounted components
  const isMounted = useRef(true);
  
  useEffect(() => {
    return () => {
      // Cleanup function invoked on component unmount
      isMounted.current = false;
    };
  }, []);


  // Form Submission Functions 
  const [optinData, setOptinData] = useState({
    email: '',
    referrer: document.referrer,
    hostname: window.location.hostname,
    userAgent: window.navigator.userAgen,
    page_name: "KetoPizzaLanding"
  })

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false)
  const { email } = optinData;
  
  const navigate = useNavigate();

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    if (!email || !emailRegex.test(email)) {
      setErrorMessage('Please enter a valid email address');
      return false;
    }
  
    return true;
  };

  const handleChange = (event) => {
    setOptinData((prevState) => ({
      ...prevState,
      email: event.target.value,
    }));
  
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (validateEmail()) {
      // Valid email, proceed with form submission
      try {
        const response = await axios.post(API_URL, optinData);
        setIsLoading(true)

        if (response.status === 200){
          navigate('/ketopizzaty')
        }
      } catch (error){
        console.error('There was an error!', error);
      } finally {
        // Cleaning up state update for unmounted component after redirect
        if (isMounted.current){
          setIsLoading(false)
        }

      }

    }
  };

  return (
    <div className="bg-gray-900">
      <div className="relative overflow-hidden">
        <Popover as="header" className="relative z-10">
          <div className="bg-gradient-to-r from-indigo-950 from-5% via-blue-900 via-25% to-indigo-950 pt-6">
            <nav className="relative mx-auto flex max-w-7xl items-center justify-between px-6" aria-label="Global">
              <div className="flex flex-1 items-center">
                <div className="flex w-full items-center justify-between md:w-auto">
                  <a href="/">
                    {/* <span className="sr-only">Your Company</span> */}
                    <img
                      className="h-8 w-auto sm:h-10"
                      src={Logo}
                      alt="EatToBurn logo"
                    />
                  </a>
                  {/* <div className="-mr-2 flex items-center md:hidden">
                    <Popover.Button className="focus-ring-inset relative inline-flex items-center justify-center rounded-md bg-gray-900 p-2 text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-white">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div> */}
                </div>
                {/* <div className="hidden space-x-8 md:ml-10 md:flex">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="text-base font-medium text-white hover:text-gray-300"
                    >
                      {item.name}
                    </a>
                  ))}
                </div> */}
              </div>
              {/* <div className="hidden md:flex md:items-center md:space-x-6">
                <a href="#" className="text-base font-medium text-white hover:text-gray-300">
                  Log in
                </a>
                <a
                  href="#"
                  className="inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white hover:bg-gray-700"
                >
                  Start free trial
                </a>
              </div> */}
            </nav>
          </div>

          {/* <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel focus className="absolute inset-x-0 top-0 origin-top transform p-2 transition md:hidden">
              <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
                <div className="flex items-center justify-between px-5 pt-4">
                  <div>
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?from-color=teal&from-shade=500&to-color=cyan&to-shade=600&toShade=600"
                      alt=""
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="pb-6 pt-5">
                  <div className="space-y-1 px-2">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="mt-6 px-5">
                    <a
                      href="#"
                      className="block w-full rounded-md bg-gradient-to-r from-rose-500 to-red-600 px-4 py-3 text-center font-medium text-white shadow hover:from-rose-600 hover:to-red-700"
                    >
                      Start free trial
                    </a>
                  </div>
                  <div className="mt-6 px-5">
                    <p className="text-center text-base font-medium text-gray-500">
                      Existing customer?{' '}
                      <a href="#" className="text-gray-900 hover:underline">
                        Login
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition> */}
        </Popover>
        <main>
          <div className="bg-gray-950 pt-10 sm:pt-16 lg:overflow-hidden lg:pb-14 lg:pt-8 relative">
            <div className="absolute inset-0 z-0"
                   style={{ 
                    backgroundImage: `url(${PizzaBaground})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    opacity: 0.5
                    }}
            ></div>   
                
                <div className="mx-auto max-w-7xl lg:px-8">
                <div className="px-6 text-center mx-auto max-w-7xl lg:px-8 z-10 relative">
                    <div className="mx-auto max-w-md px-6 sm:max-w-2xl sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left">
                        <div className="lg:py-24">
                            {/* <a
                            href="#"
                            className="inline-flex items-center rounded-full bg-black p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base"
                            >
                            <span className="rounded-full bg-gradient-to-r from-teal-500 to-cyan-600 px-3 py-0.5 text-sm font-semibold leading-5 text-white">
                                We're hiring
                            </span>
                            <span className="ml-4 text-sm">Visit our careers page</span>
                            <ChevronRightIcon className="ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                            </a> */}
                            <h1 className="mt-4 text-3xl font-semibold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                            <span className="block sm:pb-5">Craving Pizza on Keto?</span>
                            {/* <span className="block bg-gradient-to-r from-slate-50 to-gray-50 bg-clip-text pb-3 text-transparent">
                            Ditch the Carbs, 
                            </span>
                            <span className='block bg-gradient-to-r from-slate-50 to-gray-50 bg-clip-text pb-3 text-transparent sm:pb-5'> 
                            Not the Flavor!</span> */}
                            </h1>
                            <h3 className="mt-4 text-4xl font-500 tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-4xl">
                            <span className="block bg-gradient-to-r from-slate-50 to-gray-50 bg-clip-text pb-3 text-transparent">
                            Over 50 Gourmet Pizzas You Can Whip Up in 30 Minutes or Less!
                            </span>
                            </h3>
                            <h4 className="mt-4 text-xl text-gray-100 sm:text-3xl lg:text-3xl xl:text-3xl">
                            Ditch the Carbs, Not the Flavor!
                            </h4>
                            <div className="mt-10 sm:mt-10">
                            <form 
                              onSubmit={handleSubmit} 
                              className="sm:mx-auto sm:max-w-xl lg:mx-0">
                                <div >
                                <div className="min-w-0 flex-1">
                                    <label htmlFor="email" className="sr-only">
                                    Email address
                                    </label>
                                    <input
                                    id="email"
                                    type="email"
                                    value={email}
                                    onChange={handleChange}
                                    placeholder="Enter your email"
                                    className="text-xl block w-full rounded-md border-0 px-4 py-3 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900"
                                    />
                                </div>
                                {errorMessage && <p className="text-white">{errorMessage}</p>}
                                <div className="mt-3 pb-8">
                                    <button
                                    type="submit"
                                    className="text-2xl block w-full rounded-md bg-gradient-to-r from-rose-500 to-red-600 px-4 py-3 font-medium text-white shadow hover:from-rose-600 hover:to-red-700 focus:outline-none focus:ring-2 focus:ring-rose-400 focus:ring-offset-2 focus:ring-offset-gray-900"
                                    >
                                      {isLoading ? <RotatingLines 
                                          strokeColor="white"
                                          strokeWidth="3"
                                          animationDuration="1.75"
                                          width="36"
                                          visible={true}/>            
                                      : 'Yes, I Want This!'}
                                    </button>
                                </div>
                                </div>
                                {/* <p className="mt-3 text-sm text-gray-300 sm:mt-4">
                                Start your free 14-day trial, no credit card necessary. By providing your email, you agree to
                                our{' '}
                                <a href="#" className="font-medium text-white">
                                    terms of service
                                </a>
                                .
                                </p> */}
                            </form>
                            </div>
                        </div>
                    </div>
                    {/* <div className="-mb-16 mt-12 sm:-mb-48 lg:relative lg:m-0">
                    <div className="mx-auto max-w-md px-6 sm:max-w-2xl lg:max-w-none lg:px-0">
                        {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}
                    {/*  <img
                        className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                        src={PizzaBaground}
                        //   src="https://tailwindui.com/img/component-images/cloud-illustration-teal-cyan.svg"
                        alt=""
                        />
                    </div>
                    </div> */}
                </div>
                
                </div>

          </div>

          {/* Feature section with screenshot */}
          {/* <div className="relative bg-gray-50 pt-16 sm:pt-24 lg:pt-32">
            <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
              <div>
                <h2 className="text-lg font-semibold text-cyan-600">Serverless</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  No server? No problem.
                </p>
                <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
                  Phasellus lorem quam molestie id quisque diam aenean nulla in. Accumsan in quis quis nunc, ullamcorper
                  malesuada. Eleifend condimentum id viverra nulla.
                </p>
              </div>
              <div className="-mb-10 mt-12 sm:-mb-24 lg:-mb-80">
                <img
                  className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
                  src="https://tailwindui.com/img/component-images/green-project-app-screenshot.jpg"
                  alt=""
                />
              </div>
            </div>
          </div> */}

          {/* Feature section with grid */}
          {/* <div className="relative bg-white py-16 sm:py-24 lg:py-32">
            <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
              <h2 className="text-lg font-semibold text-cyan-600">Deploy faster</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Everything you need to deploy your app
              </p>
              <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
                Phasellus lorem quam molestie id quisque diam aenean nulla in. Accumsan in quis quis nunc, ullamcorper
                malesuada. Eleifend condimentum id viverra nulla.
              </p>
              <div className="mt-12">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  {features.map((feature) => (
                    <div key={feature.name} className="pt-6">
                      <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                        <div className="-mt-6">
                          <div>
                            <span className="inline-flex items-center justify-center rounded-md bg-gradient-to-r from-teal-500 to-cyan-600 p-3 shadow-lg">
                              <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                            </span>
                          </div>
                          <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">{feature.name}</h3>
                          <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div> */}

          {/* Testimonial section */}
          <div className="bg-gradient-to-r from-blue-950 via-rose-900 to-blue-950 pb-16 lg:relative lg:z-10 lg:pb-0">
            <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-8 lg:px-8">
              <div className="relative lg:-my-8">
                <div aria-hidden="true" className="absolute inset-x-0 top-0 h-1/2 bg-gradient-to-r from-blue-950 via-rose-900 to-blue-950 lg:hidden" />
                <div className="mx-auto max-w-md px-6 sm:max-w-3xl lg:h-full lg:p-0">
                  <div className="shadow-2xl border border-gray-500 aspect-h-6 aspect-w-10 overflow-hidden rounded-xl sm:aspect-h-7 sm:aspect-w-16 lg:aspect-none lg:h-full">
                    <img
                      className="object-cover lg:h-full lg:w-full"
                      src={SpecialPizza}
                      // src={PizzaSlices}
                      alt="Keto Pizza Slices"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-6 lg:col-span-2 lg:m-0 lg:pl-8">
                <div className="mx-auto max-w-md px-6 sm:max-w-2xl lg:max-w-none lg:px-0 lg:py-20">
                  <blockquote>
                    <div>
                      {/* <svg
                        className="h-12 w-12 text-white opacity-25"
                        fill="currentColor"
                        viewBox="0 0 32 32"
                        aria-hidden="true"
                      >
                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                      </svg> */}
                      <p className="mt-6 text-2xl font-medium text-white">
                        This treasure trove of nearly 50 keto-friendly pizzas is a testament to the fact that staying low-carb doesn't mean sacrificing flavor or fun. 
                      </p>
                      <p className="mt-6 text-2xl font-medium text-white">
                        Prepare to be amazed by the variety and depth of pizza options at your fingertips, ensuring your keto journey is as delicious as it is nutritious.  
                      </p>  
                    </div>
                    {/* <footer className="mt-6">
                      <p className="text-base font-medium text-white">Judith Black</p>
                      <p className="text-base font-medium text-cyan-100">CEO at PureInsights</p>
                    </footer> */}
                  </blockquote>
                </div>
              </div>
            </div>
          </div>

          {/* Blog section */}
          <div className="relative bg-gradient-to-r from-indigo-950 from-5% via-blue-900 via-25% to-indigo-950 py-16 sm:py-24 lg:py-32">
            <div className="relative">
              <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
                <h2 className="text-3xl font-semibold text-rose-500">Join the Tribe</h2>
                <p className="mt-2 text-3xl font-semibold tracking-tight text-gray-50 sm:text-4xl">Experience the Bliss of Our Pizzas</p>
                <p className="mx-auto mt-5 max-w-prose text-xl text-gray-100">
                  This is how simple ingredients blend in harmony to create extraordinary tastes and bring people together over the universal love for delicious pizza.
                </p>
              </div>
              <div className="mx-auto mt-12 grid max-w-md gap-8 px-6 sm:max-w-lg lg:max-w-7xl lg:grid-cols-3 lg:px-8">
                {blogPosts.map((post) => (
                  <div key={post.id} className="flex flex-col overflow-hidden rounded-lg shadow-2xl border border-gray-500">
                    <div className="flex-shrink-0">
                      <img className="h-48 w-full object-cover" src={post.imageUrl} alt="" />
                    </div>
                    <div className="flex flex-1 flex-col justify-between bg-white p-6">
                      <div className="flex-1">
                        <p className="text-2xl font-medium text-rose-600">
                          <a href={post.category.href} className="hover:underline">
                            {post.category.name}
                          </a>
                        </p>
                        <a href={post.href} className="mt-2 block">
                          <p className="text-2xl font-semibold text-gray-900">{post.title}</p>
                          <p className="text-2xl mt-3 text-gray-500">{post.preview}</p>
                        </a>
                      </div>
                      <div className="mt-6 flex items-center">
                        <div className="flex-shrink-0">
                          <a href={post.author.href}>
                            <img className="h-20 w-20 rounded-full" src={post.author.imageUrl} alt={post.author.name} />
                          </a>
                        </div>
                        <div className="ml-3">
                          <p className="text-xl font-medium text-gray-900">
                            <a href={post.author.href} className="hover:underline">
                              {post.author.name}
                            </a>
                          </p>
                          {/* <div className="flex space-x-1 text-sm text-gray-500">
                            <time dateTime={post.datetime}>{post.date}</time>
                            <span aria-hidden="true">&middot;</span>
                            <span>{post.readingLength} read</span>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* CTA Section */}
          <div className="relative bg-gradient-to-r from-blue-950 via-rose-800 via-50% to-blue-950">
            <div className="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
              <img
                className="h-full w-full object-cover"
                src={PizzaPies}
                alt="Keto Pizza Pies"
              />
              <div
                aria-hidden="true"
                className="absolute inset-0 bg-gradient-to-r from-blue-300 from-70% to-rose-700 mix-blend-multiply"
              />
            </div>
            <div className="relative mx-auto max-w-md px-6 py-12 sm:max-w-7xl sm:py-20 md:py-28 lg:px-8 lg:py-32">
              <div className="md:ml-auto md:w-1/2 md:pl-10">
                <h2 className="text-2xl font-semibold text-gray-300">FREE EBOOK!</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Bye-bye Gravings!</p>
                <p className="mt-3 text-2xl text-gray-300">
                  Nearly 50 Quick Keto Pizza Recipes to Savor - Delicious, Gourmet, and Guilt-Free!
                </p>
                <div className="mt-8">
                  <form 
                    onSubmit={handleSubmit} 
                    className="sm:mx-auto sm:max-w-xl lg:mx-0">
                      <div >
                        <div className="min-w-0 flex-1">
                            <label htmlFor="email" className="sr-only">
                            Email address
                            </label>
                            <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={handleChange}
                            placeholder="Enter your email"
                            className="text-xl block w-full rounded-md border-0 px-4 py-3 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900"
                            />
                        </div>
                        {errorMessage && <p className="text-white">{errorMessage}</p>}
                        <div className="mt-3 pb-8">
                            <button
                            type="submit"
                            className="text-2xl block w-full rounded-md bg-gradient-to-r from-rose-500 to-red-600 px-4 py-3 font-medium text-white shadow hover:from-rose-600 hover:to-red-700 focus:outline-none focus:ring-2 focus:ring-rose-400 focus:ring-offset-2 focus:ring-offset-gray-900"
                            >
                              {isLoading ? <RotatingLines 
                                  strokeColor="white"
                                  strokeWidth="3"
                                  animationDuration="1.75"
                                  width="36"
                                  visible={true}/>            
                              : 'Yes, I Want This!'}
                            
                            </button>
                        </div>
                      </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
        <footer className="bg-gradient-to-r from-indigo-950 from-5% via-blue-900 via-25% to-indigo-950" aria-labelledby="footer-heading">
          <h2 id="footer-heading" className="sr-only">
            Footer
          </h2>
          <div className="mx-auto max-w-md px-6 pt-12 sm:max-w-7xl lg:px-8 lg:pt-16">
            <div className="xl:grid xl:grid-cols-3 xl:gap-8">
              <div className="space-y-8 xl:col-span-1">
                <img
                  className="h-10"
                  src={Logo}
                  alt="EatToBurn Logo"
                />
                {/* <p className="text-base text-gray-400">
                  Follow us on social.
                </p> */}
                <div className="flex space-x-6">
                  {footerNavigation.social.map((item) => (
                    <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                      <span className="sr-only">{item.name}</span>
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </a>
                  ))}
                </div>
              </div>
              <div className="mt-12 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
                <div className="md:grid md:grid-cols-2 md:gap-8">
                <div className="mt-12 md:mt-0">
                    {/* <h3 className="text-base font-medium text-gray-400">Legal</h3> */}
                    <ul role="list" className="mt-4 space-y-4">
                      {footerNavigation.legal.map((item) => (
                        <li key={item.name}>
                          <a href={item.href} className="text-base text-gray-400 hover:text-gray-500">
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {/* <div>
                    <h3 className="text-base font-medium text-gray-400">Solutions</h3>
                    <ul role="list" className="mt-4 space-y-4">
                      {footerNavigation.solutions.map((item) => (
                        <li key={item.name}>
                          <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div> */}
                  {/* <div className="mt-12 md:mt-0">
                    <h3 className="text-base font-medium text-gray-400">Support</h3>
                    <ul role="list" className="mt-4 space-y-4">
                      {footerNavigation.support.map((item) => (
                        <li key={item.name}>
                          <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div> */}
                </div>
                <div className="md:grid md:grid-cols-2 md:gap-8">
                  {/* <div>
                    <h3 className="text-base font-medium text-gray-400">Company</h3>
                    <ul role="list" className="mt-4 space-y-4">
                      {footerNavigation.company.map((item) => (
                        <li key={item.name}>
                          <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div> */}
                  {/* <div className="mt-12 md:mt-0">
                    <h3 className="text-base font-medium text-gray-400">Legal</h3>
                    <ul role="list" className="mt-4 space-y-4">
                      {footerNavigation.legal.map((item) => (
                        <li key={item.name}>
                          <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="mt-12 border-t border-gray-200 py-8">
              <p className="text-base text-gray-400 xl:text-center">
                &copy; {new Date().getFullYear()} EatToBurn.com. All rights reserved.
              </p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}
