import { HiChevronDoubleRight } from "react-icons/hi";
import ketoWeightLossFemaleBefore from '../../assets/img/post_quiz_sales_page/female_before.png';
import ketoWeightLossFemaleAfter from '../../assets/img/post_quiz_sales_page/female_after.png';
import ketoWeightLossMaleBefore from '../../assets/img/post_quiz_sales_page/male_before.png';
import ketoWeightLossMaleAfter from '../../assets/img/post_quiz_sales_page/male_after.png';

// Function to calculate BMI values
const calculateBMI = (weight, height, isMetric) => {
  let bmi;
  if (isMetric) {
    // BMI = weight(kg) / (height(m) * height(m))
    bmi = weight / ((height * 0.01) * (height * 0.01));
  } else {
    // BMI = (weight (lbs) / height (in)²) x 703
    bmi = (weight / (height * height)) * 703;
  }

  return Math.round(bmi * 10) / 10; // Round to one decimal place
}

// Functions to display BMI status
const getHealthRiskSeverity = (bmi) => {
  if (bmi < 18.5) return { category: "Underweight", risk: "Increased" };
  if (bmi >= 18.5 && bmi < 25) return { category: "Healthy weight", risk: "Lowest" };
  if (bmi >= 25 && bmi < 30) return { category: "Overweight", risk: "Increased" };
  if (bmi >= 30 && bmi < 35) return { category: "Class 1 Obesity", risk: "High" };
  if (bmi >= 35 && bmi < 40) return { category: "Class 2 Obesity", risk: "Very high" };
  if (bmi >= 40) return { category: "Class 3 Obesity", risk: "Extremely high" };
  return { category: "Unknown", risk: "Unknown" };
};

const getHealthRiskColor = (risk) => {
  switch (risk) {
    case "Lowest":
      return "text-green-600";
    case "Increased":
      return "text-yellow-600";
    case "High":
      return "text-orange-600";
    case "Very high":
    case "Extremely high":
      return "text-red-600";
    default:
      return "text-gray-600";
  }
};

const getHealthRiskBgColor = (risk) => {
  switch (risk) {
    case "Lowest":
      return "bg-green-600";
    case "Increased":
      return "bg-yellow-600";
    case "High":
      return "bg-orange-600";
    case "Very high":
    case "Extremely high":
      return "bg-red-600";
    default:
      return "bg-gray-600";
  }
};

const SeverityIndicator = ({ risk }) => {
  const riskLevels = ["Lowest", "Increased", "High", "Very high", "Extremely high"];
  const riskIndex = riskLevels.indexOf(risk);

  return (
    <div className="flex justify-center mt-1">
      {riskLevels.map((level, index) => (
        <div 
          key={level}
          className={`w-2 h-2 rounded-full mx-1 ${
            index <= riskIndex ? getHealthRiskBgColor(risk) : 'bg-gray-300'
          }`}
        />
      ))}
    </div>
  );
};

// _________________ //
// const getBMIStatus = (bmi) => {
//   if (bmi < 18.5) return "Underweight";
//   if (bmi >= 18.5 && bmi < 25) return "Healthy Weight";
//   if (bmi >= 25 && bmi < 30) return "Overweight";
//   if (bmi >= 30) return "Obesity";
//   return "Unknown";
// };

// const getBMIStatusColor = (status) => {
//   switch (status) {
//     case "Underweight":
//       return "text-blue-600";
//     case "Healthy Weight":
//       return "text-green-600";
//     case "Overweight":
//       return "text-yellow-600";
//     case "Obesity":
//       return "text-red-600";
//     default:
//       return "text-gray-600";
//   }
// };

// Function to calculate weight loss percentage
// const calculateWeightLossPercentage = (currentWeight, goalWeight) => {
//   if (currentWeight <= goalWeight) return 0;
//   const weightLoss = currentWeight - goalWeight;
//   const percentage = (weightLoss / currentWeight) * 100;
//   return Math.round(percentage * 10) / 10; // Round to one decimal place
// };

export default function HeroSection({quizData, surveyData}) {

  const isMetric = surveyData.metric;

  // Calculate BMI current and future passing parameters dynamicly 
  const currentBMI = calculateBMI(
    isMetric ? surveyData.weightKgCurr : surveyData.weightLbsCurr,
    isMetric ? surveyData.heightCm : (surveyData.heightFeet * 12) + surveyData.heightInches,
    isMetric
  )

  const goalBMI = calculateBMI(
    isMetric ? surveyData.weightKgTarget : surveyData.weightLbsTarget,
    isMetric ? surveyData.heightCm : (surveyData.heightFeet * 12) + surveyData.heightInches,
    isMetric
  )

  // const currentBMIStatus = getBMIStatus(currentBMI);
  // const goalBMIStatus = getBMIStatus(goalBMI);

  const currentHealthRisk = getHealthRiskSeverity(currentBMI);
  const goalHealthRisk = getHealthRiskSeverity(goalBMI);

  // const weightLossPercentage = calculateWeightLossPercentage(
  //   isMetric ? surveyData.weightKgCurr : surveyData.weightLbsCurr, 
  //   isMetric ? surveyData.weightKgTarget : surveyData.weightLbsTarget
  // );

  return (
    <div className="relative overflow-hidden bg-gray-50">
      <div aria-hidden="true" className="hidden sm:absolute sm:inset-y-0 sm:block sm:h-full sm:w-full">
        <div className="relative mx-auto h-full max-w-7xl">
          <svg
            fill="none"
            width={404}
            height={784}
            viewBox="0 0 404 784"
            className="absolute right-full translate-x-1/4 translate-y-1/4 transform lg:translate-x-1/2"
          >
            <defs>
              <pattern
                x={0}
                y={0}
                id="4522f7d5-8e8c-43ee-89bd-ad34cbfb07fa"
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} fill="currentColor" width={4} height={4} className="text-gray-200" />
              </pattern>
            </defs>
            <rect fill="url(#4522f7d5-8e8c-43ee-89bd-ad34cbfb07fa)" width={404} height={784} />
          </svg>
          <svg
            fill="none"
            width={404}
            height={784}
            viewBox="0 0 404 784"
            className="absolute left-full -translate-x-1/4 -translate-y-3/4 transform md:-translate-y-1/2 lg:-translate-x-1/2"
          >
            <defs>
              <pattern
                x={0}
                y={0}
                id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} fill="currentColor" width={4} height={4} className="text-gray-200" />
              </pattern>
            </defs>
            <rect fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)" width={404} height={784} />
          </svg>
        </div>
      </div>

      <div className="relative pb-10 pt-6 sm:pb-8">
        <main className="mx-auto mt-16 max-w-7xl px-4 sm:mt-24">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block xl:inline">You Personalized Meal Plan </span>{' '}
              <span className="block text-orange-600 xl:inline">Is Ready</span>
            </h1>
            {/* <p className="mx-auto mt-3 max-w-md text-base text-gray-500 sm:text-lg md:mt-5 md:max-w-3xl md:text-xl">
              Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet
              fugiat veniam occaecat fugiat aliqua.
            </p> */}
            <div className="mt-10 mx-auto max-w-3xl">
              <div className="bg-white shadow rounded-lg overflow-hidden">
                <div className="p-6">
                  {/* Labels */}
                  <div className="flex justify-between mb-4">
                    <p className="text-sm font-semibold text-gray-500">Now</p>
                    <p className="text-sm font-semibold text-orange-600">Your Goal</p>
                  </div>

                  {/* Images */}
                  <div className="flex justify-between items-center mb-6">
                    <img 
                      // src={ketoWeightLossFemaleBefore} 
                      src={surveyData.gender === "female" ? ketoWeightLossFemaleBefore : ketoWeightLossMaleBefore} 
                      alt="Current body" 
                      className="w-1/2"
                    />
                    <div className="flex flex-col items-center">
                      <HiChevronDoubleRight className="h-10 w-10 flex-none text-orange-700"></HiChevronDoubleRight>
                      {/* <svg className="w-6 h-6 text-orange-600" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                      <>{weightLossPercentage} % <ArrowDownIcon></ArrowDownIcon></> */}
                      
                    </div>
                    <img 
                      // src={ketoWeightLossFemaleAfter} 
                      src={surveyData.gender === "female" ? ketoWeightLossFemaleAfter : ketoWeightLossMaleAfter}
                      alt="Goal body" 
                      className="w-1/2"
                    />
                  </div>

                  {/* Condition Info */}
                  <div className="grid grid-cols-2 gap-4 text-center">
                    <div>
                      {/* <p className="text-lg font-semibold text-gray-500">Body Mass Index </p> */}
                      <p className={`text-lg font-semibold ${getHealthRiskColor(currentHealthRisk.risk)}`}> BMI {currentBMI}</p>
                      <p className={`text-lg font-semibold ${getHealthRiskColor(currentHealthRisk.risk)}`}>
                        {currentHealthRisk.category}
                      </p>

                      {/* <p className="text-sm text-gray-500">Body fat</p>
                      <p className={`font-semibold ${getBMIStatusColor(currentBMIStatus)}`}>{currentBMIStatus}</p> */}
                      <p className="text-lg font-semibold text-gray-500 mt-2">Health Risk</p>
                    {/* List risk of descese on ome side and risk reduction % and body weight % reduction on oposite side */}
                      {/* <p className="font-semibold">Beginner</p> */}
                      <p className={`text-lg font-semibold ${getHealthRiskColor(currentHealthRisk.risk)}`}>
                        {currentHealthRisk.risk}
                      </p>
                      <SeverityIndicator risk={currentHealthRisk.risk} />
                      
                      {/* <div className="flex justify-center mt-1">
                        <div className="w-2 h-2 rounded-full bg-orange-600 mx-1"></div>
                        <div className="w-2 h-2 rounded-full bg-gray-300 mx-1"></div>
                        <div className="w-2 h-2 rounded-full bg-gray-300 mx-1"></div>
                        <div className="w-2 h-2 rounded-full bg-gray-300 mx-1"></div>
                        <div className="w-2 h-2 rounded-full bg-gray-300 mx-1"></div>
                      </div> */}
                    </div>
                    <div>
                      {/* <p className="text-lg font-semibold text-gray-500">Body Mass Index (BMI)</p> */}
                      <p className={`text-lg font-semibold ${getHealthRiskColor(goalHealthRisk.risk)}`}>BMI {goalBMI}</p>
                      <p className={`text-lg font-semibold ${getHealthRiskColor(goalHealthRisk.risk)}`}>
                        {goalHealthRisk.category}
                      </p>
                      {/* <p className="text-sm text-gray-500">Body fat</p>
                      <p className={`font-semibold ${getBMIStatusColor(currentBMIStatus)}`}>{goalBMIStatus}</p> */}
                      <p className="text-lg font-semibold text-gray-500 mt-2">Health Risk</p>
                      {/* <p className="font-semibold">Advanced</p> */}
                      <p className={`text-lg font-semibold ${getHealthRiskColor(goalHealthRisk.risk)}`}>
                        {goalHealthRisk.risk}
                      </p>
                      <SeverityIndicator risk={goalHealthRisk.risk} />
                      {/* <div className="flex justify-center mt-1">
                        <div className="w-2 h-2 rounded-full bg-orange-600 mx-1"></div>
                        <div className="w-2 h-2 rounded-full bg-orange-600 mx-1"></div>
                        <div className="w-2 h-2 rounded-full bg-orange-600 mx-1"></div>
                        <div className="w-2 h-2 rounded-full bg-orange-600 mx-1"></div>
                        <div className="w-2 h-2 rounded-full bg-orange-600 mx-1"></div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="mx-auto mt-5 max-w-md sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <a
                  href="#"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 md:px-10 md:py-4 md:text-lg"
                >
                  Get started
                </a>
              </div>
              <div className="mt-3 rounded-md shadow sm:ml-3 sm:mt-0">
                <a
                  href="#"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-indigo-600 hover:bg-gray-50 md:px-10 md:py-4 md:text-lg"
                >
                  Live demo
                </a>
              </div>
            </div> */}
          </div>
        </main>
      </div>
    </div>
  )
}
