import { useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { newMealplanRecord } from '../../../features/mealplan/mealplanSlice';
import { newQuizRecord } from '../../../features/quiz/quizSlice';

import { XlviLoader } from "react-awesome-loaders";
import TextTransition, { presets } from 'react-text-transition';

const Results = (props) => {

  // console.log("Props in RESULTS ", props)
  const [index, setIndex] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const TEXTS = [
      'Preparing your results',
      'Reviewing quiz answers', 
      'Analizing your goals', 
      'Calculating your BMR',
      'Computing ideal caloric intake',
      'Creating your personal plan'
  ];

  useEffect(() => {
      const intervalId = setInterval(() => {
        // () => setIndex((index) => index + 1),
        // 3000, // every 3 seconds

        // create new function to set index
        setIndex((prevIndex) => {
          // If the next index is the last one in the text array
          if (prevIndex === TEXTS.length - 1){
            setTimeout(() => {
              // Use navigate function to redirect to internal router pages
              // navigate('/myplan', {quizResults: {quiz: props.quizData, survey: props.surveyData}})
              navigate('/myplan')
              // Use this line to navigate to an outside/3rd party url (This shows Unbounce page)
              // window.location.href = 'https://hello.eattoburn.com/myplan/';
            }, 2000); // Wait 3 seconds
          }
          return prevIndex + 1;
        })
      }, 3000);

      return () => clearTimeout(intervalId);
    });

  useEffect(() => {
    dispatch(newMealplanRecord(props.surveyData));
    dispatch(newQuizRecord(props.quizData));
  },[dispatch, props.quizData, props.surveyData])

  return (
    <>
      <div className="flex min-w-full min-h-screen justify-center">
          <div className="mt-40 mr-10 ">
              <div className="text-base pl-6 pb-10 font-semibold text-indigo-600">
                <TextTransition springConfig={presets.stiff}>{TEXTS[index % TEXTS.length]}</TextTransition>
              </div>    
              <XlviLoader
                  boxColors={["#36EEE0", "#F652A0", "#F8D210"]}
                  desktopSize={"128px"}
                  mobileSize={"100px"}
              />
          </div>
      </div>
    </>
  )
}

export default Results