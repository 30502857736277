import ketoWeightLossInches from '../../../assets/img/lose_inches.png';
import ketoWeightLossCm from '../../../assets/img/lose_cm.png';
import ketoWeightLossMale from '../../../assets/img/male_lose.png';

export default function WeightLossChartStats(props) {
    // console.log(props)
    // Calculate BMI 
    let BMI 
    if (props.metric){
        // BMI = weight(kg) / (height(m) * height(m))
        BMI = props.weightKgCurr / ((props.heightCm*0.01) * (props.heightCm*0.01));
    } else {
        // BMI = (weight (lbs) / height (in)²) x 703
        var height = (props.heightFeet * 12) + props.heightInches;
        BMI = props.weightLbsCurr / (height * height) * 703
    }

    return (
      <div>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
        {/* +++++++++++++++++++++++++ */}
        <div className="bg-white shadow overflow-hidden sm:rounded-lg h-min px-4 py-2 ">
            <dl>
            <div className="bg-zinc-200 px-4 py-5 border-b sm:grid sm:grid-cols-3 sm:gap-4 ">
                <dt className="text-2xl font-medium text-gray-700">
                Overweight
                </dt>
                <dd className="mt-1 text-3xl font-bold text-end text-rose-700 sm:mt-0 sm:col-span-2">
                    {BMI >= 25 ? 
                        <div>
                            <span>{BMI.toFixed(1)}</span>
                            <span className='text-lg font-thin'>BMI</span>
                        </div>  : ''} 
                
                </dd>
            </div>

            <div className="bg-white px-4 py-5 border-b sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-2xl font-medium text-gray-500">
                Normal 
                </dt>
                <dd className="mt-1 text-3xl font-bold text-end text-gray-900 sm:mt-0 sm:col-span-2">
                    {BMI >= 18.5 && BMI <= 24.9 ? 
                        <div>
                            <span>{BMI.toFixed(1)}</span>
                            <span className='text-lg font-thin'>BMI</span>
                        </div> : ''} 
                </dd>
            </div>

            <div className="bg-white px-4 py-5 border-b sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-2xl font-medium text-gray-500">
                Underweight
                </dt>
                <dd className="mt-1 text-3xl font-bold text-end text-gray-900 sm:mt-0 sm:col-span-2">
                    {BMI < 18.5 ?                         
                        <div>
                            <span>{BMI.toFixed(1)}</span>
                            <span className='text-lg font-thin'>BMI</span>
                        </div> : ''} 
                </dd>
            </div>
            <div className='bg-gray-50 shadow-md sm:rounded-lg'>
                <h1 className='mt-4 text-center text-5xl'>Lose up to </h1>
                <h1 className="m-6 text-center text-6xl font-bold tracking-tight text-red-600 sm:text-6xl">
                    {props.metric ? `-1.25 kg` : `-2.75 lbs`} 
                </h1>
                <h3 className='m-4 text-center text-3xl'>After first week</h3>

            </div>
            <div className="mt-1 border-t border-gray-500 p-1">
                <p className="text-xs text-gray-400">
                This is not a customized estimate and results may differ. 
                Estimation made base on a study of <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2716748/" target="_blank" rel="noopener">long-term effects of a keto</a> 
                diet in obese patients published in fall 2004.
                </p>
            </div>
            </dl>
        </div>

        {/* +++++++++++++++++++++++++ */}
             {/* ==================================== */}
            <div className="overflow-hidden rounded-lg bg-white px-4 py-2 shadow sm:p-6">
              <dt className="truncate text-2xl text-center font-semibold text-gray-900">
                Estimated body changes
             </dt>
              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                <img 
                    src = {props.gender === "female" ? (
                        props.metric ? ketoWeightLossCm : ketoWeightLossInches) : ketoWeightLossMale }
                    // src={props.metric ? ketoWeightLossCm : ketoWeightLossInches} 
                    alt="Keto Weight loss results"/>
             </dd>
             <div className="mt-1 border-t border-gray-500 p-1">
                <p className="text-xs text-gray-400">
                This is not a customized graph and results may differ. 
                Estimation made on Keto Diet, UAB calculation based in 
                part on study <a href="https://www.mdpi.com/2072-6643/12/6/1854" 
                target="_blank" rel="noopener">Michalczyk</a> et al. published 22 June 2020
                </p>
            </div>
            </div>
        </dl>
      </div>
    )
  }  