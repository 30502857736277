import React, { useState } from 'react';
import { QuizProgress, QuizQuestion, QuizStatement } from "../../../components";
import {quizContent} from '../../../assets/data/quizContent';

const QuizPartOne = (props) => {
    // Creates data array to track answers
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answersTracker, setAnswersTracker] = useState([]);

    const handleAddToTracker = (answer) => {
        
        // Grab optinId if its available in localStorage
        let optinId;
        if(localStorage.getItem('optin')){
            // grab optinId from local storage and trim the quotes with .slice()
            optinId = localStorage.getItem('optin').slice(1,-1);
        } else {
            optinId = '';
        }
        // console.log(optinId)
        const answerObject = {
          optinId,  
          question: quizContent[currentQuestion].type,
          answer: answer,
        };
      
        const newArray = [...answersTracker, answerObject];
        // console.log(newArray)
        setCurrentQuestion(currentQuestion + 1);
        setAnswersTracker(newArray);
        // Update state in the parent Quiz conpoment
        props.updateQuizState({ quizData: newArray })
      };
    
    return (
        <div className='mx-auto max-w-3xl bg-gray-100 shadow sm:rounded-lg p-4 sm:px-6 lg:px-8'>
            <QuizProgress step={currentQuestion}/>
            <QuizStatement statement={quizContent[currentQuestion]?.statement}/>
            <QuizQuestion question={quizContent[currentQuestion]?.question}/>
                <ul className="space-y-3">
                    { quizContent[currentQuestion]?.answers.map(
                        (answer)=>{
                            return(
                                <li key={answer.type}
                                    className="ring-2 ring-sky-100 bg-white ring-opacity-60 ring-offset-2
                                                ring-offset-sky-300 relative flex cursor-pointer rounded-lg 
                                                px-5 py-4 shadow-md hover:bg-sky-100
                                                hover:ring-offset-sky-600 hover:ring-offset-4" 
                                    onClick={() => handleAddToTracker(answer)}>
                                    <div className="flex w-full items-center justify-between">
                                        <div className="flex items-center">
                                            <div className="text-lg">
                                                {answer.content}
                                            </div>            
                                        </div>      
                                    </div>
                                </li>
                            )
                        }
                    )}
                </ul>
        </div>
    )
}

export default QuizPartOne