import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import {SurveyProgress, SurveyTitle} from '../../../components';


export default function Age(props){

    const [ errorMessage, setErrorMesage ] = useState();
    const [ disabled, setEnabled ] = useState(true);

    const handleAgeChange = (event) =>{
        const value = event.target.value;
        const fieldFlag = props.validateInput(value, 18, 100)
        props.updateFormValue(value);
        setEnabled(fieldFlag)
        setErrorMesage(fieldFlag)
    }

    const handleNextStep = (stepValue)=>{
        props.nextStep(stepValue)
    }

    return(
        <>
        <div>
            <SurveyTitle props={props}/>
            <SurveyProgress step={props.step}/>
            <label htmlFor="number" className="block text-lg mt-5 font-medium leading-6 text-gray-900">
            What is your age?
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
            <input
                type="number"
                name="age"
                id="age"
                min="18"
                max="99"
                onChange={handleAgeChange}
                style={{fontSize: '1.25rem'}}
                className={errorMessage ? (
                        "block w-full text-center h-12 rounded-md border-0 py-1.5 ring-1 ring-inset placehoder:text-xl focus:ring-2 sm:text-xl sm:leading-6 pr-10 text-red-900   ring-red-300 placeholder:text-red-300 focus:ring-inset focus:ring-red-500"
                    ) : (
                        "block w-full text-center h-12 rounded-md border-0 py-1.5 ring-1 ring-inset placeholder:text-xl focus:ring-2 sm:text-xl sm:leading-6 text-gray-900  first-letter:shadow-sm  ring-offset-sky-300 placeholder:text-gray-400 focus:ring-inset-4 focus:ring-offset-sky-600"
                    )}
                placeholder="How old are you?"
                aria-invalid="true"
                aria-describedby="age-error"
            />
            {errorMessage && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <ExclamationCircleIcon className="h-8 w-8 text-red-500" aria-hidden="true" />
                        </div>)}

            </div>
            <div className='mt-2'>
            {errorMessage && (
                <p className=" text-lg text-red-600" id="age-error">
                Age can't be under 18 or over 99.
                </p>)}
            </div>
    
        </div>
        <div>
            <button
                type="submit"
                disabled={disabled}
                onClick={()=>handleNextStep(props.step)}
                className="flex w-full h-12 justify-center rounded-md bg-orange-600 px-3 mt-12 py-2 text-lg font-semibold text-white shadow-sm hover:bg-orange-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-800"
                >
                Next
            </button>
        </div>
        </>        
    )

};

