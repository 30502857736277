import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
//import { earningData } from '../assets/dummy';
import { mealplanRecord, } from '../features/mealplan/mealplanSlice';
import { loadRecipes } from '../features/recipes/recipesSlice';
import { Spinner, MealplanDataBar, MealplanLayout } from '../components';

const Mealplan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const {recipes, isSuccess } = useSelector((state) => state.recipes);

  useEffect(() => {
    if(!user){
      navigate('/login');
    }

    const fetchData = async () => {
      const mealplanData = await dispatch(mealplanRecord(user.buyerId));
      const data = mealplanData.payload;
      dispatch(loadRecipes({targetCalories: data.lowerCalories}))
    }

    fetchData();
  },[user, navigate, recipes, dispatch])

  return (
    <div className='mt-10'>
      <MealplanDataBar />
      {isSuccess ? 
        <MealplanLayout /> : 
        <div className="flex items-center justify-center mx-auto mt-12 max-w-lg lg:max-w-none">
          <Spinner />
        </div>
        
      }              
    </div>
  )
}

export default Mealplan