import { Sidebar } from '../components';
import {useStateContext} from '../contexts/ContextProvider';
// const { activeMenu } = useStateContext();

const SidebarLayout = () => {

    const { activeMenu } = useStateContext();
    return (
        <>
            {activeMenu ? (
                <div className='w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white'>
                    <Sidebar />
                </div>
            ) : (
                <div className='w-0 dark:bg-secondary-dark-bg'>
                <Sidebar />
                </div>
            )}
        </>
    )
}

export default SidebarLayout;