import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { logout, reset} from '../features/auth/authSlice';

import { AiOutlineMenu } from 'react-icons/ai';
import { FiLogOut } from 'react-icons/fi';
// import { BsChatLeft } from 'react-icons/bs';
import { RiStarSmileLine } from 'react-icons/ri';
// import { MdKeyboardArrowDown } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
// import avatar from '../assets/avatar.jpg';
// import {Cart, Chat, Notification, UserProfile} from '.';
import { useStateContext } from '../contexts/ContextProvider';


const NavButton = ({ title, customFunc, icon, color, dotColor }) =>(
  <TooltipComponent content={title} position="BottomCenter">
    <button 
      type='button' 
      onClick={customFunc} 
      style={{color}}
      className='relative text-2xl rounded-full p-2 hover:bg-light-grey'>
        <span 
          style={{background: dotColor}} 
          className='absolute inline-flex rounded-full h-2 w-2 right-2 top-2'/>
        {icon}
    </button>
  </TooltipComponent>
)

const Navbar = () => {
  const {
        // activeMenu, 
        setActiveMenu, 
        // isClicked, 
        // setIsClicked, 
        handleClick,
        screenSize, 
        setScreenSize} = useStateContext();
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);  
  
  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    navigate('/login')
  }
    
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    handleResize();
    return ()=>window.removeEventListener('resize', handleResize)
  });

  useEffect(()=>{
    if(screenSize <= 900){
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  },[screenSize]);

  
  return (  
    <div className='flex justify-between p-2 md:mx-6 '>

      <NavButton 
        title="Menu" 
        customFunc={() => setActiveMenu((prevActiveMenu)=> !prevActiveMenu)}
        color='red'
        icon={<AiOutlineMenu/>}/>
      <div className='flex items-left'>
        {/* <NavButton 
          title="Cart" 
          customFunc={() => handleClick('cart')}
          color='blue'
          icon={<FiShoppingCart/>}/>
        <NavButton 
          title="Chat" 
          dotColor='#03C9D7'
          customFunc={() => handleClick('chat')}
          color='blue'
          icon={<BsChatLeft/>}/>  
        <NavButton 
          title="Notification" 
          dotColor='#03C9D7'
          customFunc={() => handleClick('notification')}
          color='blue'
          icon={<RiNotification3Line/>}/>   */}
          {/* <NavButton 
            title='Logout'
            icon={<><span style={{ marginRight: '0.5rem' }}>Logout <FiLogOut /></span> </>} 
            color='blue'
            customFunc={() => onLogout()} /> */}

        <TooltipComponent 
          content='Profile'
          position='BottomCenter'>
            <div className='flex items-left gap-2 cursor-pointer 
                    p-1 hover:bg-light-gray rounded-lg'
                  onClick={()=>handleClick('userProfile')}  >
                    <RiStarSmileLine className='text-grey-400 text-2xl w-8 h-8'/>
                    {/* <img className='rounded-full w-8 h-8' src={avatar}/> */}
                    <p>
                      <span className='text-grey-400 text-xl'>Hi, </span>{' '}
                      <span className='text-grey-400 font-bold ml-1 text-xl'>{user.name}</span>
                    </p>
                    {/* <MdKeyboardArrowDown className='text-grey-400 text-xl'/> */}
            </div>
        </TooltipComponent>
        <NavButton 
            title='Logout'
            icon={<FiLogOut />} 
            color='red'
            customFunc={() => onLogout()} />
        {/* {isClicked.cart && <Cart/>} 
        {isClicked.chat && <Chat/>}  
        {isClicked.notification && <Notification/>}  
        {isClicked.userProfile && <UserProfile/>}     */}
      </div>  
    </div>
  )
}

export default Navbar