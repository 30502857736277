// import BookCover from '../assets/img/bonuses/image14.jpg';

export default function VideoPagePDFSection(props) {

  const {cover, content, download} = props;
    return (
      <div className="overflow-hidden bg-white py-24 sm:py-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start">
            <div className="lg:pr-4 lg:pt-4">
              <div className="lg:max-w-lg">
                <h2 className="text-xl font-semibold leading-7 text-indigo-600">
                  {content[1].headline}
                </h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    {content[0].headline}
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  {content[0].text}
                </p>
                <div className="mt-8">
                  <a
                    href={download}
                    className="inline-flex rounded-md bg-pink-600 px-12 py-2.5 text-lg uppercase font-semibold text-white shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600"
                  >
                    Get started
                  </a>
                </div>

              </div>
            </div>
            <img
              src={cover}
              alt="Bulletproof Keto Screenshot"
              className="w-[24rem] max-w-[50%] rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:ml-0"
            />
          </div>
        </div>
      </div>
      
    )
  }
  