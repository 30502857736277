import React, {useState, useEffect} from "react";
import {
    QuizPartOne,
    QuizPartTwo
} from '../components';

export default function Quiz(){

    // Show quiz or mealplan survey
    const [showQuizPartTwo, setShowQuizPartTwo] = useState(false);

    // Data from part one that is passed to part two
    const [quizState, setQuizState] = useState({
        quizData:[],
        mealplanData: [],
    })

    function updateQuizState(quizAnswers){
        setQuizState(quizAnswers)
    }

    // Sets the showQuizPartTwo to true when condidion is met
    // prevents component reload 
    useEffect(()=>{
        if(quizState.quizData.length === 11){
            setShowQuizPartTwo(true)
        }
    }, [quizState.quizData.length])

    
    // console.log('QuizState',quizState)
    return showQuizPartTwo ? <QuizPartTwo quizData={quizState.quizData} /> : <QuizPartOne updateQuizState={updateQuizState}/>
}