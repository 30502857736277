import {useState} from 'react';
import {
  AccountUpdateModal, 
  CancelSubscription} from '../components';

export default function AccountSettings() {

  const [open, setOpen] = useState(false)
  const [type, setType] = useState('')

  const userDataString = localStorage.getItem('user');
  // const userData = JSON.parse(userDataString);
  const { name, email, _id} = JSON.parse(userDataString);

  const handleOpenModal = () => {
    setOpen(!open)
  }

  return (
    <>
      <div className="mx-auto max-w-7xl pt-6 lg:flex lg:gap-x-16 lg:px-8">
        <main className="px-4 py-6 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <div>
              <h2 className="text-xl font-semibold leading-7 text-gray-900">Manage account information</h2>
              <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-lg leading-6">
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Name</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{name}</div>
                    <button 
                      type="button" 
                      className="font-medium text-pink-600 hover:text-pink-700"
                      onClick={() => { handleOpenModal(); setType('name');}}>
                      Update name
                    </button>
                  </dd>
                </div>

                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Email</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{email}</div>
                    <button 
                      type="button" 
                      className="font-semibold text-pink-600 hover:text-pink-700"
                      onClick={() => { handleOpenModal(); setType('email');}}>
                      Update email
                    </button>
                  </dd>
                </div>

                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Password</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">********</div>
                    <button 
                      type="button" 
                      className="font-semibold text-pink-600 hover:text-pink-700"
                      onClick={() => { handleOpenModal(); setType('password');}}>
                      Update password
                    </button>
                  </dd>
                </div>
              </dl>
            </div>

            <div>
              <h2 className="text-xl font-semibold leading-7 text-gray-900">Billing settings</h2>

              {/* <ul className="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-lg leading-6">
                <li className="flex justify-between gap-x-6 py-6">
                  <div className="font-medium text-gray-900">TD Canada Trust</div>
                  <button type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">
                    Update
                  </button>
                </li>
                <li className="flex justify-between gap-x-6 py-6">
                  <div className="font-medium text-gray-900">Royal Bank of Canada</div>
                  <button type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">
                    Update
                  </button>
                </li>
              </ul> */}
              <CancelSubscription />
              {/* <div className="flex border-t border-gray-100 pt-6">
                <button type="button" className="text-md font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                   Cancel my subscription
                </button>
              </div> */}
            </div>
          </div>
        </main>
      </div>
      <AccountUpdateModal 
        open={open} 
        type={type} 
        controlModal={handleOpenModal} 
        data={{name,  email, _id}}/>
    </>
  )
}
