import arrowGif from '../../assets/img/arrow-flash-small.gif'

export default function OrderBump(state) {
    return (
      <div className="border-dashed border-2 border-gray-900 bg-yellow-400 mt-3 shadow sm:rounded-lg">
        <div > 
          <h3 className="text-center text-lg rounded-t-lg mx-0 py-2 bg-yellow-200 font-semibold leading-6 text-green-700">
          <span className='mr-2 inline-block align-middle'>
            <img src={arrowGif} alt='Arrow down animation'/>
         </span>
          <input
              id="orderbump"
              name="orderbump"
              type="checkbox"
              onChange={state.onChange}
              className="h-4 w-4 mr-2 rounded border-green-500 text-green-600 focus:ring-green-600"
            /> 
            Yes! I Want This Offer
          </h3>
          <div className="px-3 pb-2 mt-2 max-w-full font-semibold text-md text-gray-700">
            <p>
             <span className="uppercase text-red-700 underline underline-offset-2 font-bold mr-2">one time offer:</span>
             DON’T DO A SINGLE SIT-UP and Lose as much as 3 to 5 inches off your waistline in one month. New Book reveals surprising discoveries in weight-loss science. You will probably lose more inches each week than if you faithfully did hundreds sit-ups every day. It's normally $39.98, but you can have it today for $9.98. THIS OFFER WILL NOT BE REPEATED.
            </p>
          </div>
        </div>
      </div>
    )
  }
  