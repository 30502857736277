import {GiCook} from 'react-icons/gi';

export default function IngridientsList({metric, ingridients}) {

    function capitalizeFirstWord(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    }

    return (
      <ul className="divide-y divide-gray-100">
        {ingridients.map((item) => (
          <li
            key={ingridients.indexOf(item)}
            className="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-5 sm:flex-nowrap"
          >
            <div className='px-6'>
              <p className="text-lg font-medium leading-6 text-gray-900">
                  {!metric ? item.original : capitalizeFirstWord(item.originalName)}
              </p>
            </div>
            <dl className="flex w-full flex-none justify-between gap-x-8 sm:w-auto">
              <div className="flex w-16 text-2xl text-end gap-x-2.5">
                <dt><GiCook /></dt>
                {!metric ? (
                  <dd className="flex text-lg leading-6 text-gray-900">
                    <span className='mx-2'>{item.measures.us.amount}</span> 
                    <span>{item.measures.us.unitShort}</span>
                  </dd>
                ) : (
                  <dd className="flex text-lg leading-6 text-gray-900">
                    <span className='mx-2'>{Math.round(item.measures.metric.amount)}</span> 
                    <span>{item.measures.metric.unitShort}</span>
                  </dd>
                )}

              </div>
            </dl>
          </li>
        ))}
      </ul>
    )
  }