import { useState, useEffect } from "react";
import {SurveyProgress, SurveyTitle} from '../../../components';

const Fats = ({ step, carbs, onCarbChange, nextStep, previousStep }) => {
  
  /* Set state for buttons disabled property */
  const [ disabled, setDisabled ] = useState(true);

  /* Save step and previousStep as props variable to pass to
    SurveyTile where its accessed as props.props */
    const props = { step, previousStep};

  /* check if any checkbox values selected and set setDisabled
    to oposite if its true (any selected) set button disabled to fasle
    and visa versa */
  useEffect(() => {
    const anySelectedValue = Object.values(carbs).some(value => value);
    setDisabled(!anySelectedValue);
  }, [carbs])

  const handleNextStep = (stepValue)=>{
    nextStep(stepValue)
  }

  const handleCarbChange = event => {
    const { name, checked } = event.target;
    onCarbChange('carb', name, checked);
  }

  return (
    <>
    <div className='mx-auto max-w-3xl bg-gray-100 sm:rounded-lg p-4 sm:px-6 lg:px-8'>
      <SurveyTitle props={props}/>
      <SurveyProgress step={step}/>
      <p className="my-3 px-3 text-lg font-semibold text-gray-900">
          Which carbohydrates do you want to be included in your meals?
        </p>
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 mt-5">

        {Object.keys(carbs).map((key, index) => {
            return(
              <label key={index}>
                <li className="ring-2 ring-sky-100 bg-white ring-opacity-60 ring-offset-2
                      ring-offset-sky-300 relative flex cursor-pointer rounded-lg 
                      px-5 py-4 shadow-md hover:bg-sky-100
                      hover:ring-offset-sky-600 hover:ring-offset-4">
                  
                      <input 
                        type="checkbox" 
                        name={key} 
                        checked={carbs[key]} 
                        onChange={handleCarbChange}
                        className='my-auto rounded border-gray-600 text-sky-600 focus:ring-sky-600'
                      />    
                    <div className='mx-auto items-center '>
                      <span className="inline-flex flex-shrink-0 px-0.5 pt-0.5 text-xl capitalize font-medium">
                        {key}      
                      </span>  
                    </div>    
                </li>
              </label> )
        })}
      </ul>
    </div>
    <div>
      <button
          type="submit"
          disabled={disabled}
          onClick={()=> handleNextStep(step)}
          className="flex w-full h-12 justify-center rounded-md bg-orange-600 uppercase px-3 mt-12 py-2 text-lg font-semibold text-white shadow-sm hover:bg-orange-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-800"
          >
          Next
      </button>
    </div>
    </>
  )
}

export default Fats