import {useState} from 'react';
import { useSelector } from 'react-redux';
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from 'axios';
import { useDispatch } from "react-redux";
import { newBuyer} from '../../features/buyer/buyerSlice';
import {updateMealplanRecord} from '../../features/mealplan/mealplanSlice';
import { Navigate } from 'react-router-dom';
import {CardFormContainer, OrderBump} from '../../components';
import SubscriptionDisclaimer from './PaymentFormSubscribeDisclaimer';

const serverSideBaseAPI = process.env.REACT_APP_SERVERSIDE_BASE_API;

// const serverSideBaseAPI = 'http://localhost:5000/api';


const PaymentFormSubscribe = ({bump, name, email, price, onBumpChange, onStepChange}) => {

    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    // setting purchase to true is card charge is success
    const [purchase, setPurchase] = useState(false);

    const dispatch = useDispatch();

    let apiEndpoint;
    if(bump){
        apiEndpoint = '/buy/subscribebump';
    } else {
        apiEndpoint = '/buy/subscribe';
    }

    const Chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    // Creates random 12 chars for buyerId 
    const randomString = (length, chars) => {
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
        return result;
    }
    
    // and grab beehiivSubId from Redux Store
    const optinData = useSelector((state) => state.optin.data);

    const optinId = optinData ? optinData.newOptinRecord.optinId : '';
    const beehiivSubId = optinData ? optinData.newOptinRecord.beehiivSubId : '';
    
    const handleSubmit = async (event)=>{
        event.preventDefault();
        if(!stripe || !elements){
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        onStepChange();
        setIsProcessing(true);
        // Create payment method
        const paymentMethod = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {name, email}
        });

        if (paymentMethod.error && (paymentMethod.error.type === 'validation_error' || paymentMethod.error.type === 'card_error')){
            setErrorMessage(paymentMethod.error.message);
            setIsProcessing(false)
        } 
        else {
            // data here is data value part of response from backend api 
            try {
                const {data}  = await axios.post(serverSideBaseAPI + apiEndpoint, 
                    {
                        name, 
                        email,
                        payment_method: paymentMethod.paymentMethod.id
                    });
    
                    // console.log('After sub Attemt ',data)
                
                // Handle Order bump single charge 
                const {client_secret} = data;
                const {paymentIntent} = await stripe.confirmCardPayment(client_secret);
                // console.log(paymentIntent)
                if (paymentIntent.status === "succeeded"){

                    const buyerId = randomString(12, Chars);

                    const mealplanIdLocalStorage = localStorage.getItem('mealplanId');
                    const mealplanId = mealplanIdLocalStorage.replace(/^"(.*)"$/, '$1');
                    
                    const BuyerDetails = {
                        name,
                        email,
                        amount: paymentIntent.amount,
                        created: paymentIntent.created,
                        intentId: paymentIntent.id,
                        orderBump: bump,
                        optinId,
                        buyerId,
                        beehiivSubId,
                        mealplanUpsell: false,
                        subscription: true,
                        subscriptionId: data.subscription,
                        customerId: data.customer
                    }
                    
                    dispatch(newBuyer(BuyerDetails))
                    setPurchase(true)
                    dispatch(updateMealplanRecord({buyerId, mealplanId}))

                    // Add final purchase amount to dataLayer
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      'event': 'gtm.click',
                      'customerData': {
                        'amount': price
                      }
                    })
                }

            } catch (error) {
                setErrorMessage(error.response.data.error.message)
                setIsProcessing(false)
            }
        }


            
            // // data here is data value part of response from backend api 
            // const {data}  = await axios.post(serverSideBaseAPI + apiEndpoint, 
            //     {
            //         name, 
            //         email,
            //         payment_method: paymentMethod.id
            //     });

            // console.log('After sub Attemt ',data)
            // if (data.error){
            //     console.log('Error from creating sub', data.error)
            // }    
            // const {client_secret} = data;

            // // Handle Order bump single charge 
            // const response = await stripe.confirmCardPayment(client_secret);
            // console.log(response)
            // if (response.error && (response.error.type === 'validation_error' || response.error.type === 'card_error')){
            //     setErrorMessage(response.error.message);
            //     setIsProcessing(false)
            // } else {
            //     if (response.paymentIntent.status === "succeeded"){
                    
                //         const buyerId = randomString(12, Chars);

                //         const BuyerDetails = {
                //             name,
                //             email,
                //             amount: response.paymentIntent.amount,
                //             created: response.paymentIntent.created,
                //             intentId: response.paymentIntent.id,
                //             orderBump: bump,
                //             buyerId,
                //             mealplanUpsell: false,
                //             subscription: false,
                //             subscriptionId: "",
                //             customerId: data.customer
                //         }

                //         dispatch(newBuyer(BuyerDetails))
                //         setPurchase(true)
            //     }
            // }

            // setIsProcessing(false);
        // }    
      }     

      const iframeStyles = {
        base: {
          color: "#32325",
          fontSize: "18px",
          iconColor: "#32325",
          "::placeholder": {
            color: "#32325"
          }
        },
        invalid: {
            iconColor: '#F51720',
            color: '#F51720',
          },
        complete: {
          iconColor: "green",
          color: "green"
        }
    };

    const cardElementOptions = {
        iconStyle: "solid",
        style: iframeStyles,
        hidePostalCode: true
    };

    // On purchase success navigate to desired page
    if (purchase) {
        return <Navigate to={{pathname: '/register'}}/>
    }

  return (
    <>
    <form onSubmit={handleSubmit}>
        <CardFormContainer>
         <CardElement options={cardElementOptions}/>
        </CardFormContainer>
        { errorMessage ? <p className="text-red-600 font-semibold text-base">{errorMessage}</p> : ''}
        <OrderBump state={bump} onChange={onBumpChange}/>
        <SubscriptionDisclaimer price={price}/>
        <button
            id="submit"
            disabled={isProcessing || !stripe || !elements} 
            className="rounded-md min-w-full bg-red-500 px-3 my-3 py-2 text-lg 
                        font-semibold text-white shadow-sm hover:bg-red-600 
                        outline outline-2 outline-red-600"
            >
            { isProcessing  ? "Processing ... " :  "Yes! Let's Go!"}    
        </button>
    </form>
    </>
  )
}

export default PaymentFormSubscribe;