// NotFound.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();
  
  // Redirect to /home when this component is rendered
  React.useEffect(() => {
    navigate('/');
  }, [navigate]);

  return null; // render nothing
}

export default NotFound;
