const DISCLAIMER = 
[
    {
        id: 1,
        type: 'disclaimer',
        date: 'January, 27th 2020',
        text: [
            {
                sequence: "h1",
                text: "WEBSITE DISCLAIMER",
                type: "h"
            },
            {
                sequence: "p1",
                text: "The information provided by EatToBurn (“we,” “us” or “our”) on www.eattoburn.com (the “Site”) and our mobile application is for general informational purposes only. All information on the Site and our mobile application is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the Site or our mobile application. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR OUR MOBILE APPLICATION OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE AND OUR MOBILE APPLICATION. YOUR USE OF THE SITE AND OUR MOBILE APPLICATION AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE AND OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.",
                type: "p"
            },
            {
                sequence: "h2",
                text: "EXTERNAL LINKS DISCLAIMER",
                type: "h"
            },
            {
                sequence: "p2",
                text: "The Site and our mobile application may contain (or you may be sent through the Site or our mobile application) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability or completeness by us. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.",
                type: "p"
            },
            {
                sequence: "h3",
                text: "PROFESSIONAL DISCLAIMER",
                type: "h"
            },
            {
                sequence: "p3",
                text: "The Site cannot and does not contain fitness advice. The fitness information is provided for general informational and educational purposes only and is not a substitute for professional advice. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. We do not provide any kind of fitness advice. THE USE OR RELIANCE OF ANY INFORMATION CONTAINED ON THIS SITE OR OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.",
                type: "p"
            },
            {
                sequence: "h4",
                text: "AFFILIATES DISCLAIMER",
                type: "h"
            },
            {
                sequence: "p4",
                text: "The Site and our mobile application may contain links to affiliate websites, and we receive an affiliate commission for any purchases made by you on the affiliate website using such links. Our affiliates include the following: Clickbank",
                type: "p"
            },
            {
                sequence: "h5",
                text: "TESTIMONIALS DISCLAIMER",
                type: "h"
            },
            {
                sequence: "p5",
                text: "The Site may contain testimonials by users of our products and/or services. These testimonials reflect the real-life experiences and opinions of such users. However, the experiences are personal to those particular users, and may not necessarily be representative of all users of our products and/or services. We do not claim, and you should not assume, that all users will have the same experiences. YOUR INDIVIDUAL RESULTS MAY VARY. ",
                type: "p"
            },
            {
                sequence: "p6",
                text: "The testimonials on the Site are submitted in various forms such as text, audio and/or video, and are reviewed by us before being posted. They appear on the Site verbatim as given by the users, except for the correction of grammar or typing errors. Some testimonials may have been shortened for the sake of brevity where the full testimonial contained extraneous information not relevant to the general public.",
                type: "p"
            },
            {
                sequence: "p7",
                text: "The views and opinions contained in the testimonials belong solely to the individual user and do not reflect our views and opinions. We are not affiliated with users who provide testimonials, and users are not paid or otherwise compensated for their testimonials.",
                type: "p"
            },
            {
                sequence: "p8",
                text: "The testimonials on the Site are not intended, nor should they be construed, as claims that our products and/or services can be used to diagnose, treat, mitigate, cure, prevent or otherwise be used for any disease or medical condition. No testimonials have been clinically proven or evaluated.",
                type: "p"
            }

        ]
    },
    {
        id: 2,
        type: 'privacypolicy',
        date: 'January, 27th 2020',
        text: [
            {
                sequence: "h1",
                text: "PRIVACY POLICY",
                type: "h"
            },
            {
                sequence: "p1",
                text: "Thank you for choosing to be part of our community at EatToBurn (“Company”, “we”, “us”, or “our”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at contact@eattoburn.com .",
                type: "p"
            },
            {
                sequence: "p2",
                text: "When you visit our and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy policy, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our and our services. This privacy policy applies to all information collected through our and/or any related services, sales, marketing or events (we refer to them collectively in this privacy policy as the 'Services').",
                type: "p"
            },
            {
                sequence: "p3",
                text: "Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.",
                type: "p"
            },
            {
                sequence: "h2",
                text: "WHAT INFORMATION DO WE COLLECT?",
                type: "h"
            },
            {
                sequence: "p4",
                text: "Personal information you disclose to us",
                type: "p"
            },
            {
                sequence: "p5",
                text: "We collect personal information that you provide to us such as name, address, contact information, passwords and security data, and payment information.",
                type: "p"
            },
            { 
                sequence: "p6",
                text: "We collect personal information that you voluntarily provide to us when registering at the expressing an interest in obtaining information about us or our products and services, when participating in activities on the (such as posting messages in our online forums or entering competitions, contests or giveaways) or otherwise contacting us.",
                type: "p"
            },
            {
                sequence: "p7",
                text: "The personal information that we collect depends on the context of your interactions with us and the , the choices you make and the products and features you use. The personal information we collect can include the following: ",
                type: "p"
            },
            {
                sequence: "p8",
                text: "Credentials. We collect passwords, password hints, and similar security information used for authentication and account access.",
                type: "p"
            },
            {
                sequence: "p9",
                text: "Social Media Login Data. We provide you with the option to register using social media account details, like your Facebook, Twitter or other social media account. If you choose to register in this way, we will collect the Information described in the section called 'HOW DO WE HANDLE YOUR SOCIAL LOGINS' below.",
                type: "p"
            },
            {
                sequence: "p10",
                text: "All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.",
                type: "p"
            },
            {
                sequence: "p11",
                text: "Information automatically collected",
                type: "p"
            },
            {
                sequence: "p12",
                text: "Some information — such as IP address and/or browser and device characteristics — is collected automatically when you visit our . We automatically collect certain information when you visit, use or navigate the . This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our and other technical information. This information is primarily needed to maintain the security and operation of our , and for our internal analytics and reporting purposes.",
                type: "p"
            },
            {
                sequence: "p13",
                text: "Like many businesses, we also collect information through cookies and similar technologies.",
                type: "P"
            },
            {
                sequence: "h3",
                text: "WILL YOUR INFORMATION BE SHARED WITH ANYONE?",
                type: "h"
            },
            {
                sequence: "p14",
                text: "We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations. We may process or share data based on the following legal basis:",
                type: "p"
            },
            {
                sequence: "p15",
                text: "Consent: We may process your data if you have given us specific consent to use your personal information in a specific purpose.",
                type: "p"
            },
            {
                sequence: "p16",
                text: "Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.",
                type: "p"
            },
            {
                sequence: "p17",
                text: "Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.",
                type: "p"
            },
            {
                sequence: "p18",
                text: "Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).",
                type: "p"
            },
            {
                sequence: "p19",
                text: "Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.",
                type: "p"
            },
            {
                sequence: "p20",
                text: "Vendors, Consultants and Other Third-Party Service Providers. We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the , which will enable them to collect data about how you interact with the over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. Unless described in this Policy, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes.",
                type: "p"
            },
            {
                sequence: "p21",
                text: "Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.",
                type: "p"
            },
            {
                sequence: "p22",
                text: "Third-Party Advertisers. We may use third-party advertising companies to serve ads when you visit the . These companies may use information about your visits to our Website(s) and other websites that are contained in web cookies and other tracking technologies in order to provide advertisements about goods and services of interest to you. ",
                type: "p"
            },
            {
                sequence: "p23",
                text: "Affiliates. We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.",
                type: "p"
            },
            {
                sequence: "p24",
                text: "Business Partners. We may share your information with our business partners to offer you certain products, services or promotions.",
                type: "p"
            },
            {
                sequence: "p25",
                text: "Other Users. When you share personal information or otherwise interact with public areas of the , such personal information may be viewed by all users and may be publicly distributed outside the in perpetuity. If you interact with other users of our and register through a social network (such as Facebook), your contacts on the social network will see your name, profile photo, and descriptions of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our , and view your profile.",
                type: "p"
            },
            {
                sequence: "h4",
                text: "DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?",
                type: "h"
            },
            {
                sequence: "p26",
                text: "We may use cookies and other tracking technologies to collect and store your information. We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy.",
                type: "p"
            },
            {
                sequence: "h5",
                text: "HOW DO WE HANDLE YOUR SOCIAL LOGINS?",
                type: "h"
            },
            {
                sequence: "p27",
                text: "If you choose to register or log in to our services using a social media account, we may have access to certain information about you. Our offer you the ability to register and login using your third party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile Information we receive may vary depending on the social media provider concerned, but will often include your name, e-mail address, friends list, profile picture as well as other information you choose to make public. We will use the information we receive only for the purposes that are described in this privacy policy or that are otherwise made clear to you on the . Please note that we do not control, and are not responsible for, other uses of your personal information by your third party social media provider. We recommend that you review their privacy policy to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps.",
                type: "p"
            },
            {
                sequence: "h6",
                text: "IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?",
                type: "h"
            },
            {
                sequence: "p28",
                text: "We may transfer, store, and process your information in countries other than your own. Our servers are located in. If you are accessing our from outside, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see 'WILL YOUR INFORMATION BE SHARED WITH ANYONE?' above), in and other countries.",
                type: "p"
            },
            {
                sequence: "p29",
                text: "If you are a resident in the European Economic Area, then these countries may not have data protection or other laws as comprehensive as those in your country. We will however take all necessary measures to protect your personal information in accordance with this privacy policy and applicable law. ",
                type: "p"
            },
            {
                sequence: "h7",
                text: "WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?",
                type: "h"
            },
            {
                sequence: "p30",
                text: "We are not responsible for the safety of any information that you share with third-party providers who advertise, but are not affiliated with, our websites.",
                type: "p"
            },
            {
                sequence: "p31",
                text: "The may contain advertisements from third parties that are not affiliated with us and which may link to other websites, online services or mobile applications. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy policy. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services or applications that may be linked to or from the . You should review the policies of such third parties and contact them directly to respond to your questions.",
                type: "p"
            },
            {
                sequence: "h8",
                text: "HOW LONG DO WE KEEP YOUR INFORMATION?",
                type: "h"
            },
            {
                sequence: "p32",
                text: "We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law. We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this policy will require us keeping your personal information for longer than 6 months.",
                type: "p"
            },
            {
                sequence: "p33",
                text: "When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.",
                type: "p"
            },
            {
                sequence: "h9",
                text: "DO WE COLLECT INFORMATION FROM MINORS?",
                type: "h"
            },
            {
                sequence: "p34",
                text: "We do not knowingly collect data from or market to children under 18 years of age. We do not knowingly solicit data from or market to children under 18 years of age. By using the , you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the . If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under age 18, please contact us at contact@eattoburn.com .",
                type: "p"
            },
            {
                sequence: "h10",
                text: "WHAT ARE YOUR PRIVACY RIGHTS?",
                type: "h"
            },
            {
                sequence: "p35",
                text: "You may review, change, or terminate your account at any time. If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.",
                type: "p"
            },
            {
                sequence: "p36",
                text: "Account Information: If you would at any time like to review or change the information in your account or terminate your account, you can: Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.",
                type: "p"
            },
            { 
                sequence: "p37",
                text: "Opting out of email marketing: You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list – however, we will still need to send you service-related emails that are necessary for the administration and use of your account. ",
                type: "p"
            },
            {
                sequence: "h11",
                text: "CONTROLS FOR DO-NOT-TRACK FEATURES",
                type: "h"
            },
            {
                sequence: "p38",
                text: "Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy policy.",
                type: "p"
            },
            {
                sequence: "h12",
                text: "DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?",
                type: "h"
            },
            {
                sequence: "p39",
                text: "  Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information. California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.",
                type: "p"
            },
            {
                sequence: "p40",
                text: "If you are under 18 years of age, reside in California, and have a registered account with the , you have the right to request removal of unwanted data that you publicly post on the . To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the , but please be aware that the data may not be completely or comprehensively removed from our systems.",
                type: "p"
            },
            {
                sequence: "h13",
                text: "DO WE MAKE UPDATES TO THIS POLICY?",
                type: "h"
            },
            {
                sequence: "p41",
                text: "Yes, we will update this policy as necessary to stay compliant with relevant laws. We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.",
                type: "p"
            },
            {
                sequence: "h14",
                text: "HOW CAN YOU CONTACT US ABOUT THIS POLICY?",
                type: "h"
            },
            {
                sequence: "p42",
                text: "If you have questions or comments about this policy, you may email us at contact@eattoburn.com .",
                type: "p"
            },
            {
                sequence: "h15",
                text: "HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?",
                type: "h"
            },
            {
                sequence: "p43",
                text: "Based on the laws of some countries, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please submit a request to contact@eattoburn.com . We will respond to your request within 30 days.",
                type: "p"
            }
        ]
    },
    {
        id: 3,
        type: 'termsofuse',
        date: 'January, 27th 2020',
        text: [
            {
                sequence: "h1",
                text: "TERMS OF USE",
                type: "h"
            },
            {
                sequence: "p1",
                text: "These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and EatToBurn ('Company', “we”, “us”, or “our”), concerning your access to and use of the EatToBurn website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”). You agree that by accessing the Site, you have read, understood, and agreed to be bound by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.",
                type: "p"
            },
            {
                sequence: "p2",
                text: "Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use at any time and for any reason. We will alert you about any changes by updating the “Last updated” date of these Terms of Use, and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Terms of Use to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms of Use by your continued use of the Site after the date such revised Terms of Use are posted.",
                type: "p"
            },
            {
                sequence: "p3",
                text: "The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Site from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.",
                type: "p"
            },
            {
                sequence: "h2",
                text: "INTELLECTUAL PROPERTY RIGHTS",
                type: "h"
            },
            {
                sequence: "p4",
                test: "Unless otherwise indicated, the Site is our proprietary property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws of the United States, foreign jurisdictions, and international conventions. The Content and the Marks are provided on the Site “AS IS” for your information and personal use only. Except as expressly provided in these Terms of Use, no part of the Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.",
                type: "p"
            },
            {
                sequence: "p5",
                text: "Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the Site, the Content and the Marks.",
                type: "p"
            },
            {
                sequence: "h3",
                text: "USER REPRESENTATIONS",
                type: "h"
            },
            {
                sequence: "p6",
                text: "By using the Site, you represent and warrant that: (1) you have the legal capacity and you agree to comply with these Terms of Use; (2) you are not a minor in the jurisdiction in which you reside; (3) you will not access the Site through automated or non-human means, whether through a bot, script, or otherwise; (4) you will not use the Site for any illegal or unauthorized purpose; and (5) your use of the Site will not violate any applicable law or regulation.",
                type: "p"
            },
            {
                sequence: "p7",
                text: "If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any portion thereof).",
                type: "p"
            },
            {
                sequence: "h4",
                text: "CONTRIBUTION LICENSE",
                type: "h"
            },
            {
                sequence: "p8",
                text: "You and Site agree that we may access, store, process, and use any information and personal data that you provide following the terms of the Privacy Policy and your choices (including settings). By submitting suggestions or other feedback regarding the Site, you agree that we can use and share such feedback for any purpose without compensation to you. ",
                type: "p"
            },
            {
                sequence: "p9",
                text: "We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable for any statements or representations in your Contributions provided by you in any area on the Site. You are solely responsible for your Contributions to the Site and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions.", 
                type: "p"
            },
            {
                sequence: "h5",
                text: "SITE MANAGEMENT",
                type: "h"
            },
            {
                sequence: "p10",
                text: "We reserve the right, but not the obligation, to: (1) monitor the Site for violations of these Terms of Use; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Use, including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site.",
                type: "p"
            },
            {
                sequence: "h6",
                text: "FEES, RENEWAL, CANCELLATIONS & REFUND POLICIES",
                type: "h"
            },
            {
                sequence: "p11",
                text: "Subscribers to our Premium Membership further agree as follows: Agreement to Pay. If you are a Premium Membership Subscriber, you will be responsible for the fixed and periodic charges and fees (including prepayment plan fees for multiple periods or recurring monthly fees) you selected at the time of subscription. Applicable taxes, and other charges and fees incurred in order to access the Premium Services may also apply.",
                type: "p"
            },
            {
                sequence: "p12",
                text: "Auto-Renewal. Your Premium Membership will automatically be extended for successive renewal periods of the same duration selected at the time of your purchase, at the then-current non-promotional subscription rate. Until you cancel, we will charge or debit your payment method at the beginning of your subscription. Your non-cancellation of the Premium membership or continued use of the paid subscription features of the Premium Membership will reaffirm that we are authorized to charge you. If your credit or debit account has been closed or your payment method is otherwise invalid, your subscription may not renew and your subscription will be automatically canceled effective as of the end of your current billing cycle. The renewal charge will generally be the same as the prior period’s charge, unless we notify you in advance at the time of sign up or prior to the beginning of the renewal period as described above.",
                type: "p"
            },
            {
                sequence: "p13",
                text: "Cancellation of Premium Membership. You can cancel your subscription for our Premium Membership at any time by contacting Customer Support at contact@eattoburn.com or thought Billing Section at Customer Dashboard or by such other means as we may provide from time to time. We will attempt to process all cancellation requests within 72 hours after we receive your request. We reserve the right to collect fees, surcharges, or costs incurred before your cancellation takes effect. If you terminate your paid subscription for Premium Membership, your subscription will remain active until the end of your then-current subscription period.",
                type: "p"
            },
            {
                sequence: "p14",
                text: "Refund Policy. If you terminate your paid subscription for Premium Membership, you will not receive a refund for any fees already paid, your subscription will remain active until the end of your then-current subscription period.",
                type: "p"
            },
            {
                sequence: "p15",
                text: "Modifications. We reserve the right to revise the terms of the fee charge, cancellation and refund policies, upon reasonable advance notice communicated to you through a posting on the Website and/or Mobile App or such other means as we may deem appropriate from time to time (including electronic mail or conventional mail). Any changes made will apply to all memberships created or renewed after the date such change was implemented.",
                type: "p"
            },
            {
                sequence: "h7",
                text: "TERM AND TERMINATION",
                type: "h"
            },
            {
                sequence: "p16",
                text: "These Terms of Use shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.",
                type: "p"
            },
            {
                sequence: "p17",
                text: "If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.",
                type: "p"
            },
            {
                sequence: "h8",
                text: "MODIFICATIONS AND INTERRUPTIONS ",
                type: "h"
            },
            {
                sequence: "p18",
                text: "We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Site. We also reserve the right to modify or discontinue all or part of the Site without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Site.  ",
                type: "p"
            },
            {
                sequence: "p19",
                text: "We cannot guarantee the Site will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Site during any downtime or discontinuance of the Site. Nothing in these Terms of Use will be construed to obligate us to maintain and support the Site or to supply any corrections, updates, or releases in connection therewith.",
                type: "p"
            },
            {
                sequence: "h9",
                text: "GOVERNING LAW",
                type: "h"
            },
            {
                sequence: "p20",
                text: "These Terms shall be governed by and defined following the laws of New York, USA. EatToBurn and yourself irrevocably consent that the courts of New York, USA shall have exclusive jurisdiction to resolve any dispute which may arise in connection with these terms.",
                type: "p"
            },
            {
                sequence: "h10",
                text: "DISPUTE RESOLUTION",
                type: "h"
            },
            {
                sequence: "p21",
                text: "Binding Arbitration: Any dispute arising out of or in connection with this contract, including any question regarding its existence, validity or termination, shall be referred to and finally resolved by the International Commercial Arbitration Court under the European Arbitration Chamber (Belgium, Brussels, Avenue Louise, 146) according to the Rules of this ICAC, which, as a result of referring to it, is considered as the part of this clause. The number of arbitrators shall be one. The seat, or legal place, of arbitration shall be New York, USA. The language of the proceedings shall be English.",
                type: "p"
            },
            {
                sequence: "p22",
                text: "Restrictions: The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To the full extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is no right or authority for any Dispute to be arbitrated on a class-action basis or to utilize class action procedures; and (c) there is no right or authority for any Dispute to be brought in a purported representative capacity on behalf of the general public or any other persons.",
                type: 'p'
            },
            {
                sequence: "p23",
                text: "Exceptions to Arbitration: The Parties agree that the following Disputes are not subject to the above provisions concerning binding arbitration: (a) any Disputes seeking to enforce or protect, or concerning the validity of, any of the intellectual property rights of a Party; (b) any Dispute related to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorized use; and (c) any claim for injunctive relief. If this provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion of this provision found to be illegal or unenforceable and such Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that court.",
                type: "p"
            },
            {
                sequence: "h11", 
                text: "CORRECTIONS",
                type: "h"
            }, 
            {
                sequence: "p24", 
                text: "There may be information on the Site that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Site at any time, without prior notice.",
                type: "p"
            },
            {
                sequence: "h12",
                text: "DISCLAIMER",
                type: "h"
            },
            {
                sequence: "p25",
                text: "THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.",
                type: "p"
            }, 
            {
                sequence: "h13",
                text: "LIMITATIONS OF LIABILITY",
                type: "h"
            },
            {
                sequence: "p26",
                text: "IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO US OR $1.00 . CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.",
                type: "p"
            },
            {
                sequence: "h14",
                text: "INDEMNIFICATION",
                type: "h"
            },
            {
                sequence: "p27",
                text: "You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: (1) use of the Site; (2) breach of these Terms of Use; (3) any breach of your representations and warranties set forth in these Terms of Use; (4) your violation of the rights of a third party, including but not limited to intellectual property rights; or (5) any overt harmful act toward any other user of the Site with whom you connected via the Site. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.",
                type: "p"
            },
            {
                sequence: "h15",
                text: "USER DATA",
                type: "h"
            },
            {
                sequence: "p28",
                text: "We will maintain certain data that you transmit to the Site for the purpose of managing the performance of the Site, as well as data relating to your use of the Site. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Site. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.",
                type: "p"
            },
            {
                sequence: "h16", 
                text: "ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES",
                type: "h"
            }, 
            {
                sequence: "p29",
                text: "Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means. ",
                type: "p"
            },
            {
                sequence: "h17",
                text: "CALIFORNIA USERS AND RESIDENTS",
                type: "h"
            },
            {
                sequence: "p30",
                text: "If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916) 445-1254.",
                type: "p"
            },
            {
                sequence: "h18",
                text: "MISCELLANEOUS",
                type: "h"
            },
            {
                sequence: "p31",
                text: "These Terms of Use and any policies or operating rules posted by us on the Site or in respect to the Site constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Terms of Use shall not operate as a waiver of such right or provision. These Terms of Use operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these Terms of Use is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms of Use and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Terms of Use or use of the Site. You agree that these Terms of Use will not be construed against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Terms of Use and the lack of signing by the parties hereto to execute these Terms of Use.",
                type: "p"
            },
            {
                sequence: "h19",
                text: "CONTACT US ", 
                type: "h"
            },
            {
                sequence: "p32",
                text: "In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site, please contact us at: contact@eattoburn.com",
                type: "p"
            }
        ]
    },
    {
        id: 3,
        type: 'references',
        date: 'January, 27th 2020',
        text: [
            {
                sequence: "h1",
                text: "Scientific References and Studies",
                type: "h"
            },
            {
                sequence: "p1",
                text: "(1) Twinkie diet helps nutrition professor lose 27 pounds, Madison Park, CNN, 2010",
                type: "p"
            },
            {
                sequence: "p2",
                text: "(2) Energy balance and its components: implications for body weight regulation, Am J Clin Nutr. 2012 Apr; 95(4): 989–994.",
                type: "p"
            },
            {
                sequence: "p3",
                text: "(3) Adaptive thermogenesis in humans, Int J Obes (Lond). 2010 Oct; 34(0 1): S47–S55.",
                type: "p"
            },
            {
                sequence: "p4",
                text: "(4) Effects of low-carbohydrate diets versus low-fat diets on metabolic risk factors: a meta-analysis of randomized controlled clinical trials, Am J Epidemiol. 2012 Oct 1;176 Suppl 7(Suppl 7):S44-54.",
                type: "p"
            },
            {
                sequence: "p5",
                text: "(5) Effects of low-carbohydrate vs low-fat diets on weight loss and cardiovascular risk factors: a meta-analysis of randomized controlled trials, Arch Intern Med. 2006 Feb 13;166(3):285-93.  doi: 10.1001/archinte.166.3.285.",
                type: "p"
            },
            {
                sequence: "p6",
                text: "(6) Validity of predictive equations to estimate RMR in females with varying BMI, J Nutr Sci. 2020 May 26;9:e17.  doi: 10.1017/jns.2020.11.",
                type: "p"
            },
            {
                sequence: "p7",
                text: "(7) Estimation of energy expenditure using prediction equations in overweight and obese adults: a systematic review, J Hum Nutr Diet. 2016 Aug;29(4):458-76.  doi: 10.1111/jhn.12355.  Epub 2016 Feb 29",
                type: "p"
            },
            {
                sequence: "p8",
                text: "(8) A new predictive equation for resting energy expenditure in healthy individuals, Am J Clin Nutr. 1990 Feb;51(2):241-7.  doi: 10.1093/ajcn/51.2.241.",
                type: "p"
            },
            {
                sequence: "p9",
                text: "(9) Systematic review and meta-analysis of different dietary approaches to the management of type 2 diabetes, Am J Clin Nutr. 2013 Mar;97(3):505-16.  doi: 10.3945/ajcn.112.042457. Epub 2013 Jan 30.",
                type: "p"
            },
            {
                sequence: "p10",
                text: "(10) A randomized controlled trial of 130 g/day low-carbohydrate diet in type 2 diabetes with poor glycemic control, Clin Nutr. 2017 Aug;36(4):992-1000. doi: 10.1016/j.clnu.2016.07.003.  Epub 2016 Jul 18.",
                type: "p"
            },
            {
                sequence: "p11",
                text: "(11) One year follow-up after a randomized controlled trial of a 130 g/day low-carbohydrate diet in patients with type 2 diabetes mellitus and poor glycemic control, PLoS One. 2017 Dec 4;12(12):e0188892. doi: 10.1371/journal.pone.0188892.  eCollection 2017.",
                type: "p"
            },
            {
                sequence: "p12",
                text: "(12) A non-calorie-restricted low-carbohydrate diet is effective as an alternative therapy for patients with type 2 diabetes, Intern Med. 2014;53(1):13-9. doi: 10.2169/internalmedicine.53.0861.",
                type: "p"
            },
            {
                sequence: "p13",
                text: "(13) Two diets with different haemoglobin A1c and antiglycaemic medication effects despite similar weight loss in type 2 diabetes, Diabetes Obes Metab. 2014 Jan;16(1):90-3.  doi: 10.1111/dom.12191.  Epub 2013 Aug 29.",
                type: "p"
            },
            {
                sequence: "p14",
                text: "(14) Glycemic index, glycemic load, and dietary fiber intake and incidence of type 2 diabetes in younger and middle-aged women, Am J Clin Nutr. 2004 Aug;80(2):348-56.  doi: 10.1093/ajcn/80.2.348.",
                type: "p"
            },
            {
                sequence: "p15",
                text: "(15) Ego depletion and the strength model of self-control: a meta-analysis, Psychol Bull. 2010 Jul;136(4):495-525.  doi: 10.1037/a0019486.",
                type: "p"
            },
            {
                sequence: "p16",
                text: "(16) Biochemistry, Ketogenesis, Kiranjit K. Dhillon; Sonu Gupta. 2020 Apr 6.",
                type: "p"
            },
            {
                sequence: "p17",
                text: "(17) Ketogenic enteral nutrition as a treatment for obesity: short term and long term results from 19,000 patients, Nutr Metab (Lond). 2012; 9: 96.",
                type: "p"
            }
        ]
    }
];

export default DISCLAIMER;