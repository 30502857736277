import React from 'react'
import VideoPagePDFSection from '../components/VideoPagePDFSection';
import VideoPageVideoSection from '../components/VideoPageVideoSection';

// Content imports for PDF
import BookCover from '../assets/img/bonuses/image14.jpg';
import PDFSectionContent from '../assets/PDFSectionContent';

// Content imports for videos
import videoProperties from '../assets/BulletproofVideoProps';
import cdImage from '../assets/img/bonuses/bulletproofCD.png';

const download = 'https://eat2burndownload.s3.amazonaws.com/Bulletproof/BulletproofKetoGuide.pdf';

const Bulletproof = () => {
  return (
    <>
      <VideoPagePDFSection 
        cover={BookCover} 
        content={PDFSectionContent.Bulletproof}
        download={download}/>
      <VideoPageVideoSection 
        image={cdImage} 
        videos={videoProperties}/>
    </>
  )
}

export default Bulletproof