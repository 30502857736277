import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import mealplanService from './mealplanService';

const initialState = {
    // if user is set in localStorage save as user else set as empty string
    data: null,
    isError: false,
    isSuccess: false,
    isLoading:false, 
    message: ''
}

// Create new mealplan record Thunk
export const newMealplanRecord = createAsyncThunk('mealplan/createRecord',
    async (mealplanData, thunkAPI) => {
        try{
            return await mealplanService.newMealplanRecord(mealplanData)
        }catch(error){
            const message = 
                (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message || 
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    })

// Retreive mealplan record Thunk based on buyerId 
// This thunk is used to retrieve mealplan data for existing and new buyers
export const mealplanRecord = createAsyncThunk('mealplan/mealplanRecord',
    async (buyerId, thunkAPI) => {
        try{
            return await mealplanService.mealplanRecord({buyerId:buyerId})
        } catch(error){
            const message = 
                (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message || 
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Retrieve mealplan record for noneBuyers 
// THis is used to recreate sales page data to users who bailed before checkout
export const mealplanRecordNoneBuyer = createAsyncThunk('mealplan/mealplanRecordNoneBuyer',
    async (idObject, thunkAPI) => {
        try{
            return await mealplanService.mealplanRecordNoneBuyer(idObject);
        } catch(error){
            const message = 
                (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Update mealplan record Thunk
export const updateMealplanRecord = createAsyncThunk('mealplan/updateRecord',
    async (mealplanData, thunkAPI) => {
        try {
            return await mealplanService.updateMealplanRecord({mealplanData})
        } catch (error){
            const message = 
                (error.response && 
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)
// Get mealplan recipes
export const mealplanRecipes = createAsyncThunk('mealplan/mealplanRecipes',
    async (mealplanParams, thunkAPI) => {

        try{
            const token = thunkAPI.getState().auth.user.token
            return await mealplanService.mealplanBuilder(mealplanParams, token)
        } catch(error){
            const message = 
                (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message || 
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const mealplanSlice = createSlice({
    name: "mealplan",
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder 
            .addCase(newMealplanRecord.pending, (state) => {
                state.isLoading = true
            })
            .addCase(newMealplanRecord.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.data = action.payload
                // state.mealplan.push(action.payload)
            })
            .addCase(newMealplanRecord.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(mealplanRecord.pending, (state) => {
                state.isLoading = true
            })
            .addCase(mealplanRecord.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.data = action.payload
            })
            .addCase(mealplanRecord.rejected, (state, action) =>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(mealplanRecipes.pending, (state) => {
                state.isLoading = true
            })
            .addCase(mealplanRecipes.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.recipes = action.payload
            })
            .addCase(mealplanRecipes.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(updateMealplanRecord.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateMealplanRecord.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                if (state.data && action.payload.mealplanUpdate){
                    state.data = {
                        ...state.data,
                        buyer: action.payload.mealplanUpdate.buyer,
                        buyerId: action.payload.mealplanUpdate.buyerId
                    }
                }
            })
            .addCase(updateMealplanRecord.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(mealplanRecordNoneBuyer.pending, (state) => {
                state.isLoading = true
            })
            .addCase(mealplanRecordNoneBuyer.fulfilled, (state, action) =>{
                state.isLoading = false;
                state.isSuccess = true;
                state.data = action.payload;
            })
            .addCase(mealplanRecordNoneBuyer.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
    }
})

export const { reset } = mealplanSlice.actions;
export default mealplanSlice.reducer;
