import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Quizzes } from '../pages';

import { ACVGummies } from '../components';

const QuizzesMain = ()=>{
    return(
            <div>
            <Routes>
                <Route path='/' element={<Quizzes/>}/>
                <Route path='/acvgummies' element={<ACVGummies/>}/>
            </Routes>
            </div>  
    )
}

export default QuizzesMain;