import {useState} from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import {useDispatch} from 'react-redux';
import { update,} from '../features/auth/authSlice';

export default function AccountUpdateModal(params) {

  const [fieldValid, setFieldValid] = useState({
    name: true,
    email: true,
    password: true,
    password2: true,
    current_password: true
  });
  
  const [validationError, setValidationError] = useState({
    name: '',
    email: '',
    password: '',
    password2: '',
    current_password: ''
  });

  const [buttonDisabled, setButtonDisabled] = useState(true);

  
 // initial state has only one empty key/val pair to hold current email 
 // in user's account as filter in database 
  const initialFieldState = {
    filterEmail: ''
  }
  
  const [fieldData, setFieldData] = useState(initialFieldState)

  const dispatch = useDispatch()

  // grabbing current user date from params pass to component
  // data is passed from parent where it ave in localStorage
  const {name, email, password} = params.data;

  // Name validation function checks for characters only between 3 and 30 length
  const validateName = (name) => {
    //const namePattern = /^[A-Za-z]{3,30}$/;
    // const namePattern = /^[a-zA-Z]+([ \-'`]{0,1}[a-zA-Z]+){0,2}[.]?$/;
    const namePattern = /^[a-zA-Z][ a-zA-Z-`']*$/;
    return namePattern.test(name);
  };

    // Email validation function
  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  // Password validation function
  const validatePassword = (password) => {
    // const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
    return passwordPattern.test(password)
  }

  // Check password confirmation function
  const passwordConfirm  = (password, password2) => {
    const passwordsMatch = password === password2;
    return passwordsMatch;
  }

  // onchange creating fieldData values to pass to backend API to handle update
  const onChange = (e) => {
    const { name, value } = e.target;

    // Perform validation based on the field name
    let isValid = true;
    let error = '';

    switch (name) {
      case 'name':
        isValid = validateName(value);
        error = isValid ? '' : 'Please use only letters, hyphens, apostrophes, and spaces in the Name field.';
        setButtonDisabled(!isValid)
        break;
      case 'email':
        isValid = validateEmail(value);
        error = isValid ? '' : 'Please provide a valid email address in the format yourname@example.com.';
        setButtonDisabled(!isValid)
        break;
      case 'password':
        isValid = validatePassword(value);
        error = isValid ? '' : 'Password must have a minimum length of 8 characters, with at least one uppercase letter, one lowercase letter, and one number.';
        setButtonDisabled(!isValid)
        break;
      case 'password2':
        isValid = passwordConfirm(fieldData.password, value);
        error = isValid ? '' : 'Passwords do not match.';
        setButtonDisabled(!isValid)
        break;
      case 'current_password':
        isValid = value !== '';
        setButtonDisabled(!isValid)
        break;  
      default:
        isValid = true;
        error = '';
    }

    setFieldData((prevState) => ({
      ...prevState,
      filterEmail: email,
      [e.target.name]: e.target.value
    }));

    setFieldValid((prevState) => ({
      ...prevState,
      [name]: isValid
    }));
  
    setValidationError((prevState) => ({
      ...prevState,
      [name]: error
    }));
  };

  // when user click cancel instead of save fieldData is cleared to original state
  const handleCancel = () => {
    setFieldData(initialFieldState);
  }

  const onClick = () => {
      params.controlModal();
  }

  const onSubmit = (e) => {
    e.preventDefault()  
    dispatch(update(fieldData));
  }

  return (
    <>
      <Transition appear show={params.open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={params.controlModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-xl mb-6 font-medium leading-6 text-gray-900 capitalize sm:text-2xl"
                  >
                    Update {params.type}
                  </Dialog.Title>
                  <form onSubmit={onSubmit}>
                    <div className="mt-2">

                      {params.type === "password" &&
                          <label className="block text-md font-medium leading-6 text-gray-900">
                          New Password
                        </label>
                      }
  
                    <input
                      type={params.type}
                      name={params.type}
                      id={params.type}
                      className={`block w-full h-12 text-lg pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-lg sm:leading-6`}
                      placeholder={params.type === 'name' ? name : params.type === 'email' ? email : password}
                      onChange={onChange}
                    />

                    {
                      Object.keys(fieldValid).map((fieldName) => (
                        !fieldValid[fieldName] && (
                          <p className="mt-2 text-md font-medium text-red-600" key={`${fieldName}-error`} id={`${fieldName}-error`}>
                            {validationError[fieldName]}
                          </p>
                        )
                      ))
                    }
                    
                    {params.type === "password" &&
                    <>
                      <label className="block mt-12 text-md font-medium leading-6 text-gray-900">
                        Reenter New Password
                      </label>
                      
                      <input 
                        type="password"
                        name="password2"
                        id="password2"
                        className="block w-full h-12 text-lg pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-lg sm:leading-6"
                        onChange={onChange}
                      />
                      { 
                      !fieldValid && 
                         <p className="mt-2 text-md font-medium text-red-600" id="email-error">{validationError}</p> 
                      }
                      <label className="block mt-12 text-md font-medium leading-6 text-gray-900">
                        Enter Current Password To Confirm Change
                      </label>
                      
                      <input 
                      type="password"
                      name="current_password"
                      id="current_password"
                      className="block w-full h-12 text-lg pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-lg sm:leading-6"
                      onChange={onChange}
                      />
                    </>
                    }
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      className={`inline-flex w-full justify-center rounded-md  px-3 py-2 text-lg font-semibold text-white shadow-sm sm:ml-3 sm:w-auto
                      ${buttonDisabled ? 'bg-indigo-300' : 'bg-indigo-600'}`}
                      onClick={onClick}
                      disabled={buttonDisabled}
                    >
                      Save
                    </button>
                    
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-lg font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => {params.controlModal(); handleCancel();}}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

