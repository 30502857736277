import { CheckCircleIcon } from '@heroicons/react/20/solid'

export default function SubscriptionDisclaimer({price}) {
  return (
    <div className="rounded-md mt-3 bg-green-50 p-4">
      <div className="flex">
        <div className="ml-3">
          <div className="mt-2 text-md text-green-700">
            <p>By clicking <span className='font-semibold'>Yes! Let's Go!</span>, I agree  to pay ${price} for my plan and that if I do not cancel before the end of the 12-week 
                introductory plan it will convert to a 12-week subscription. EatToBurn will automatically charge my payment method 
                $34.89 every 12 weeks thereafter until I cancel. I can cancel online by visiting Update profile page on 
                EatToBurn website. If I cancel before the end of a subscription period, I understand that EatToBurn will not provide a partial refund.</p>
          </div>
        </div>
      </div>
    </div>
  )
}
