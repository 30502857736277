import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { 
  Age, 
  TargetWeight, 
  Height, 
  Weight, 
  // WeightLossChart,
  WeightLossChart1,
  Fats,
  Proteins,
  Carbs,
  Results
 } from '../../../components';


export default function QuizPartTwo(params){

  // const [optinId, setOptinId] = useState('')
  const [step, setStep] = useState(0);
  const [metric, setMetric] = useState(false);

  const [age, setAge] = useState('');

  const [weightLbsTarget, setWeightLbsTarget] = useState('');
  const [weightKgTarget, setWeightKgTarget] = useState('');
  
  const [weightLbsCurr, setWeightLbsCurr] = useState('');
  const [weightKgCurr, setWeightKgCurr] = useState('');

  const [heightFeet, setHeightFeet] = useState('');
  const [heightInches, setHeightInches] = useState('');
  const [heightCm, setHeightCm] = useState('')
// console.log(params)
  // Protein sources
  const [proteins, setProteins] = useState(
    { chicken: false,
      turkey: false,
      beef: false,
      pork: false,
      salmon: false,
      tuna: false,
      shrimp: false,
      calamari: false
    })

  // Fat sources
  const [fats, setFats ] = useState({
    eggs: false, 
    cheese: false,
    avacado: false,
    olives: false,
    nuts: false,
    coconut: false,
    yogurt: false,
    butter: false
  })

  // Carb sources
  const [carbs, setCarbs ] = useState({
    broccoli: false, 
    cauliflower: false,
    kale: false,
    greens: false,
    asparagus: false,
    raspberries: false,
    cabbage: false,
    mushrooms: false,
    zucchini: false,
    radishes: false 
  })

  // Grab optinId if its available in localStorage
  // let optinId
  // if(localStorage.getItem('optin')){
  //   optinId = localStorage.getItem('optin').slice(1, -1);
  // } else {
  //   optinId = ''
  // }
  // ********************************************
  // Replace grabing optinId from localStorage by grabing it from Redux Store
  // and grab beehiivSubId from Redux Store
  const optinData = useSelector((state) => state.optin.data);
  const optinId = optinData ? optinData.newOptinRecord.optinId : '';
  const beehiivSubId = optinData ? optinData.newOptinRecord.beehiivSubId : '';
  

  // Activity and gender
  const activity = params.quizData[10].answer.type;
  const gender = params.quizData[9].answer.type;

  /* 
  Spread the protein, fat, and carb objects in surveyResults 
  object to pass to Results component as single JSON
  */ 
  const surveyData = {
    optinId, beehiivSubId, age, gender, activity, metric, 
    weightLbsTarget, weightKgTarget, 
    weightLbsCurr, weightKgCurr, 
    heightFeet, heightInches, heightCm,
    ...proteins, ...fats, ...carbs
  }
  
  // Quiz answers
  const quizData = params.quizData;

  function nextStep(stepValue) {
    setStep(stepValue + 1)
  }

  function previousStep(stepValue){
    setStep(stepValue - 1)
  }

  // Set metric value to the oposite of current value
  const switchToMetric = () => {
    setMetric(!metric)
  }

  const handleMarcosChange = (macro, food, value) => {
    switch(macro){
      case 'protein':
        setProteins(prevState => ({...prevState, [food]: value}));
        break;
      case 'fat':
        setFats(prevState => ({...prevState, [food]: value}));
        break;
      case 'carb':
        setCarbs(prevState => ({...prevState, [food]: value}));
        break;
      default:
        break;    
    }
  }

  const updateFormValue = (fieldName, value) => {
    switch(fieldName) {
      case 'age':
        setAge(parseInt(value))
        break;
      case 'weightLbsTarget':
        setWeightLbsTarget(parseInt(value));
        break;
      case 'weightKgTarget':
        setWeightKgTarget(parseInt(value));
        break;  
      case 'weightLbsCurr':
        setWeightLbsCurr(parseInt(value));
        break;
      case 'weightKgCurr':
        setWeightKgCurr(parseInt(value));
        break;
      case 'heightFeet':
        setHeightFeet(parseInt(value));
        break;
      case 'heightInches':
        setHeightInches(parseInt(value));
        break;
      case 'heightCm':
        setHeightCm(parseInt(value))  
        break;  
      default:
        console.error(`Invalid fieldName: ${fieldName}`);
    }
  };

  const validateInput = (inputValue, min, max) => { 
    const value = parseInt(inputValue);

    var errorFlag = false;
    if (value < min || value > max || isNaN(value)){
      errorFlag = true;
    } 
    return errorFlag;
  }
  
  const FormSteps = ()=>{
    switch(step){
      case 0:
        return(
          <Age 
              age={age}
              step={step}
              updateFormValue={(value) => updateFormValue('age', value)}
              validateInput={validateInput}
              nextStep={() => nextStep(step)}
              previousStep={() => previousStep(step)}
              />
        )
      case 1:
        return(
          <TargetWeight 
            step={step}
            metric={metric}
            weightLbsTarget={weightLbsTarget}
            weightKgTarget={weightKgTarget}
            switchToMetric={() => switchToMetric()}
            updateLbsFormValue={(value) => updateFormValue('weightLbsTarget', value)}
            updateKgFormValue={(value) => updateFormValue('weightKgTarget', value)}
            validateInput={validateInput}
            nextStep={() => nextStep(step)}
            previousStep={() => previousStep(step)}
            />
        ) 
      case 2: 
          return(
            <Height
              step={step}
              metric={metric}
              heightFeet={heightFeet}
              heightInches={heightInches}
              updateHeightFtValue={(value) => updateFormValue('heightFeet', value)}
              updateHeightInchValue={(value) => updateFormValue('heightInches', value)}
              updateHeightCmValue={(value) => updateFormValue('heightCm', value)}
              validateInput={validateInput}
              nextStep={() => nextStep(step)}
              previousStep={() => previousStep(step)}
            />
          )
      case 3:
        return(
          <Weight 
            step={step}
            metric={metric}
            weightLbsCurr={weightLbsCurr}
            updateLbsFormValue={(value) => updateFormValue('weightLbsCurr', value)}
            updateKgFormValue={(value) => updateFormValue('weightKgCurr', value)}
            validateInput={validateInput}
            nextStep={() => nextStep(step)}
            previousStep={() => previousStep(step)}
          />
        ) 
      case 4:
        return(
          <WeightLossChart1
            step={step}
            metric={metric}
            gender={gender}
            weightLbsTarget={weightLbsTarget}
            weightLbsCurr={weightLbsCurr}
            weightKgCurr={weightKgCurr}
            weightKgTarget={weightKgTarget}
            heightCm={heightCm}
            heightFeet={heightFeet}
            heightInches={heightInches}
            nextStep={() => nextStep(step)}
          />
        ) 
      case 5:
        return(
          <Proteins 
            step={step}
            proteins={proteins}
            onProteinChange={handleMarcosChange}
            nextStep={() => nextStep(step)}
            previousStep={() => previousStep(step)}
          />
        )
      case 6:
        return(
          <Fats 
            step={step}
            fats={fats}
            onFatChange={handleMarcosChange}
            nextStep={() => nextStep(step)}
            previousStep={() => previousStep(step)}
          />
        )
      case 7:
        return(
          <Carbs 
            step={step}
            carbs={carbs}
            onCarbChange={handleMarcosChange}
            nextStep={() => nextStep(step)}
            previousStep={() => previousStep(step)}
          />
        )
      case 8:
        return(
          <Results 
            surveyData={surveyData} 
            quizData={quizData}/>
        )           
    }
  }

  return(
    <div className='mx-auto max-w-3xl bg-gray-100 shadow sm:rounded-lg p-4 sm:px-6 lg:px-8'>
       {FormSteps()}
    </div>
  )
}