import React, { useState } from 'react';
import { QuizQuestion, QuizProgress, QuizResults} from '../../components';
import {ACVGummiesQuizContent} from '../../assets/data/quizzes/ACVGummiesQuizContent';


const ACVGummies = () => {
    // Creates data array to track answers
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answersTracker, setAnswersTracker] = useState([]);
    const [showResults, setShowResults] = useState(false);

    const handleAddToTracker = (answer) => {

        const answerObject = {
          question: ACVGummiesQuizContent[currentQuestion].type,
          answer: answer
        };
      
        const newArray = [...answersTracker, answerObject];

        setCurrentQuestion(currentQuestion + 1);
        setAnswersTracker(newArray);

        if(answersTracker.length === 7){
            setShowResults(true);
        } 
    };

    return(

        <div className='mx-auto max-w-3xl bg-gray-100 shadow sm:rounded-lg p-4 sm:px-6 lg:px-8'>
            <QuizProgress step={currentQuestion}/>
            {!showResults 
            ? 
            <>
            <QuizQuestion question={ACVGummiesQuizContent[currentQuestion]?.question}/>
            <ul className="space-y-3">
                { ACVGummiesQuizContent[currentQuestion]?.answers.map(
                    (answer)=>{
                        return(
                            <li key={answer.type}
                                className="ring-2 ring-sky-100 bg-white ring-opacity-60 ring-offset-2
                                            ring-offset-sky-300 relative flex cursor-pointer rounded-lg 
                                            px-5 py-4 shadow-md hover:bg-sky-100
                                            hover:ring-offset-sky-600 hover:ring-offset-4" 
                                onClick={() => handleAddToTracker(answer)}>
                                <div className="flex w-full items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="text-lg">
                                            {answer.content}
                                        </div>            
                                    </div>      
                                </div>
                            </li>
                        )
                    }
                )}
            </ul>
            </>
            :
            <QuizResults results={answersTracker}/>
            }
        </div>

    )
}

export default ACVGummies;