import axios from 'axios';

// const API_URL = 'http://localhost:5000/api/reset/'
// const API_URL = 'https://eattoburn.com/api/reset/'
const API_URL = process.env.REACT_APP_SERVERSIDE_BASE_API + '/reset/';


// Find if user exists
const findUser = async (userData) =>{
    try{
        // console.log('service' ,userData)
        const response = await axios.post(API_URL + 'finduser/', userData)
        if(response.data){
            localStorage.setItem('reset', JSON.stringify(response.data))
        }
        return response.data
        
    } catch (error){
        throw new Error("Unexpected Error")
    }
}

// Reset Forgoten Password  
const resetPassword = async (userData) => {

    try {
        const response = await axios.put(API_URL + 'reset/', userData)
    } catch (error){
        throw new Error("Unexpeted error")
    }
}

// remove reset token from localStokrage 
const destroyToken = () => {
    localStorage.removeItem('reset');
}

const resetService = {
    findUser,
    resetPassword,
    destroyToken
}

export default resetService;