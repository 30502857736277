import { WifiLoader } from "react-awesome-loaders";


const MiniSpinner = () => {
    return(
        <div className="flex justify-center mt-8">  
            <WifiLoader 
                background={"transparent"}
                desktopSize={"38px"}
                mobileSize={"20px"}
                text={"Loading..."}
                backColor="#F8D210"
                frontColor="#F652A0"
            />          
        </div>
    )
}

export default MiniSpinner;