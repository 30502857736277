import {useState} from 'react';
import { findUser, } from '../features/reset/resetSlice';
import {useDispatch} from 'react-redux';

const ResetPasswordEmail = () => {

    const [emailSent, setEmailSent] = useState(false);
    const [emailValid, setEmailValid] = useState(true);

    const [ formData, setFormData] = useState({
      email: '',
    });

    const dispatch = useDispatch();

    const onChange = (e) => {
      setEmailValid(true)

      setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value
      }))
    };


    const onSubmit = (e) => {
        e.preventDefault();


        // console.log(formData)
        dispatch(findUser(formData))
        setEmailSent(true)
        // dispatch(reset())
    }   

  return (
    <>  
    {
        emailSent ? (
            <>
            <h1 className='text-xl font-bold'>
                Thanks! If {formData.email} matches an email we have on file, then we've sent you an email containing further instructions for resetting your password.
            </h1>
            <p className='my-6'>If you haven't received an email in 5 minutes, check your spam, resend, or try a different email.</p>
            </>
        ) : (
            <form onSubmit={onSubmit} className="space-y-6" action="#" method="POST" noValidate>
            <div>
                 <p className='text-lg my-6'>
                 Enter the email address associated with your account and we'll send you a link to reset your password.
                 </p>
                 <label htmlFor="email" className="block text-lg font-medium leading-6 text-gray-900">
                   Email
                 </label>
                 <div className="mt-2">
                   <input
                     style={{fontSize: '1.25rem'}}
                     // onChange={onChange}
                     // value={email}
                     id="email"
                     name="email"
                     type="email"
                     autoComplete="email"
                     onChange={onChange}
                     className='block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                     // className={`block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                     // ${isEmailValid ? '' : 'ring-red-500 bg-red-200'}`}
                   />
                 </div>
                 {/* { !isEmailValid && 
                 <p className="mt-2 text-md font-medium text-red-600" id="email-error">Please enter email.</p> } */}
               </div>
               <div>
                 <button
                   type="submit"
                   className="flex w-full h-12 justify-center rounded-md bg-indigo-600 px-3 py-2 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                 > Continue
                    {/* {isLoading ? <RotatingLines 
                                   strokeColor="white"
                                   strokeWidth="3"
                                   animationDuration="1.75"
                                   width="36"
                                   visible={true}/>            
                               : 'Sign in'} */}
                 </button>
             </div>
         </form>
        )
    }
    </>
  )
}

export default ResetPasswordEmail