
export default function CheckoutSteps(step) {
  const formStep = step.step;
  return (
    <div>
      <div className="my-4" aria-hidden="true">
        <div className="mt-6 hidden grid-cols-2 text-md sm:text-sm lg:font-medium text-gray-600 sm:grid">
          <div 
            className={`flex my-1 ${formStep === 1 ? `text-gray-500` : `text-green-600`}`}

            >
            <div className={`w-6 h-6 flex mr-2 justify-center items-center rounded-full text-white 
                            ${formStep === 1 ? `bg-gray-400` : `bg-green-600`}`}
            >
              1
            </div>
            <p>Contact Information</p>
          </div>
          <div 
            className={`flex my-1 ${formStep === 1 || formStep === 2 ? `text-gray-500` : formStep === 3 ? `text-green-600` : `text-green-600`}`}
            >
            <div 
              className={`w-6 h-6 flex mr-2 justify-center items-center rounded-full text-white 
              ${formStep === 1 || formStep === 2 ? `bg-gray-400` : formStep === 3 ? `bg-green-600` : `bg-green-600`}`}
              >
              2
            </div>
            Payment Information
            </div>
        </div>
        <div className="overflow-hidden rounded-full bg-gray-200">
          <div className="h-2 rounded-full bg-green-600" style={formStep === 1 ? { width: '50%' } : { width: '100%'}} />
        </div>
      </div>
    </div>
  )
}
