export default function KetoPizzaLandingThankYou() {
    return (
      <>
        {/*
          This example requires updating your template:
  
          ```
          <html class="h-full">
          <body class="h-full">
          ```
        */}
        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
          <div className="text-center">
            <p className="text-2xl font-semibold text-gray-600">Your copy is on its way</p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-rose-700 sm:text-5xl">Let's Make Your Keto Journey Personal</h1>
            <p className="mt-6 text-2xl leading-7 text-gray-900">Take our 20-second quiz to discover a healthier, happier you!</p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="/quiz"
                className="px-32 py-3.5 rounded-md bg-red-600 text-3xl font-semibold text-white shadow-sm hover:bg-gradient-to-r from-red-600 via-red-500 to-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              >
                NEXT
              </a>
            </div>
          </div>
        </main>
      </>
    )
  }
