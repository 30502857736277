import axios from "axios";

const API_URL = 'api/optin/';

// Creat new optin record
const newOptinRecord = async (optinData) => {
    // console.log('optService ...data sending ',optinData)
    const response = await axios.post(API_URL + 'newoptin/', optinData);
    // Save optinId to localStorage
    // console.log('Responce ', response)
    if (response.data){
        localStorage.setItem('optin', JSON.stringify(response.data.newOptinRecord.optinId))
    }

    return response.data;
}

// Retrieve existing optin record
const retrieveOptinRecord = async (optinId) => {
    // console.log('optinId ...data sending ',optinId)
    const response = await axios.post(API_URL + 'optinrecord/', optinId);
    // console.log(response.data)
    return response.data;
}

const quizService = {
    newOptinRecord,
    retrieveOptinRecord
}

export default quizService;