import React, { useEffect, useState } from 'react';
import {useLocation} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PlanTimer from '../components/order/PlanTimer';
import HeroSection from '../components/order/HeroSection';
// import PriceSection from '../components/order/PriceSection';
import FeatureSection from '../components/order/FeatureSection';
import FAQSection from '../components/order/FAQSection';
// import GuaranteeSection from '../components/order/GuaranteeSection';
import SinglePriceSection from '../components/order/SinglePriceSection';
import BonusesSection from '../components/order/BonusesSection';
import {mealplanRecordNoneBuyer} from '../features/mealplan/mealplanSlice';
import { retrieveQuizRecord } from '../features/quiz/quizSlice';
import { optinRecord } from '../features/optin/optinSlice';
 
export default function ConfirmPlan() {

  const dispatch = useDispatch()
  const location = useLocation()
  const [dataFetched, setDataFetched] = useState(false);

  const surveyData = useSelector(state => state.mealplan.data);
  const quizData = useSelector(state => state.quiz.data);
  const optinData = useSelector(state => state.optin.data);

  const mealplanLoading = useSelector(state => state.mealplan.isLoading);
  const quizDataLoading = useSelector(state => state.quiz.isLoading);
  const optinDataLoading = useSelector(state => state.optin.isLoading);

  const mealplanError = useSelector(state => state.mealplan.isError);
  const quizDataError = useSelector(state => state.quiz.isError);
  const optinDataError = useSelector(state => state.optin.isError);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const mealplanId = params.get('mealplanId');
    const optinId = params.get('optinId')

    const fetchData = async () => {
      if (mealplanId && !surveyData){
        dispatch(mealplanRecordNoneBuyer({mealplanId}))
      }
  
      if (optinId && !quizData){
        dispatch(retrieveQuizRecord({optinId}))
      }
  
      if (optinId && !optinData){
        dispatch(optinRecord({optinId}))
      }
      setDataFetched(true);
    }

    if (!dataFetched && (!surveyData || !quizData || !optinData)){
      fetchData();
    }

  }, [dispatch, location, surveyData, quizData, optinData, dataFetched])


  if (mealplanLoading || quizDataLoading || optinDataLoading) {
    return <div>Loading...</div>;
  }

  if (mealplanError || quizDataError || optinDataError) {
    return <div>Error loading data. Please try again.</div>;
  }

  if (!surveyData || !quizData || !optinData) {
    return <div>No data available. Please complete the survey.</div>;
  }

  return(
    <>
      <PlanTimer />
      <HeroSection quizData={quizData} surveyData={surveyData}/>
      {/* <PriceSection /> */}
      <SinglePriceSection quizData={quizData} surveyData={surveyData} dataFetched={dataFetched}/>
      <FeatureSection quizData={quizData} dataFetched={dataFetched}/>
      {/* <BonusesSection quizData={quizData}/> */}
      <FAQSection />
      {/* <PriceSection /> */}
      <SinglePriceSection quizData={quizData} surveyData={surveyData} dataFetched={dataFetched}/>
      {/* <GuaranteeSection /> */}
    </>
  )
}
