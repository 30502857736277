import { useEffect } from 'react';
import {Dna} from 'react-loader-spinner';
import { ACVGummiesLinks } from '../../assets/data/quizzes/ACVGummiesLinks';

export default function QuizResults(props) {

    let answerCounts = {};

    props.results.forEach(item => {
        const answerValue = item.answer.answer;
        if (answerCounts[answerValue]) {
            answerCounts[answerValue] += 1;
        } else {
            answerCounts[answerValue] = 1;
        }
    });

    // Find the answer with the highest count
    let maxCount = 0;
    let mostFrequentAnswer = null;
    Object.entries(answerCounts).forEach(([answer, count]) => {
        if (count > maxCount) {
            maxCount = count;
            mostFrequentAnswer = answer;
        }
    });

    // Function to find the link for the most frequent answer
    const findLinkForAnswer = (answer) => {
        const linkObject = ACVGummiesLinks.find(linkItem => linkItem.result === answer);
        return linkObject ? linkObject.link : "Link not found";
    };

    // Get affiliate link for recomended selection
    const recommendedLink = findLinkForAnswer(mostFrequentAnswer);

    // Redirect to affiliate page after 2 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            window.location.href = recommendedLink; // Redirect to the recommended link
        }, 2000); // Set the delay here (2000 milliseconds = 2 seconds)

        return () => clearTimeout(timer); // Clear the timer if the component unmounts
    }, [recommendedLink]); // Dependency array to re-run the effect if recommendedLink changes


    return (
      <div className="bg-white">
        <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center grid justify-items-center">
            <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
                />
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Hang tight!              
            </h2>
            <p className="mx-auto mt-2 max-w-xl text-lg leading-8 text-gray-600">
            We're putting the final sparkle on your results.<br/> This page with refresh in a jiffy!
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              {/* <a
                href="#"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Get started
              </a> */}
              <a href={recommendedLink} className="text-md font-semibold leading-6 text-blue-600">
                <u>Click here to go to your recomeded supplement page now!</u> 
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }