import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import recipesService from './recipesService';

// get user from localStorage
const recipes = JSON.parse(localStorage.getItem('recipes'));

const initialState = {
    // if recipes are set in localStorage save as recipes else set as empty string
    data: recipes ? recipes : null,
    isError: false,
    isSuccess: false,
    isLoading:false, 
    message: ''
}

// Get mealplan recipes
export const loadRecipes = createAsyncThunk('recipes/loadRecipes',
    async (recipesParams, thunkAPI) => {

        try{
            const token = thunkAPI.getState().auth.user.token
            return await recipesService.loadRecipes(recipesParams, token)
        } catch(error){
            const message = 
                (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message || 
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
);

export const recipesSlice = createSlice({
    name: "recipes",
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadRecipes.pending, (state) => {
                state.isLoading = true
            })
            .addCase(loadRecipes.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.data = action.payload
            })
            .addCase(loadRecipes.rejected, (state, action) =>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    }

});

export const { reset } = recipesSlice.actions;
export default recipesSlice.reducer;