import { Disclosure } from '@headlessui/react'
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

// import { MinusSmallIcon, PlusSmallIcon, ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: "Can I really lose weight without feeling hungry all the time?",
    answer:
      "Absolutely! Our keto-based plan is designed to keep you feeling full and satisfied. By focusing on healthy fats and proteins, you'll naturally reduce cravings and hunger pangs, making weight loss much more comfortable and sustainable.",
  },
  {
    question: "Do I need to spend hours in the gym to see results?",
    answer:
      "Not at all! Our program is designed to help you lose weight primarily through dietary changes. While exercise can enhance your results, it's not required to see significant weight loss with our plan.",
  },
  {
    question: "How quickly can I expect to see results?",
    answer:
      "Many of our users report visible changes in as little as 4 weeks. However, everyone's body is different, and results may vary. The key is consistency with the plan.",
  },
  {
    question: "Will I have to give up all my favorite foods?",
    answer:
      "No! Our meal plan includes a variety of delicious foods, including some surprises like bacon and steak. You'll learn how to enjoy food while still losing weight, without feeling deprived.",
  },
  {
    question: "Is this just another fad diet that will leave me weighing more in the long run?",
    answer: 
      "Absolutely not. Our program is designed as a sustainable lifestyle change, not a quick fix. It's about retraining your body to burn fat efficiently, leading to long-term success in weight management."
  },
  {
    question: "I'm always tired when I diet. Will this be the same?",
    answer: 
      "Many of our users actually report increased energy levels! By optimizing your nutrition and helping your body burn fat for fuel, you're likely to experience improved energy and mental clarity throughout the day."
  }
  // More questions...
]

// export default function FAQSection() {
//   return (
//     <div className="bg-white">
//       <div className="mx-auto max-w-7xl px-6 py-12 sm:py-12 lg:px-8 lg:py-40">
//         <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
//           <h2 className="text-4xl font-bold leading-10 tracking-tight text-gray-900">People often ask</h2>
//           <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
//               {faqs.map((faq) => (
//                 <Disclosure key={faq.question} as="div" className="pt-6">
//                   <dt>
//                     <Disclosure.Button className="group flex w-full items-start justify-between text-left text-gray-900">
//                       <span className="text-base font-semibold leading-7">{faq.question}</span>
//                       <span className="ml-6 flex h-7 items-center">
//                         <FaChevronDown aria-hidden="true" className="h-6 w-6 group-[open]:hidden" />
//                         <FaChevronUp aria-hidden="true" className="h-6 w-6 hidden group-[open]:block" /> 
//                       </span>
//                     </Disclosure.Button>
//                   </dt>
//                   <Disclosure.Panel as="dd" className="mt-2 pr-12">
//                     <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
//                   </Disclosure.Panel>
//                 </Disclosure>
//               ))}
//           </dl>
//         </div>
//       </div>
//     </div>
//   )
// }

export default function FAQSection() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-16 sm:py-18 lg:px-8 lg:py-28">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-4xl font-bold leading-10 tracking-tight text-gray-900">People often ask</h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-xl font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <FaChevronUp className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <FaChevronDown className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-xl leading-7 text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
