import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'

import App from './App1';
import { store } from './app/store'
import { Provider } from 'react-redux';
import { ContextProvider } from './contexts/ContextProvider';

/******** Google tag manager *********/
import TagManager from 'react-gtm-module'

/*************** PostHog ******************/
import { PostHogProvider} from 'posthog-js/react'

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

const tagManagerArgs = {
    gtmId: 'GTM-WK7VF6CX'
}

TagManager.initialize(tagManagerArgs)
/*************************************/

ReactDOM.render(
    <Provider store={store}>
        <ContextProvider>
            <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
                <App />
            </PostHogProvider>
        </ContextProvider>
    </Provider>
, document.getElementById('root'));