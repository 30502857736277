import { LegalPageHeader } from '../components';
import DISCLAIMER from "../assets/disclaimer";

export default function LegalPages() {

    // Checking for legal page's URL
    const legalPageUrl = () => {
      var arr =  window.location.pathname.split('/')
      return arr[2]
    }

    // Grabs text from Disclaimer file based on legal page's URL
    const legalText = () => {
      for (var i = 0; i < DISCLAIMER.length; i++){
          if (DISCLAIMER[i].type === legalPageUrl()){
              return DISCLAIMER[i]
          }
      }
    }

    const RenderText = (text) => {
      if (text.type === "h"){
          return(
              <h5 key={text.sequence} className="font-semibold leading-6 text-gray-800  ">
                  {text.text} 
              </h5>
          );
      } 
      else {
          return(
              <p>
                  {text.text}
              </p>
          );
      }        
    }
    
    const pageText = legalText();

    return (
      <>
      <LegalPageHeader />
      <div className="overflow-hidden bg-white px-6 py-16 lg:px-8 xl:py-36">
        <div className="mx-auto max-w-max lg:max-w-7xl">
          <div className="relative">
            <svg
              className="absolute right-0 top-0 -mr-20 -mt-20 hidden md:block md:[overflow-anchor:none]"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)" />
            </svg>
            <svg
              className="absolute bottom-0 left-0 -mb-20 -ml-20 hidden md:block md:[overflow-anchor:none]"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)" />
            </svg>
            <div className="relative md:bg-white md:p-6">
              <div className="lg:grid lg:grid-cols-1 lg:gap-6">
                <div className="prose prose-lg prose-indigo text-gray-500 lg:max-w-none">
                  { pageText.text.map((item) => {
                      // console.log(item);
                      return RenderText(item)
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }
  