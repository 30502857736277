import React from 'react'
import {Link , NavLink} from 'react-router-dom';
import { VscFlame } from "react-icons/vsc";
import {MdOutlineCancel} from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import {links} from '../assets/data';
import { useStateContext } from '../contexts/ContextProvider';

const Sidebar = () => {
  const {currentColor, activeMenu, setActiveMenu, screenSize} = useStateContext();
  const activeLink = 'flex items-center gap-5 pl-4 pt-3 pb-2 rounded-lg text-white text-lg m-2';
  const normalLink = 'flex items-center gap-5 pl-4 pt-3 pb-2 rounded-lg text-grey-700 dark:text-grey-200 dark:hover:text-black hover:bg-light-gray m-2 text-lg';
  
  const handleCloseSideBar = () => {
    if(activeMenu && screenSize <= 900){
        setActiveMenu(false)
    }
  } 

  return (
    <div className='ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10'>
        {activeMenu && (
            <>
                <div className='flex justify-between items-center'>
                    <Link 
                        to='/' 
                        onClick={handleCloseSideBar}
                        className='items-center gap-3 ml-3 mt-4 flex text-3xl font-extrabold tracking-tight dark:text-white text-pink-600'>
                        <VscFlame /> <span>EatToBurn</span>
                    </Link>
                    <TooltipComponent content="Menu" position="BottomCenter">
                        <button 
                            type="button"
                            // set active menu to oposite of its value
                            onClick={()=>setActiveMenu((prevActiveMenu)=> !prevActiveMenu )}
                            className='text-2xl rounded-full p-3 hover:bg-light-gray mt-4 block'>
                            <MdOutlineCancel/>
                        </button>
                    </TooltipComponent>
                </div>
                <div className='mt-10'>
                    {links.map((item) =>(
                        <div key={item.title}>
                            <p className='text-gray-600 dark:text-gray-400 m-3 mt-4 uppercase'>{item.title}</p>
                            {item.links.map((link)=>(
                                <NavLink
                                    to={`/${link.link}`}
                                    key={link.name}
                                    onClick={handleCloseSideBar}
                                    style={({isActive}) => ({
                                        backgroundColor: isActive ? currentColor : ''
                                    })}
                                    className={({isActive}) => (isActive ? activeLink : normalLink)}>
                                    <span className='text-2xl'>{link.icon}</span>
                                    <span className='capitalize'>{link.name}</span>
                                </NavLink>
                            ))}
                        </div>
                    ))}
                </div>
            </>
        )}
    </div>
  )
}

export default Sidebar;