export const ACVGummiesQuizContent = [
    {
        type: 'goal',
        question: 'If supplements are your magic beans, what giant goal are you growing towards in the clouds?',
        answers: [
            {
                type: 'ketosis',
                content: 'Weight loss and ketosis.',
                answer: 'A'
            },
            {
                type: 'blood sugar',
                content: 'Blood sugar control and weight management.',
                answer: 'B'
            },
            {
                type: 'mental clarity',
                content: 'Mental clarity and focus.',
                answer: 'C'
            },
            {
                type: 'gut health',
                content: 'Detox and digestive health.',
                answer: 'D'
            },   
            {
                type: 'metabolism boost',
                content: 'Metabolism boost and general wellness.',
                answer: 'E'
            },  
            {
                type: 'more energy',
                content: 'Natural energy and mood boost.',
                answer: 'F'
            },  
        ]
    },
    {
        type: 'supplement type',
        question: 'How do you prefer to consume your supplements?',
        answers: [
            {
                type: 'gummies',
                content: 'Gummies',
                answer: 'A'
            },
            {
                type: 'drops',
                content: 'Liquid drops',
                answer: 'B'
            },
            {
                type: 'capsules',
                content: 'Capsules',
                answer: 'C'
            }
        ]
    },
    {
        type: 'dietary pref',
        question: 'Do you have any dietary preferences for supplements?',
        answers:[
            {
                type: 'vegan',
                content: 'Vegan, non-GMO, gluten-free',
                answer: 'A'
            },
            {
                type: 'no prefs',
                content: 'No specific preferences',
                answer: 'B'
            },
            {
                type: 'organic',
                content: 'Organic ingredients',
                answer: 'C'
            }
        ]
    },
    {
        type: 'taste',
        question: 'How important is the taste of the supplement to you?',
        answers: [
            {
                type: 'very important',
                content: 'Very important; I prefer something tasty',
                answer: 'A'
            },
            {
                type: 'not important',
                content: 'Not important; effectiveness is what matters',
                answer: 'B'
            }
        ]
    },
    {
        type: 'guarantee',
        question: 'Are you looking for a supplement with a satisfaction guarantee?',
        answers: [
            {
                type: 'guarantee yes',
                content: 'Yes, it gives me confidence in the product',
                answer: 'A'
            },
            {
                type: 'guarantee no',
                content: 'Not a deciding factor for ',
                answer: 'B'
            }
        ]
    },
    {
        type: 'budget',
        question: 'What is your budget for supplements?',
        answers: [
            {
                type: 'budget yes',
                content: 'Looking for a cost-effective option',
                answer: 'A'
            },
            {
                type: 'budget no',
                content: 'Willing to invest more for higher quality',
                answer: 'B'
            }
        ]
    },
    {
        type: 'increase metabolism',
        content: 'Do you prefer supplements that also support increased metabolism?',
        answers: [
            {
                type: 'metabolism yes',
                content: 'Yes, boosting metabolism is a priority',
                answer: 'A'
            },
            {
                type: 'metabolism no',
                content: 'No, I have other health priorities',
                answer: 'B'
            }
        ]
    },
    {
        type: 'allergens',
        content: 'Are allergen-free and non-GMO ingredients crucial for you?',
        answers: [
            {
                type: 'allergens yes',
                content: 'Yes, they are essential',
                answer: 'A'
            },
            {
                type: 'allergens no',
                content: 'No, I am flexible as long as the product is effective',
                answer: 'B'
            }
        ]
    }
]

