import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonGetMyPlan from './ButtonGetMyPlan';

export default function PlanTimer() {
  const [timeLeft, setTimeLeft] = useState(600); // 600 seconds = 10 minutes
  const navigate = useNavigate(); // Initialize the navigate function

  useEffect(() => {
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="sticky top-0 z-50 bg-white shadow">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col sm:flex-row items-center justify-center py-3 sm:py-4">  
          <div className="flex flex-col sm:flex-row items-center">
            <span className="text-gray-900 font-medium text-xl sm:text-2xl mb-1 sm:mb-0 sm:mr-2">
              Discount is reserved for:
            </span>
            <span 
            // className="text-4xl sm:text-3xl font-bold text-orange-600 mb-2 sm:mb-0 sm:mr-4"
            className="text-3xl sm:text-4xl font-bold text-orange-600 mb-2 sm:mb-0 sm:mr-6"
            >
              {formatTime(timeLeft)}
            </span>
            <ButtonGetMyPlan />
            {/* <button
              className="bg-orange-500 hover:bg-orange-600 text-white text-md uppercase font-medium py-2 px-14 rounded"
              onClick={() => navigate('/subscribe')}
            >
              Get my plan
            </button> */}
          </div>
        </div>
      </div>
    </div>
  )
}