import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import authService from './authService';

// get user from localStorage
const user = JSON.parse(localStorage.getItem('user'));

const initialState = {
    // if user is set in localStorage save as user else set as empty string
    user: user ? user : '',
    isError: false,
    isSuccess: false,
    isLoading:false, 
    message: ''
}
// Register user Thunk
export const register = createAsyncThunk('auth/register',
async (user, thunkAPI) => {
    try{
        return await authService.register(user)
    }catch(error){
        const message = (error.response && 
                        error.response.data && 
                        error.response.data.message) || 
                        error.message ||
                        error.toString()
        
        return thunkAPI.rejectWithValue(message)                
    }
})

// Login user Thunk
export const login = createAsyncThunk('auth/login',
async (user, thunkAPI) => {
    try{
        // console.log('slice ', user)
        return await authService.login(user)
    }catch(error){
        const message = (error.response && 
                        error.response.data && 
                        error.response.data.message) || 
                        error.message ||
                        error.toString()
        
        return thunkAPI.rejectWithValue(message)                
    }
})

// Logout user Thunk
export const logout = createAsyncThunk( 'auth/logout', 
    async () => {
        await authService.logout()
    }
)

// Update user Thunk (name, email and password)
export const update = createAsyncThunk( 'auth/update',
async (user, thunkAPI) => {
    try{
        // grab token from redux state using thunkAPI's getState 
        const token = thunkAPI.getState().auth.user.token;
        return await authService.update(user, token);
    } catch(error){
        const message = (error.response && 
            error.response.data && 
            error.response.data.message) || 
            error.message ||
            error.toString()

        return thunkAPI.rejectWithValue(message)   
    }
})

// Update password Thunk
// export const updatePassword  = createAsyncThunk('auth/passwordUpdate', 
// async (user, thunkAPI) => {
//     try{
//         const token = thunkAPI.getState.auth.user.token;
//         return await authService.updatePassword(user, token)
//     } catch(error){
//         const message = (error.response && 
//             error.response.data && 
//             error.response.data.message) || 
//             error.message ||
//             error.toString()

//         return thunkAPI.rejectWithValue(message) 
//     }
// })

// Cancel paid subscription Thunk
export const cancel = createAsyncThunk('auth/cancel',
async (user, thunkAPI) => {
    try{
        const token = thunkAPI.getState().auth.user.token;
        return await authService.cancel(user, token)
    } catch (error){
        const message = (error.response &&
             error.response.data &&
             error.response.data.message) ||
             error.message ||
             error.toString()
        
        return thunkAPI.rejectWithValue(message)     
    }
}
)

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        }
    },
    extraReducers: (builder) => {

        builder
            .addCase(register.pending, (state) => {
                state.isLoading = true
            })
            .addCase(register.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(register.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = ''
            })
            .addCase(login.pending, (state) => {
                state.isLoading = true
            })
            .addCase(login.fulfilled, (state, action)=>{
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(login.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = ''
            })
            .addCase(logout.fulfilled, (state)=>{
                state.user = ''
            })
            .addCase(update.pending, (state) => {
                state.isLoading = true
            })
            .addCase(update.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(update.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = ''
            })
            .addCase(cancel.pending, (state) => {
                state.isLoading = true
            })
            .addCase(cancel.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(cancel.rejected, (state, action)=>{
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = ''
            })
    }
})

export const { reset } = authSlice.actions;
export default authSlice.reducer;