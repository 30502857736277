const PDFSectionContent = {
    Foolproof: [
        { 
            id: 1,
            headline: 'This is a step-by-step guide to lose weight and keep it off, starting now!',
            text: 'Unwanted belly fat can be very easy to gain and difficult to get rid of.  Any weight loss success story begins with first changing yourself and your relationship with food. Creating healthy eating habits is the the first and most critical step on your journey to loosing and keeping off unwanted body fat.',
            icon: 'seedling'
        },
        { 
            id: 2,
            headline: 'Dangers of processed foods',
            text: 'The ingredients in processed foods are highly unnatural. They are full of hidden fats and sugars. Each one of these unnatural ingredients subotaging your weight loss efforts and can contribute to severe health problems, including hypertension and diabetes.',
            icon: 'heartbeat'
        }
    ],
    Bulletproof: [
        { 
            id: 1,
            headline: 'How to lose pounds and remain them gone?',
            text: 'When people achieve ketosis, they not only lose weight, but they get a burst of energy; they think more clearly and even their skin improves. ',
            icon: 'hamburger'
        },
        { 
            id: 2,
            headline: 'Turbocharge Your Keto Lifestyle Results',
            text: 'This ebook will help you to adopt the keto diet and recalibrate your metabolism for sustainable weight loss.',
            icon: 'award'
        }
    ]
};

export default PDFSectionContent;