import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { TooltipComponent } from '@syncfusion/ej2-react-popups';
// import { FiSettings } from 'react-icons/fi';
import { Navbar, SidebarLayout, } from '../components';
import { Mealplan, Downloads, Foolproof, Bulletproof, Bonuses, AccountSettings, } from '../pages';
import { useStateContext } from '../contexts/ContextProvider';
import { Routes, Route } from 'react-router-dom';


const Home = () => {

  const navigate =  useNavigate();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if(!user) {
      navigate('/login')
    }
  }, [user, navigate])

  const { activeMenu } = useStateContext();

  return (
    <div className='flex relative dark:bg-main-dark-bg'>
      <div className='fixed right-4 bottom-4' style={{zIndex: '1000'}}>
        {/* <TooltipComponent content="Settings" positions='Top'>
          <button 
              type='button'
              className='text-3x1 
              p-3 hover:drop-shadow-x1 
              hover:bg-light-gray
              text-white'
              style={{background: 'blue', borderRadius: '50%'}}>
              <FiSettings />
          </button>
        </TooltipComponent>  */}
      </div>
      {activeMenu ? (
          <div className='w-72 fixed sidebar
              dark:bg-secondary-dark-bg
              bg-white'>
            <SidebarLayout />
          </div>
      ) : (
          <div className='w-0
                    dark:bg-secondary-dark-bg'>
              <SidebarLayout />
          </div>
      )}
      <div className={`dark:bg-main-bg bg-main-bg min-h-screen w-full
                    ${activeMenu ? 'md:ml-72' : 'flex-2'}` }>
        <div className='fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full'>
          <Navbar />
        </div> 
        <div>
          <Routes>
            <Route path='/' element={<Downloads/>}/>
            <Route path='/mealplan' element={<Mealplan/>}/>
            <Route path='/foolproof' element={<Foolproof/>}/>
            <Route path='/bulletproof' element={<Bulletproof/>}/>
            <Route path='/bonuses' element={<Bonuses/>}/>
            <Route path='/update' element={<AccountSettings/>}/>
            {/* <Route path='/billing' element={<Billing />} /> */}
          </Routes>
        </div>             
      </div>
    </div>
  )
}

export default Home