
export default function RecipeInstructions(instructions) {

    // combine all steps into single array
    const steps = instructions.instructions.reduce((result, obj) => {
        return result.concat(obj.steps);
      }, []);
   
    return (
      <div className="bg-white">
        <div className="mx-auto max-w-7xl divide-y divide-gray-900/10 px-6 py-2 sm:py-2 lg:px-8 lg:py-2">
          <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
            {steps.map((step) => (
              <div key={steps.indexOf(step)} className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
                <dt className="text-lg font-semibold leading-7 text-gray-900 lg:col-span-5">
                    Step: {step.number}
                </dt>
                <dd className="mt-4 lg:col-span-7 lg:mt-0">
                  <p className="text-lg leading-7 text-gray-600">
                      {step.step}
                  </p>
                </dd>
              </div>
            ))}
           
          </dl>
        </div>
      </div>
    )
  }