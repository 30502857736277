import { useLocation } from 'react-router-dom';
import {useDispatch} from 'react-redux';
import { resetPassword } from '../features/reset/resetSlice';
import { useState } from 'react';


const ResetPasswordPassword = () => {
  
  // Grab token from URL query
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // Get the value of a specific query parameter
  const urlToken = queryParams.get('token');

  const [formData, setFormData] = useState({
    password: '',
    password2: '',
    token: '',
  })
  
  const [passwordValid, setPasswordValid] = useState(true);

  // const { reset, isLoading, isError, isSuccess, message } = useSelector(
  //   (state) => state.reset
  // )

  // console.log(reset)
  const dispatch = useDispatch();

  const onChange = (e) => {
    setPasswordValid(true)

    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
      token: urlToken,
    }))
  };

  const {password, password2, token} = formData;

  const onSubmit = (e) => {
    e.preventDefault()

    dispatch(resetPassword({password, token,}))      
  }   
    
  return (
    <>
      <form onSubmit={onSubmit} className="space-y-6" action="#" method="POST" noValidate>
          <div>
              <p className='text-lg my-6'>
                  Create new password
              </p>
              <label htmlFor="email" className="block text-lg font-medium leading-6 text-gray-900">
                Password
              </label>
              <div className="mt-2">
                <input
                  style={{fontSize: '1.25rem'}}
                  value={password}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="password"
                  onChange={onChange}
                  className='block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                  // className={`block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                  // ${isEmailValid ? '' : 'ring-red-500 bg-red-200'}`}
                />
              </div>
              {/* { !isEmailValid && 
              <p className="mt-2 text-md font-medium text-red-600" id="email-error">Please enter email.</p> } */}

              <label htmlFor="email" className="block text-lg mt-6 font-medium leading-6 text-gray-900">
                Reenter Password
              </label>
              <div className="mt-2">
                <input
                  style={{fontSize: '1.25rem'}}
                  value={password2}
                  id="password2"
                  name="password2"
                  type="password"
                  autoComplete="password2"
                  onChange={onChange}
                  className='block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                  // className={`block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                  // ${isEmailValid ? '' : 'ring-red-500 bg-red-200'}`}
                />
              </div>
          </div>
            <div>
              <button
                type="submit"
                className="flex w-full h-12 justify-center rounded-md bg-indigo-600 px-3 py-2 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              > Continue
                  {/* {isLoading ? <RotatingLines 
                                strokeColor="white"
                                strokeWidth="3"
                                animationDuration="1.75"
                                width="36"
                                visible={true}/>            
                            : 'Sign in'} */}
              </button>
          </div>
      </form>
    </>
  )
}

export default ResetPasswordPassword