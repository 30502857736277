import React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import posthog from 'posthog-js';
import {
  Login,
  Landing,
  KetoPizzaLanding,
  KetoPizzaLandingThankYou,
  Home,
  Register,
  Quiz,
  ConfirmPlan,
  CheckoutSubscribe,
  LegalPages,
  ForgotPassword,
  PageNotFoundRedirect,
  QuizzesMain,
  NewsletterLandingVariant0,
  NewsletterThankYouVariant0
} from './pages';

import './App.css';

// Separate component for route handling and page tracking
const AppRoutes = () => {
  let location = useLocation();
  
  React.useEffect(() => {
    posthog.capture('$pageview');
  }, [location]);

  return (
    <Routes>
      <Route path='/home/*' element={<Home />} />
      <Route path='/login' element={<Login />}/>
      <Route path='/register' element={<Register />} />
      
      {/* Landing pages */}
      <Route path='/ketopizza' element={<KetoPizzaLanding/>} />
      <Route path='/ketopizzaty' element={<KetoPizzaLandingThankYou/>}/>
      <Route path='/' element={<Landing />}/>
      <Route path='/quiz' element={<Quiz />} />
      <Route path='/quizzes/*' element={<QuizzesMain/>}/>
      
      {/* Newsletter Landing Page Tests */}
      <Route path='/signup-v0' element={<NewsletterLandingVariant0/>} />
      <Route path='/thankyou-v0' element={<NewsletterThankYouVariant0/>} />
      
      {/* Confirm order pages */}
      <Route path='/myplan' element={<ConfirmPlan/>}/>
      
      {/* Checkout Routes */}
      <Route path='/subscribe' element={<CheckoutSubscribe/>}/>
      
      <Route path='/legal/:page' element={<LegalPages/>}/>
      
      <Route path='/reset' element={<ForgotPassword/>} />
      <Route path='/reset/password' element={<ForgotPassword/>}/>
      
      <Route path='*' element={<PageNotFoundRedirect/>}/>
    </Routes>
  );
};

// Main App component
const App = () => {
  return (
    <div>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </div>
  );
};

export default App;